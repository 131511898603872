import './settings.css';
import {Alert, Button, Snackbar, TextField, ThemeProvider} from "@mui/material";
import React, {useEffect, useState} from "react";
import {CustomFontTheme} from "../x01/CustomFontTheme";
import {getFromLocalStorage, saveToLocalStorage} from "../localStorageZugriff";
import {YesNoComponent} from "./YesNoComponent";
import EditIcon from '@mui/icons-material/Edit';
import BoardDialog from "./BoardDialog";
import FetchUtil from "../util/FetchUtil";
import {DeleteForever} from "@mui/icons-material";

function SettingsDHVerbindung() {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showBoardDialog, setShowBoardDialog] = useState(getFromLocalStorage(false));
    const [showAutodartsBoardId, setShowAutodartsBoardId] = useState(getFromLocalStorage(false));
    const [showToken, setShowToken] = useState(getFromLocalStorage(true));
    const [showBoardToken, setShowBoardToken] = useState(getFromLocalStorage(true));
    const [token, setToken] = useState(getFromLocalStorage("dh_token"));
    const [boardBezeichnung, setBoardBezeichnung] = useState(getFromLocalStorage("dh_board_bezeichnung"));
    const [autodartsBoardId, setAutodartsBoardId] = useState(getFromLocalStorage("dh_autodarts_boardid"));
    const [boardToken, setBoardToken] = useState(getFromLocalStorage("dh_board_token"));
    const [url, setUrl] = useState(getFromLocalStorage("dh_url"));
    const [alleSpieleImLiveView, setAlleSpieleImLiveView] = useState(getFromLocalStorage("dh_alle_spiele_im_live_view"));

    useEffect(() => {
        let tmpToken = getFromLocalStorage("dh_token");
        let tmpBoardtoken = getFromLocalStorage("dh_board_token");
        let tmpAutodartBoardId = getFromLocalStorage("dh_autodarts_boardid");
        setShowToken(tmpToken === undefined || tmpToken === "");
        setShowBoardToken(tmpBoardtoken === undefined || tmpBoardtoken === "");
        setShowAutodartsBoardId(tmpAutodartBoardId !== null && tmpAutodartBoardId !== undefined && tmpAutodartBoardId !== "")
    }, []);

    useEffect(() => {
        saveToLocalStorage("dh_token", token);
        saveToLocalStorage("dh_board_bezeichnung", boardBezeichnung);
        saveToLocalStorage("dh_board_token", boardToken);
        saveToLocalStorage("dh_autodarts_boardid", autodartsBoardId);
        saveToLocalStorage("dh_url", url);
        saveToLocalStorage("dh_alle_spiele_im_live_view", alleSpieleImLiveView);
        setShowAutodartsBoardId(autodartsBoardId !== null && autodartsBoardId !== undefined && autodartsBoardId !== "")
    }, [token, boardBezeichnung, autodartsBoardId, boardToken, url, alleSpieleImLiveView]);

    const checkServerVerbindung = () => {
        FetchUtil.fetchGet(url + "/boards", token, boardToken,
            json => {
                setInfoMessage("Die Verbindung zum Server konnte erfolgreich hergestellt werden.");
            },
            responseNotOk => {
                setErrorMessage("Verbindung nicht erfolgreich. Token und Board-Token überprüfen. Eventuell hat dein Veranstalter auch keinen Pro-Account.")
            },
            error => {
                setErrorMessage("Verbindung nicht erfolgreich. Token und Board-Token überprüfen. Eventuell hat dein Veranstalter auch keinen Pro-Account.")
            }
        );
    }

    return <>
        <div style={{fontSize:18}}>Hinweis: Ergebnisse können automatisch nach Darthelfer übertragen werden.</div>
        <div style={{fontSize:18}}>Weiter wird das Board verständigt, sobald in Darthelfer ein Spiel für dieses Board vorhanden ist.</div>
        <div style={{fontSize:18, marginBottom:20}}>Lege auf darthelfer.de einen Veranstalter an und generiere ein Token und ein Boardtoken und trage diese hier ein.</div>
        <div style={{fontSize:18, marginBottom:20, backgroundImage: "linear-gradient(to left, #1aa3ff, #0059B3)", padding:5}}>
            <div><img src="/images/autodarts_powered_by.png" style={{height:40}} alt="Autodarts"/></div>
            <div style={{color:"whitesmoke", marginTop:10, fontSize:14}}>
                Der Scorer von Darthelfer unterstützt die automatische Scoreeingabe über das Autodarts-System. Hierfür ist ein Veranstalter Pro-Account auf darthelfer.de notwendig.
                Die BoardId kann hier bewusst nicht eingegeben werden. Bitte wähle das in darthelfer.de entsprechend konfigurierte Board mit der Autodarts-BoardId.<br/>
                Autodarts wird nur bei Desktop/Tablet unterstützt.
            </div>
        </div>
        <Snackbar open={infoMessage !== undefined} autoHideDuration={10000} onClose={() => setInfoMessage(undefined)}
                  anchorOrigin={{ vertical:"top", horizontal:"center" }}>
            <Alert onClose={() => setInfoMessage(undefined)} severity="info" sx={{ width: '100%', fontSize:30 }}>
                {infoMessage}
            </Alert>
        </Snackbar>
        <Snackbar open={errorMessage !== undefined} autoHideDuration={10000} onClose={() => setErrorMessage(undefined)}
                  anchorOrigin={{ vertical:"top", horizontal:"center" }}>
            <Alert onClose={() => setErrorMessage(undefined)} severity="error" sx={{ width: '100%', fontSize:30 }}>
                {errorMessage}
            </Alert>
        </Snackbar>
        {token !== undefined && token !== "" && boardToken !== undefined && boardToken !== "" &&
            <div style={{marginBottom: 20}}>
                <Button style={{fontSize: 20, color: "green", fontWeight:"bold"}} onClick={() => checkServerVerbindung()}>Verbindung zum Server testen</Button>
            </div>
        }
        { showToken &&
            <div style={{marginBottom: 20}}>
                <ThemeProvider theme={CustomFontTheme}>
                    <TextField id="standard-token" label="Token" variant="standard" value={token}
                               onChange={(e) => setToken(e.target.value.toUpperCase())}/>
                </ThemeProvider>
            </div>
        }
        { !showToken &&
            <div style={{marginBottom: 20, fontSize:18}}>
                <span style={{fontWeight:"bold"}}>Token wird zur Sicherheit nicht angezeigt!!!</span><br/>
                Du hast bereits einen Token eingegeben. Sofern es Probleme mit der Verbindung gibt oder du einfach einen neuen Token eingeben willst, klicke auf den Bearbeitungsbutton.<br/>
                Der Token wird gelöscht und du kannst einen neuen Token vergeben.
                <EditIcon fontWeight={30} style={{cursor:"pointer"}} onClick={() => {
                    setToken("");
                    setShowToken(true);
                }}/>
            </div>
        }
        { showBoardToken &&
            <div style={{marginBottom: 20}}>
                <ThemeProvider theme={CustomFontTheme}>
                    <TextField id="standard-boardtoken" label="Board-Token" variant="standard" value={boardToken}
                               onChange={(e) => setBoardToken(e.target.value.toUpperCase())}/>
                </ThemeProvider>
            </div>
        }
        { !showBoardToken &&
            <div style={{marginBottom: 20, fontSize:18}}>
                <span style={{fontWeight:"bold"}}>Board-Token wird zur Sicherheit nicht angezeigt!!!</span><br/>
                Du hast bereits einen Board-Token eingegeben. Sofern es Probleme mit der Verbindung gibt oder du einfach einen neuen Board-Token eingeben willst, klicke auf den Bearbeitungsbutton.<br/>
                Der Board-Token wird gelöscht und du kannst einen neuen Board-Token vergeben.
                <EditIcon fontWeight={30} style={{cursor:"pointer"}} onClick={() => {
                    setBoardToken("");
                    setShowBoardToken(true);
                }}/>
            </div>
        }
        { showAutodartsBoardId &&
            <div style={{marginBottom: 20, fontSize:18}}>
                <span style={{fontWeight:"bold"}}>Autodarts-BoardId</span><DeleteForever fontWeight={30} style={{cursor:"pointer"}} onClick={() => {
                setAutodartsBoardId("");
                setShowAutodartsBoardId(false);
            }}/>
            </div>
        }
        {token !== undefined && token !== "" && boardToken !== undefined && boardToken !== "" &&
            <div style={{marginBottom: 20}}>
                <Button style={{fontSize: 20}} onClick={() => {
                    setShowToken(false);
                    setShowBoardToken(false);
                    setShowBoardDialog(true);
                }}>Anderes Board auswählen</Button>
            </div>
        }
        <div style={{marginBottom: 20}}>
            <ThemeProvider theme={CustomFontTheme}>
                <TextField id="standard-bezeichnung" label="Board-Bezeichnung" variant="standard" value={boardBezeichnung}
                           onChange={(e) => setBoardBezeichnung(e.target.value)}/>
            </ThemeProvider>
        </div>
        <div style={{marginBottom: 20}}>
            <ThemeProvider theme={CustomFontTheme}>
                <TextField id="standard-bezeichnung" label="Darthelfer URL" variant="standard" value={url}
                           onChange={(e) => setUrl(e.target.value)} style={{width:"100%"}}/>
            </ThemeProvider>
        </div>

        <div style={{marginTop: 40}}>
            <YesNoComponent label="Alle Spiele zum Live-View übertragen" value={alleSpieleImLiveView} onChanged={(value) => setAlleSpieleImLiveView(value)}/>
            <div style={{fontSize:18}}>Turnier-/Ligaspiele die über DH gespielt werden, werden automatisch im Live-View angezeigt. Wähle hier 'Ja' sofern auch andere Spiele im Live-View angezeigt werden sollen.</div>
        </div>

        {showBoardDialog && <BoardDialog onCancel={() => setShowBoardDialog(false)}
                                         onSave={(boardToken, boardBezeichnung, autodartsBoardId) => {
                                             setBoardToken(boardToken);
                                             setBoardBezeichnung(boardBezeichnung);
                                             setAutodartsBoardId(autodartsBoardId);
        }}/>}
    </>
}

export default SettingsDHVerbindung;
