import {useLocation} from "react-router-dom";
import App from "./App";

const withLocation = Component => props => {
    const location = useLocation();
    return <Component {...props} location={location} />;
};


export default withLocation(App)
