import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import "primeflex/primeflex.css"
import Dashboard from "./dashboard/Dashboard";
import {NAVIGATION_ITEM} from "./constants/navigationItems";
import Settings from "./settings/Settings";
import ScoringX01View from "./x01/ScoringX01View";
import GameOverview from "./gameoverview/GameOverview";
import TournamentOverview from "./tournament/TournamentOverview";
import GameDetailView from "./x01/GameDetailView";
import LiveViewInternetOverview from "./liveview/LiveViewInternetOverview";
import ScoringEdartX01View from "./x01/ScoringEdartX01View";
import LiveViewLokalOverview from "./liveview/LiveViewLokalOverview";
import BoardConnectView from "./connect/BoardConnectView";

function App({location}) {

  if (location.pathname === "" || NAVIGATION_ITEM.DASHBOARD.route === location.pathname) {
    return <Dashboard/>;
  } else if (NAVIGATION_ITEM.GAME.route === location.pathname) {
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get('id');
    return <ScoringX01View id={id}/>;
  } else if (NAVIGATION_ITEM.EDARTGAME.route === location.pathname) {
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get('id');
    return <ScoringEdartX01View gameId={id}/>;
  } else if (NAVIGATION_ITEM.GAMEDETAIL.route === location.pathname) {
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get('id');
    const type = urlParams.get('type');
    return <GameDetailView id={id} type={type}/>;
  } else if (NAVIGATION_ITEM.SETTINGS.route === location.pathname) {
    return <Settings/>;
  } else if (NAVIGATION_ITEM.GAMEOVERVIEW_OPEN.route === location.pathname) {
    return <GameOverview offeneAnzeigen={true}/>;
  } else if (NAVIGATION_ITEM.GAMEOVERVIEW_CLOSED.route === location.pathname) {
    return <GameOverview offeneAnzeigen={false}/>;
  } else if (NAVIGATION_ITEM.TOURNAMENT.route === location.pathname) {
    return <TournamentOverview/>;
  } else if (NAVIGATION_ITEM.LIVEVIEW_INTERNET.route === location.pathname) {
    return <LiveViewInternetOverview/>;
  } else if (NAVIGATION_ITEM.LIVEVIEW_LOKAL.route === location.pathname) {
    return <LiveViewLokalOverview/>;
  } else if (NAVIGATION_ITEM.BOARD_SERVER_CONNECT.route === location.pathname) {
    return <BoardConnectView/>;
  }
  return <div>Kein Zugriff auf die gewählte Seite!!! Admin verständigen.</div>;
}

export default App;
