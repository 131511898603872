import {FormControl, InputLabel, NativeSelect, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";

export function DesktopTabletComponent({value, onChanged}) {
    const getOptions = () => {
        let options = [];
        options.push(<option key={"key_desktop_"} value="desktop">Desktop</option>);
        options.push(<option key={"key_tablet"} value="tablet">Tablet</option>);
        options.push(<option key={"key_smartphone"} value="smartphone">Smartphone</option>);
        return options;
    }

    return <FormControl style={{width:300}}>
        <ThemeProvider theme={CustomFontTheme}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Geräte:
            </InputLabel>
            <NativeSelect
                defaultValue={value}
                onChange={(event) => onChanged(event.target.value)}
                inputProps={{
                    name: 'desktoptabletgeraet',
                    id: 'uncontrolled-native',
                }}
            >
                {getOptions()}
            </NativeSelect>
        </ThemeProvider>
    </FormControl>
}
