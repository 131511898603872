export function saveToLocalStorage(key, value)  {
    localStorage.setItem(key, JSON.stringify(value));
}

export function getFromLocalStorage(key)  {
    const savedItem = localStorage.getItem(key);
    if( savedItem === undefined || savedItem === null || savedItem === "undefined" || savedItem === "") {
        return undefined;
    } else {
        return JSON.parse(savedItem);
    }
}

export function getFromLocalStorageWithDefault(key, defaultValue)  {
    const savedItem = localStorage.getItem(key);
    const parsedItem = JSON.parse(savedItem);
    if( parsedItem === undefined || parsedItem === null || parsedItem === "") {
        return defaultValue;
    } else {
        return parsedItem;
    }
}

export function getAllGamesFromLocalStorage()  {
    let games = [];
    Object.keys(localStorage).forEach(function(key){
        if( key.startsWith("dh_game_")) {
            let game = getFromLocalStorage(key);
            games.push(game);
        }
    });
    return games;
}

export function removeFromLocalStorage(key)  {
    localStorage.removeItem(key);
}
