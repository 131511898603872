import {getFromLocalStorage} from "../localStorageZugriff";

export function isSmartphone() {
    const geraet = getFromLocalStorage("dh_default_geraetetyp");
    return geraet === "smartphone";
}

export function isTablet() {
    const geraet = getFromLocalStorage("dh_default_geraetetyp");
    return geraet === "tablet";
}

export function isTabletQuerformat(dimensions) {
    let wert = dimensions.height < dimensions.width
    const geraet = getFromLocalStorage("dh_default_geraetetyp");
    return geraet === "tablet" && wert;
}

export function isTabletHochformat(dimensions) {
    let wert = dimensions.height > dimensions.width
    const geraet = getFromLocalStorage("dh_default_geraetetyp");
    return geraet === "tablet" && wert;
}

export function isDesktop() {
    return !isTablet() && !isSmartphone();
}
