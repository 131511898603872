import PropTypes from "prop-types";
import React from "react";

function ScoreElement({scorecounter, selectedScore, selectedValue, value, invalid, fontSize, setSelectedScore}) {
    let wert = value;
    let style = {fontSize: fontSize, paddingTop: 5, paddingBottom: 5};
    if ( scorecounter === selectedScore && selectedValue > 180 ) {
        style = {...style, backgroundColor: "red", color: "black"};
        wert = selectedValue;
    } else if ( scorecounter === selectedScore) {
        style = {...style, backgroundColor: "yellow", color: "black", fontSize: fontSize};
        wert = selectedValue;
    }
    if( invalid !== undefined && invalid && scorecounter !== selectedScore) {
        // ungültig und nicht aktiv, dann wird durchgestrichen angezeigt
        style = {...style, textDecoration: "line-through"};
    }
    if( Number(value) >= 100 && !invalid) {
        return <div className="col" style={style} onClick={() => setSelectedScore(scorecounter)}><span style={{border:"1px solid gray", borderRadius:30, padding:5}}>{wert}</span></div>;
    }
    return <div className="col" style={style} onClick={() => setSelectedScore(scorecounter)}>{wert}</div>;
}

ScoreElement.propTypes = {
    scorecounter: PropTypes.number.isRequired,
    selectedScore: PropTypes.number.isRequired,
    selectedValue: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    invalid: PropTypes.bool.isRequired,
};

export default ScoreElement;
