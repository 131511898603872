import {createTheme} from "@mui/material";
import {red} from "@mui/material/colors";

export const CustomFontNewDialogTheme = createTheme({
    palette: {
        text: {
            secondary: '#b6b6b6',
        },
        primary: {
            main: red[500],
        },
    },
});
