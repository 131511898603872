import {FormControl, InputLabel, NativeSelect, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";

export function YesNoComponent({label, value, onChanged}) {
    const getOptions = () => {
        let options = [];
        options.push(<option key={"key_modus_yes_" + label} value={true}>Ja</option>);
        options.push(<option key={"key_modus_no_" + label} value={false}>Nein</option>);
        return options;
    }

    return <FormControl style={{width:300}}>
        <ThemeProvider theme={CustomFontTheme}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                {label}
            </InputLabel>
            <NativeSelect
                defaultValue={value}
                onChange={(event) => onChanged(event.target.value === "true")}
                inputProps={{
                    name: 'yesno',
                    id: 'uncontrolled-native',
                }}
            >
                {getOptions()}
            </NativeSelect>
        </ThemeProvider>
    </FormControl>
}
