import {Button, Dialog, DialogActions, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";


export default function DeleteDialog({onDelete, onCancel}) {
    return <Dialog onClose={onCancel} open={true}>
        <div style={{padding:20, fontSize:30}}>Möchtest du wirklich löschen?</div>
        <DialogActions style={{padding:20}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" onClick={() => onDelete()}>Löschen (Enter)</Button>
                <Button variant="text" onClick={() => onCancel()}>Abbrechen (Escape)</Button>
            </ThemeProvider>
        </DialogActions>
    </Dialog>
}
