import {getFromLocalStorage, saveToLocalStorage} from "./localStorageZugriff";

export default function updateLocalStorage()  {

    updateSingleRow("dh_default_x01_fontweight", "normal");
    updateSingleRow("dh_default_x01_statistic_orientation", "liste");
    updateSingleRow("dh_default_player1", "Heim");
    updateSingleRow("dh_default_player2", "Gast");
    updateSingleRow("dh_default_sets", "1");
    if( getFromLocalStorage("dh_default_bestoflegs") === undefined ) {
        // nur von bestof nicht gesetzt ist, wird evtl. first to als default gesetzt
        updateSingleRow("dh_default_firsttolegs", "3");
    }
    updateSingleRow("dh_default_bestoflegs", "");
    updateSingleRow("dh_default_startpunkte", "501");
    updateSingleRow("dh_default_modusid", "double_out");
    updateSingleRow("dh_default_x01_show_finishquestion", true);
    updateSingleRow("dh_default_x01_show_checkout", false);
    updateSingleRow("dh_default_x01_show_average", true);
    updateSingleRow("dh_default_x01_show_average_first_9", false);
    updateSingleRow("dh_default_x01_show_average_leg", true);
    updateSingleRow("dh_default_x01_show_100plus", true);
    updateSingleRow("dh_default_x01_show_140plus", true);
    updateSingleRow("dh_default_x01_show_171", true);
    updateSingleRow("dh_default_x01_show_180", true);
    updateSingleRow("dh_default_x01_show_bestleg", true);
    updateSingleRow("dh_default_x01_show_worstleg", false);
    updateSingleRow("dh_default_x01_show_highout", true);
    updateSingleRow("dh_default_x01_show_finish_colors", true);
    updateSingleRow("dh_tablet_tastatur_row_height", 50);
    updateSingleRow("dh_alle_spiele_im_live_view", false);
    updateSingleRow("dh_default_show_table_score_value", false);
    updateSingleRow("dh_url", "https://www.darthelfer.de/api/client/v1");

    updateSingleRow("dh_default_seconds_to_dashboard", 30);
    updateSingleRow("dh_default_seconds_to_send_tournament_game", 30);

    updateSingleRow("dh_default_x01_taste1", 0);
    updateSingleRow("dh_default_x01_taste2", 26);
    updateSingleRow("dh_default_x01_taste3", 41);
    updateSingleRow("dh_default_x01_taste4", 45);
    updateSingleRow("dh_default_x01_taste5", 60);
    updateSingleRow("dh_default_x01_taste6", 81);
    updateSingleRow("dh_default_x01_taste7", 85);
    updateSingleRow("dh_default_x01_taste8", 100);

}

function updateSingleRow(key, defaultValue) {
    const savedItem = getFromLocalStorage(key);
    if( savedItem === null || savedItem === undefined || savedItem === "") {
        console.log("Updating local storage", key, defaultValue);
        saveToLocalStorage(key, defaultValue);
    }
}
