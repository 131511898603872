import {FormControl, InputLabel, NativeSelect, ThemeProvider} from "@mui/material";
import {MODUS_ZUR_AUSWAHL} from "../constants/GameModusComponent";

export function GameModusComponent({modusId, onModusChanged, theme, showLabel, fontSize}) {
    const getOptions = () => {

        let options = [];
        for (const modusElement of MODUS_ZUR_AUSWAHL) {
            options.push(<option key={"key_modus" + modusElement.id} value={modusElement.id}>{modusElement.bezeichnung}</option>);
        }
        return options;
    }

    return <FormControl fullWidth>
        <ThemeProvider theme={theme}>
            {showLabel !== undefined && showLabel &&
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Modus
                </InputLabel>
            }
            <NativeSelect
                defaultValue={modusId}
                onChange={(event) => onModusChanged(event.target.value)}
                sx={{color: '#b6b6b6', fontSize: fontSize !== undefined ? fontSize : ""  }}
                inputProps={{
                    name: 'Modus',
                    id: 'uncontrolled-native',
                }}
            >
                {getOptions()}
            </NativeSelect>
        </ThemeProvider>
    </FormControl>
}
