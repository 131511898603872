
export const CHECKOUT_VALUES_DOUBLE_OUT_1_DART = [50,40,38,36,34,32,30,28,26,24,22,20,18,16,14,12,10,8,6,4,2];
export const CHECKOUT_VALUES_DOUBLE_OUT_2_DARTS = [110,107,104,101,100,98,97,96,95,94,93,92,91,90,89,88,87,86,85,84,83,82,81,80,79,78,77,76,75,74,73,72,71,70,69,68,67,66,65,64,63,62,61,60,59,58,57,56,55,54,53,52,51,50,49,48,47,46,45,44,43,42,41,40,39,38,37,36,35,34,33,32,31,30,29,28,27,26,25,24,23,22,21,20,19,18,17,16,15,14,13,12,11,10,9,8,7,6,5,4,3,2];
export const CHECKOUT_VALUES_DOUBLE_OUT_3_DARTS = [170,167,164,161,160,158,157,156,155,154,153,152,151,150,149,148,147,146,145,144,143,142,141,140,139,138,137,136,135,134,133,132,131,130,129,128,127,126,125,124,123,122,121,120,119,118,117,116,115,114,113,112,111,110,109,108,107,106,105,104,103,102,101,100,99,98,97,96,95,94,93,92,91,90,89,88,87,86,85,84,83,82,81,80,79,78,77,76,75,74,73,72,71,70,69,68,67,66,65,64,63,62,61,60,59,58,57,56,55,54,53,52,51,50,49,48,47,46,45,44,43,42,41,40,39,38,37,36,35,34,33,32,31,30,29,28,27,26,25,24,23,22,21,20,19,18,17,16,15,14,13,12,11,10,9,8,7,6,5,4,3,2];

export function getCheckout(togo) {
    for (const checkout of checkouts) {
        if( checkout.wert === togo) {
            return checkout.finish;
        }
    }
    return "";
}

const checkouts = [
    {
        "wert": 170, "finish": "T20 T20 Bull"
    }
    ,
    {
        "wert": 167, "finish": "T20 T19 Bull"
    }
    ,
    {
        "wert": 164, "finish": "T20 T18 Bull"
    }
    ,
    {
        "wert": 161, "finish": "T20 T17 Bull"
    }
    ,
    {
        "wert": 160, "finish": "T20 T20 D20"
    }
    ,
    {
        "wert": 158, "finish": "T20 T20 D19"
    }
    ,
    {
        "wert": 157, "finish": "T20 T19 D20"
    }
    ,
    {
        "wert": 156, "finish": "T20 T20 D18"
    }
    ,
    {
        "wert": 155, "finish": "T20 T19 D19"
    }
    ,
    {
        "wert": 154, "finish": "T20 T18 D20"
    }
    ,
    {
        "wert": 153, "finish": "T20 T19 D18"
    }
    ,
    {
        "wert": 152, "finish": "T20 T20 D16"
    }
    ,
    {
        "wert": 151, "finish": "T20 T17 D20"
    }
    ,
    {
        "wert": 150, "finish": "T20 T18 D18"
    }
    ,
    {
        "wert": 149, "finish": "T20 T19 D16"
    }
    ,
    {
        "wert": 148, "finish": "T20 T16 D20"
    }
    ,
    {
        "wert": 147, "finish": "T19 T18 D18"
    }
    ,
    {
        "wert": 146, "finish": "T20 T18 D16"
    }
    ,
    {
        "wert": 145, "finish": "T20 T15 D20"
    }
    ,
    {
        "wert": 144, "finish": "T18 T18 D18"
    }
    ,
    {
        "wert": 143, "finish": "T20 T17 D16"
    }
    ,
    {
        "wert": 142, "finish": "T20 T14 D20"
    }
    ,
    {
        "wert": 141, "finish": "T17 T18 D18"
    }
    ,
    {
        "wert": 140, "finish": "T20 T20 D10"
    }
    ,
    {
        "wert": 139, "finish": "T19 T14 D20"
    }
    ,
    {
        "wert": 138, "finish": "T20 T18 D12"
    }
    ,
    {
        "wert": 137, "finish": "T19 T20 D10"
    }
    ,
    {
        "wert": 136, "finish": "T20 T20 D8"
    }
    ,
    {
        "wert": 135, "finish": "T17 T20 D12"
    }
    ,
    {
        "wert": 134, "finish": "T20 T14 D16"
    }
    ,
    {
        "wert": 133, "finish": "T20 T11 D20"
    }
    ,
    {
        "wert": 132, "finish": "T20 T12 D18"
    }
    ,
    {
        "wert": 131, "finish": "T20 T17 D10"
    }
    ,
    {
        "wert": 130, "finish": "T20 S20 Bull"
    }
    ,
    {
        "wert": 129, "finish": "T19 T12 D18"
    }
    ,
    {
        "wert": 128, "finish": "T18 T14 D16"
    }
    ,
    {
        "wert": 127, "finish": "T20 T17 D8"
    }
    ,
    {
        "wert": 126, "finish": "T19 S19 Bull"
    }
    ,
    {
        "wert": 125, "finish": "25 T20 D20"
    }
    ,
    {
        "wert": 124, "finish": "T20 S14 Bull"
    }
    ,
    {
        "wert": 123, "finish": "T19 T10 D18"
    }
    ,
    {
        "wert": 122, "finish": "T18 T12 D16"
    }
    ,
    {
        "wert": 121, "finish": "T20 T7 D20"
    }
    ,
    {
        "wert": 120, "finish": "T20 S20 D20"
    }
    ,
    {
        "wert": 119, "finish": "T20 S19 D20"
    }
    ,
    {
        "wert": 118, "finish": "T20 S18 D20"
    }
    ,
    {
        "wert": 117, "finish": "T20 S17 D20"
    }
    ,
    {
        "wert": 116, "finish": "T20 S16 D20"
    }
    ,
    {
        "wert": 115, "finish": "T20 S15 D20"
    }
    ,
    {
        "wert": 114, "finish": "T20 S14 D20"
    }
    ,
    {
        "wert": 113, "finish": "T20 S13 D20"
    }
    ,
    {
        "wert": 112, "finish": "T20 S20 D16"
    }
    ,
    {
        "wert": 111, "finish": "T20 S19 D16"
    }
    ,
    {
        "wert": 110, "finish": "T20 S18 D16"
    }
    ,
    {
        "wert": 109, "finish": "T20 S17 D16"
    }
    ,
    {
        "wert": 108, "finish": "T20 S16 D16"
    }
    ,
    {
        "wert": 107, "finish": "T19 Bull"
    }
    ,
    {
        "wert": 106, "finish": "T20 S10 D18"
    }
    ,
    {
        "wert": 105, "finish": "T20 S13 D16"
    }
    ,
    {
        "wert": 104, "finish": "T18 Bull"
    }
    ,
    {
        "wert": 103, "finish": "T20 S3 D20"
    }
    ,
    {
        "wert": 102, "finish": "T20 S10 D16"
    }
    ,
    {
        "wert": 101, "finish": "T17 Bull"
    }
    ,
    {
        "wert": 100, "finish": "T20 D20"
    }
    ,
    {
        "wert": 99, "finish": "T20 S7 D16"
    }
    ,
    {
        "wert": 98, "finish": "T20 D19"
    }
    ,
    {
        "wert": 97, "finish": "T19 D20"
    }
    ,
    {
        "wert": 96, "finish": "T20 D18"
    }
    ,
    {
        "wert": 95, "finish": "T19 D19"
    }
    ,
    {
        "wert": 94, "finish": "T18 D20"
    }
    ,
    {
        "wert": 93, "finish": "T19 D18"
    }
    ,
    {
        "wert": 92, "finish": "T20 D16"
    }
    ,
    {
        "wert": 91, "finish": "T17 D20"
    }
    ,
    {
        "wert": 90, "finish": "T18 D18"
    }
    ,
    {
        "wert": 89, "finish": "T19 D16"
    }
    ,
    {
        "wert": 88, "finish": "T16 D20"
    }
    ,
    {
        "wert": 87, "finish": "T17 D18"
    }
    ,
    {
        "wert": 86, "finish": "T18 D16"
    }
    ,
    {
        "wert": 85, "finish": "T15 D20"
    }
    ,
    {
        "wert": 84, "finish": "T20 D12"
    }
    ,
    {
        "wert": 83, "finish": "T17 D16"
    }
    ,
    {
        "wert": 82, "finish": "T14 D20"
    }
    ,
    {
        "wert": 81, "finish": "T15 D18"
    }
    ,
    {
        "wert": 80, "finish": "T20 D10"
    }
    ,
    {
        "wert": 79, "finish": "T13 D20"
    }
    ,
    {
        "wert": 78, "finish": "T18 D12"
    }
    ,
    {
        "wert": 77, "finish": "T19 D10"
    }
    ,
    {
        "wert": 76, "finish": "T20 D8"
    }
    ,
    {
        "wert": 75, "finish": "T17 D12"
    }
    ,
    {
        "wert": 74, "finish": "T18 D10"
    }
    ,
    {
        "wert": 73, "finish": "T19 D8"
    }
    ,
    {
        "wert": 72, "finish": "T12 D18"
    }
    ,
    {
        "wert": 71, "finish": "T17 D10"
    }
    ,
    {
        "wert": 70, "finish": "T10 D20"
    }
    ,
    {
        "wert": 69, "finish": "T13 D15"
    }
    ,
    {
        "wert": 68, "finish": "T20 D4"
    }
    ,
    {
        "wert": 67, "finish": "T17 D8"
    }
    ,
    {
        "wert": 66, "finish": "T10 D18"
    }
    ,
    {
        "wert": 65, "finish": "25 D20"
    }
    ,
    {
        "wert": 64, "finish": "D12 D20"
    }
    ,
    {
        "wert": 63, "finish": "T13 D12"
    }
    ,
    {
        "wert": 62, "finish": "D11 D20"
    }
    ,
    {
        "wert": 61, "finish": "S11 S10 D20"
    }
    ,
    {
        "wert": 60, "finish": "S20 D20"
    }
    ,
    {
        "wert": 59, "finish": "T13 D10"
    }
    ,
    {
        "wert": 58, "finish": "18   D20"
    }
    ,
    {
        "wert": 57, "finish": "19    D19"
    }
    ,
    {
        "wert": 56, "finish": "16    D20"
    }
    ,
    {
        "wert": 55, "finish": "15    D20"
    }
    ,
    {
        "wert": 54, "finish": "14   D20"
    }
    ,
    {
        "wert": 53, "finish": "13   D20"
    }
    ,
    {
        "wert": 52, "finish": "12    D20"
    }
    ,
    {
        "wert": 51, "finish": "11   D20"
    }
    ,
    {
        "wert": 50, "finish": "10    D20 (Bull)"
    }
    ,
    {
        "wert": 49, "finish": " 9    D20"
    }
    ,
    {
        "wert": 48, "finish": " 8    D20"
    }
    ,
    {
        "wert": 47, "finish": "7    D20"
    }
    ,
    {
        "wert": 46, "finish": "6    D20"
    }
    ,
    {
        "wert": 45, "finish": "5    D20"
    }
    ,
    {
        "wert": 44, "finish": "4    D20"
    }
    ,
    {
        "wert": 43, "finish": "3    D20"
    }
    ,
    {
        "wert": 42, "finish": "2    D20"
    }
    ,
    {
        "wert": 41, "finish": "1    D20"
    }
    ,
    {
        "wert": 40, "finish": "D20"
    }
    ,
    {
        "wert": 39, "finish": "7     D16"
    }
    ,
    {
        "wert": 38, "finish": "D19"
    }
    ,
    {
        "wert": 37, "finish": "5     D16"
    }
    ,
    {
        "wert": 36, "finish": "D18"
    }
    ,
    {
        "wert": 35, "finish": "3     D16"
    }
    ,
    {
        "wert": 34, "finish": "D17"
    }
    ,
    {
        "wert": 33, "finish": "1     D16"
    }
    ,
    {
        "wert": 32, "finish": "D16"
    }
    ,
    {
        "wert": 31, "finish": "15   D8"
    }
    ,
    {
        "wert": 30, "finish": "D15"
    }
    ,
    {
        "wert": 29, "finish": "1   D14"
    }
    ,
    {
        "wert": 28, "finish": "D14"
    }
    ,
    {
        "wert": 27, "finish": "3   D12"
    }
    ,
    {
        "wert": 26, "finish": "D13"
    }
    ,
    {
        "wert": 25, "finish": "1   D12"
    }
    ,
    {
        "wert": 24, "finish": "D12"
    }
    ,
    {
        "wert": 23, "finish": "7   D8"
    }
    ,
    {
        "wert": 22, "finish": "D11"
    }
    ,
    {
        "wert": 21, "finish": "1   D10"
    }
    ,
    {
        "wert": 20, "finish": "D10"
    }
    ,
    {
        "wert": 19, "finish": "3   D8"
    }
    ,
    {
        "wert": 18, "finish": "D9"
    }
    ,
    {
        "wert": 17, "finish": "1   D8"
    }
    ,
    {
        "wert": 16, "finish": "D8"
    }
    ,
    {
        "wert": 15, "finish": "7   D4"
    }
    ,
    {
        "wert": 14, "finish": "D7"
    }
    ,
    {
        "wert": 13, "finish": "5   D4"
    }
    ,
    {
        "wert": 12, "finish": "D6"
    }
    ,
    {
        "wert": 11, "finish": "3   D4"
    }
    ,
    {
        "wert": 10, "finish": "D5"
    }
    ,
    {
        "wert": 9, "finish": "1   D4"
    }
    ,
    {
        "wert": 8, "finish": "D4"
    }
    ,
    {
        "wert": 7, "finish": "3   D2"
    }
    ,
    {
        "wert": 6, "finish": "D3"
    }
    ,
    {
        "wert": 5, "finish": "1   D2"
    }
    ,
    {
        "wert": 4, "finish": "D2"
    }
    ,
    {
        "wert": 3, "finish": "1   D1"
    }
    ,
    {
        "wert": 2, "finish": "D1"
    }
];
