import './settings.css';
import {useEffect, useState} from "react";
import {getFromLocalStorage, saveToLocalStorage} from "../localStorageZugriff";
import {TextField, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";


export default function SettingsShortcuts() {

    const [taste1, setTaste1] = useState ( getFromLocalStorage("dh_default_x01_taste1"));
    const [taste2, setTaste2] = useState ( getFromLocalStorage("dh_default_x01_taste2"));
    const [taste3, setTaste3] = useState ( getFromLocalStorage("dh_default_x01_taste3"));
    const [taste4, setTaste4] = useState ( getFromLocalStorage("dh_default_x01_taste4"));
    const [taste5, setTaste5] = useState ( getFromLocalStorage("dh_default_x01_taste5"));
    const [taste6, setTaste6] = useState ( getFromLocalStorage("dh_default_x01_taste6"));
    const [taste7, setTaste7] = useState ( getFromLocalStorage("dh_default_x01_taste7"));
    const [taste8, setTaste8] = useState ( getFromLocalStorage("dh_default_x01_taste8"));

    useEffect(() => {
        saveToLocalStorage("dh_default_x01_taste1", taste1);
        saveToLocalStorage("dh_default_x01_taste2", taste2);
        saveToLocalStorage("dh_default_x01_taste3", taste3);
        saveToLocalStorage("dh_default_x01_taste4", taste4);
        saveToLocalStorage("dh_default_x01_taste5", taste5);
        saveToLocalStorage("dh_default_x01_taste6", taste6);
        saveToLocalStorage("dh_default_x01_taste7", taste7);
        saveToLocalStorage("dh_default_x01_taste8", taste8);
    }, [taste1, taste2, taste3, taste4, taste5, taste6, taste7, taste8]);


    const getTasteArea = (id, label, taste, setTaste) => {

        return <div style={{marginBottom: 10}}>
            <ThemeProvider theme={CustomFontTheme}>
                <TextField id={id} type="number" label={label} variant="standard"
                           InputProps={{inputProps: {min: 0}}}
                           value={taste} onChange={(e) => {
                    setTaste(e.target.value);
                }}/>
            </ThemeProvider>
        </div>
    }

    return <div className="grid">
        <div className="col" style={{width:150}}>
            <h2>Einstellungen Shortcuts - X01</h2>
            {getTasteArea("standard-taste1", "Shortcut 1 / F1", taste1, setTaste1)}
            {getTasteArea("standard-taste2", "Shortcut 2 / F2", taste2, setTaste2)}
            {getTasteArea("standard-taste3", "Shortcut 3 / F3", taste3, setTaste3)}
            {getTasteArea("standard-taste4", "Shortcut 4 / F4", taste4, setTaste4)}
            {getTasteArea("standard-taste5", "Shortcut 5 / F5", taste5, setTaste5)}
            {getTasteArea("standard-taste6", "Shortcut 6 / F6", taste6, setTaste6)}
            {getTasteArea("standard-taste7", "Shortcut 7 / F7", taste7, setTaste7)}
            {getTasteArea("standard-taste8", "Shortcut 8 / F8", taste8, setTaste8)}
            <div style={{marginTop:10}}>F9  = Restscore (z.B. du hast 100 Punkte und wirfst 60, du kannst dann 40 eingeben und F9 drücken. Der Scorer rechnet dann die 60 Punkte selbst aus.</div>
            <div style={{marginTop:10}}>F10 = Check mit dem ersten Dart</div>
            <div style={{marginTop:10}}>F11 = Check mit dem zweiten Dart</div>
            <div style={{marginTop:10}}>F12 = Check mit dem dritten Dart</div>
        </div>
    </div>
}
