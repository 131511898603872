import {CHECKOUT_VALUES_DOUBLE_OUT_3_DARTS} from "../constants/checkouts";

export function findScoreKoordinatenInGame(game, gesuchterScoreCounter) {
    let playerCounter = 0;
    let setCounter = 0;
    let legCounter = 0;
    let scoreCounter = 0;
    for (const player of game.players) {
        setCounter = 0;
        for (const tmpSet of player.sets) {
            legCounter = 0;
            for (const leg of tmpSet.legs) {
                if (leg.abgeschlossen === undefined || !leg.abgeschlossen) {
                    scoreCounter = 0;
                    for (const score of leg.scores) {
                        if( score.counter === gesuchterScoreCounter) {
                            return [playerCounter, setCounter, legCounter, scoreCounter];
                        }
                        scoreCounter++;
                    }
                }
                legCounter++;
            }
            setCounter++;
        }
        playerCounter++;
    }
    return undefined;
}

export function isSetModus(game) {
    return game.sets && game.sets > 1;
}

export function isUnentschieden(game) {
    let setNummerImArray = 0;
    if( game.firsttolegs !== undefined && game.firsttolegs !== "" ) {
        // bei first to legs gibt es kein unentschieden
        return false;
    } else if( game.bestoflegs !== undefined && game.bestoflegs !== "" ) {
        // alle Players müssen die gleichen gewonnen legs haben
        // dazu wird mit dem vom player1 verglichen
        let legsGewonnenPlayer1 = game.players[0].sets[setNummerImArray].legs.filter(leg => leg.abgeschlossen !== undefined && leg.abgeschlossen && leg.gewonnen).length;
        for( let counter = 1 ; counter < game.players.length; counter++) {
            // wir starten bei player 2, im array counter 1
            let tmpLegsGewonnen = game.players[counter].sets[setNummerImArray].legs.filter(leg => leg.abgeschlossen !== undefined && leg.abgeschlossen && leg.gewonnen).length;
            if( legsGewonnenPlayer1 !== tmpLegsGewonnen) {
                return false;
            }
        }
        return true;
    }
    return undefined;
}

export function isSetBeendet(game) {
    // es wird der erste Player genutzt um das aktuellen Set zu ermitteln
    let player = getPlayer(game, 0);
    let currentSet = getSetByPlayer(player);
    let setNummerImArray = currentSet.setnummer-1;
    let firsttolegs = undefined;
    let bestoflegs = undefined;
    if( game.firsttolegs !== undefined && game.firsttolegs !== "" ) {
        firsttolegs = Number(game.firsttolegs);
    } else if( game.bestoflegs !== undefined && game.bestoflegs !== "" ) {
        bestoflegs = Number(game.bestoflegs);
    }

    for (const tmpPlayer of game.players) {
        let legs = tmpPlayer.sets[setNummerImArray].legs;
        let legsAbgeschlossen = legs.filter(leg => leg.abgeschlossen !== undefined && leg.abgeschlossen).length;
        let countLegsGewonnen = legs.filter(leg => leg.abgeschlossen !== undefined && leg.abgeschlossen && leg.gewonnen).length;
        if( firsttolegs !== undefined) {
            if( firsttolegs === countLegsGewonnen) {
                return true;
            }
        } else if( bestoflegs !== undefined) {
            if( legsAbgeschlossen === bestoflegs) {
                // es wurde die maximale anzahl an legs gespielt, unentschieden. set ist beendet.
                // kann nur vorkommen, wenn kein Setmodus (technisch immer set modus, dann halt nur 1 set)
                return true;
            }
            let legsZumSieg = Math.floor(bestoflegs / 2) + 1;
            if( legsZumSieg === countLegsGewonnen) {
                return true;
            }
        }
    }
    return false;
}

export function ermittleGewinner(game) {
    for (const player of game.players) {
        if( player.gewonnen !== undefined && player.gewonnen) {
            return player;
        }
    }
    return undefined;
}

export function istGameBeendet(game) {
    let setsZumSieg = Number(game.sets);
    for (const player of game.players) {
        let gewonnenSetsVomPlayer = player.sets.filter(tmpSet => tmpSet.gewonnen !== undefined && tmpSet.gewonnen ).length;
        if( gewonnenSetsVomPlayer === setsZumSieg ) {
            return true;
        }
    }
    return false;
}

function getScoresCountByValue(game, playerCounter, search, operator) {
    let counter = 0;
    let player = getPlayer(game, playerCounter);
    for (const tmpSet of player.sets) {
        for (const leg of tmpSet.legs) {
            for (const score of leg.scores) {
                if( score.invalid === undefined || !score.invalid)  {
                    if( operator === ">=" && Number(score.value) >= search ) {
                        counter++;
                    } else if( operator === "=" && Number(score.value) === search) {
                        counter++;
                    }
                }
            }
        }
    }
    return counter;
}

export function getHighscores(game, playerCounter) {
    let map = new Map([]);
    let player = getPlayer(game, playerCounter);
    for (const tmpSet of player.sets) {
        for (const leg of tmpSet.legs) {
            let scores = leg.scores;
            if( leg.gewonnen ) {
                scores = scores.slice(0, scores.length-1);
            }
            for (const score of scores) {
                if( score.invalid === undefined || !score.invalid)  {
                    if( Number(score.value) >= 100 ) {
                        let value = map.get(Number(score.value));
                        if( value === null || value === undefined ) {
                            value = 0;
                        }
                        value += 1;
                        map.set(Number(score.value), value);
                    }
                }
            }
        }
    }
    let tmp = "";
    var mapSorted = new Map([...map.entries()].sort());
    for (let [key, value] of mapSorted) {
        if( tmp !== "") {
            tmp += ", ";
        }
        tmp += value + "x" + key;
    }

    return tmp !== "" ? tmp : "-";
}

export function get100Plus(game, playerCounter) {
    return getScoresCountByValue(game, playerCounter, 100, ">=");
}

export function get140Plus(game, playerCounter) {
    return getScoresCountByValue(game, playerCounter, 140, ">=");
}

export function get180er(game, playerCounter) {
    return getScoresCountByValue(game, playerCounter, 180, "=");
}

export function get171er(game, playerCounter) {
    return getScoresCountByValue(game, playerCounter, 171, "=");
}

function calculateAverage(scores) {
    let wuerfe = 0;
    let gesamtPunkte = 0;
    for (const score of scores) {
        if( score.invalid === undefined || !score.invalid)  {
            gesamtPunkte = gesamtPunkte + Number(score.value);
            if( score.wuerfe !== undefined) {
                wuerfe = wuerfe + Number(score.wuerfe);
            } else {
                wuerfe = wuerfe + 3;
            }
        } else {
            wuerfe = wuerfe + 3;
        }
    }
    if( gesamtPunkte === 0 || wuerfe === 0) {
        return 0;
    }
    let zwischenrechnung = gesamtPunkte / wuerfe;
    let average = zwischenrechnung * 3
    return average.toFixed(2);
}

export function getAverage(game, playerCounter) {
    let scores = [];
    let player = getPlayer(game, playerCounter);
    for (const tmpSet of player.sets) {
        for (const leg of tmpSet.legs) {
            for (const score of leg.scores) {
                if( score.value !== "")  {
                    scores.push(score);
                }
            }
        }
    }
    let average = calculateAverage(scores);
    return average;
}

export function getAverageLeg(game, playerCounter) {
    let scores = [];
    let leg = getLeg(game, playerCounter);
    if( leg === undefined) {
        // kein aktives Leg vorhanden, vermutlich Game beendet.
        return 0;
    }
    for (const score of leg.scores) {
        if( score.value !== "")  {
            scores.push(score);
        }
    }
    let average = calculateAverage(scores);
    return average;

}


export function getBestLeg(game, playerCounter) {
    return calculateThrowCount(game, playerCounter, true);
}

export function getWorstLeg(game, playerCounter) {
    return calculateThrowCount(game, playerCounter, false);
}

export function getHighOut(game, playerCounter) {
    let gewonneneLegs = ermittleGewonneLegsZumPlayer(game, playerCounter);
    let wert = 0;
    for (const leg of gewonneneLegs) {
        let lastScore = leg.scores[leg.scores.length - 1];
        if( wert < Number(lastScore.value)) {
            wert = Number(lastScore.value);
        }
    }
    return wert === 0 ? "-" : wert;
}

export function getHighFinishes(game, playerCounter) {
    let gewonneneLegs = ermittleGewonneLegsZumPlayer(game, playerCounter);
    let highfinishes = [];
    for (const leg of gewonneneLegs) {
        let lastScore = leg.scores[leg.scores.length - 1];
        if( Number(lastScore.value) >= 100) {
            highfinishes.push(lastScore.value);
        }
    }
    return highfinishes;
}


function calculateThrowCount(game, playerCounter, bestLeg) {
    let gewonneneLegs = ermittleGewonneLegsZumPlayer(game, playerCounter);
    let wert = 0;
    for (const leg of gewonneneLegs) {
        let legWert = 0;
        let scores = leg.scores;
        for (const score of scores) {
            if( score.value !== "")  {
                if( score.wuerfe === undefined ) {
                    legWert = legWert + 3;
                } else {
                    legWert = legWert + Number(score.wuerfe);
                }
            }
        }
        if( wert === 0 ) {
            wert = legWert;
        } else if( bestLeg && wert > legWert) {
            wert = legWert;
        } else if( !bestLeg && wert < legWert) {
            wert = legWert;
        }
    }
    return wert === 0 ? "-" : wert;
}

function ermittleGewonneLegsZumPlayer(game, playerCounter) {
    let gewonneneLegs = [];
    let player = getPlayer(game, playerCounter);
    for (const tmpSet of player.sets) {
        for (const leg of tmpSet.legs) {
            if (leg.abgeschlossen !== undefined && leg.abgeschlossen && leg.gewonnen) {
                gewonneneLegs.push(leg);
            }
        }
    }
    return gewonneneLegs;
}

export function getAverageFirst9(game, playerCounter) {
    let scores = [];
    let player = getPlayer(game, playerCounter);
    for (const tmpSet of player.sets) {
        for (const leg of tmpSet.legs) {
            let counter = 1;
            for (const score of leg.scores) {
                if( counter > 3 ) {
                    break;
                }
                if( score.value !== "" && (score.invalid === undefined || !score.invalid))  {
                    scores.push(score);
                }
                counter++;
            }
        }
    }
    let average = calculateAverage(scores);
    return average;
}

export function getGewonneneSets(game, playerCounter) {
    let player = getPlayer(game, playerCounter);
    let anzahl = player.sets.filter(tmpSet => tmpSet.gewonnen !== undefined && tmpSet.gewonnen).length;
    return anzahl;
}

export function getGewonneneLegs(game, playerCounter) {
    let player = getPlayer(game, playerCounter);
    let tmpSet;
    if( game.abgeschlossen && Number(game.sets) === 1) {
        // sobald das Game abgeschlossen ist und es ist der Setmodus, müssen vom Set 1 die Legs genommen werden.
        tmpSet = player.sets[0];
    } else {
        tmpSet = getSetByPlayer(player);
    }
    if( tmpSet === undefined) {
        // sobald es kein aktives Set gibt, z.B. wenn das Game beentet ist.
        return 0;
    }
    let anzahl = tmpSet.legs.filter(leg => leg.gewonnen !== undefined && leg.gewonnen).length;
    return anzahl;
}

export function getSetByPlayer(player) {
    for (const tmpSet of player.sets) {
        if (tmpSet.abgeschlossen === undefined || !tmpSet.abgeschlossen) {
            return tmpSet;
        }
    }
    return undefined;
}

export function getWertImAktivenLeg(game, selectedScore) {
    let players = game.players;
    for (const player of players) {
        let leg = getLegByPlayer(player);
        for (const score of leg.scores) {
            if( score.counter === selectedScore) {
                return score.value;
            }
        }
    }
    return "";
}

export function getAnzahleWuerfeImAktivenLeg(game) {
    let wuerfe = 1;
    let players = game.players;
    for (const player of players) {
        let leg = getLegByPlayer(player);
        if( leg.scores.length > wuerfe) {
            wuerfe = leg.scores.length;
        }
    }
    return wuerfe;
}

export function getHoechstenScoreCounter(game) {
    let index = 1;
    let players = game.players;
    let indexScoreOhneWert = undefined;
    for (const player of players) {
        let leg = getLegByPlayer(player)
        for (const score of leg.scores) {
            if( score.value === "" && (indexScoreOhneWert === undefined || score.counter < indexScoreOhneWert) ) {
                indexScoreOhneWert = score.counter;
            } else if( score.counter > index) {
                index = score.counter;
            }
        }
    }
    if( indexScoreOhneWert ) {
        return indexScoreOhneWert;
    }
    return index;
}

export function getSelectedScoreIndex(game) {
    if( !game.selectedScoreIndex ) {
        return 1;
    }
    return game.selectedScoreIndex;
}

export function getLegByPlayer( player) {
    let tmpSet = getSetByPlayer(player);
    if( tmpSet === undefined) {
        // es gibt kein offenes Set, Game abgeschlossen.
        return undefined;
    }
    for (const leg of tmpSet.legs) {
        if (leg.abgeschlossen === undefined || !leg.abgeschlossen) {
            return leg;
        }
    }
    return undefined;
}

export function getLeg(game, playerCounter) {
    let player = getPlayer(game, playerCounter);
    return getLegByPlayer(player);
}

export function isPlayerActive(game, playerCounter, selectedScore) {
    if( game.abgeschlossen) {
        return false;
    }
    let leg = getLeg(game, playerCounter);
    let scores = leg.scores.filter(score => score.counter === selectedScore);
    return scores.length > 0;
}

export function isPlayerActiveByPlayer(game, player, selectedScore) {
    if( game.abgeschlossen) {
        return false;
    }
    let leg = getLegByPlayer(player);
    let scores = leg.scores.filter(score => score.counter === selectedScore);
    return scores.length > 0;
}

export function getPlayer(game, playerCount) {
    return game.players[playerCount];
}


export function isLegBeginner(game,playerCounter) {
    if( game.abgeschlossen) {
        return false;
    }
    if( getLeg(game, playerCounter).scores.length === 0) {
        return false;
    }
    return getLeg(game, playerCounter).scores[0].counter === 1;
}

export function canCheck(game, toGo, showFinishColors) {
    if( !showFinishColors) {
        return false;
    }
    // wir unterstützen nur Double Out
    if( game.modusId === "double_out" || game.modusId === "double_in_double_out") {
        return CHECKOUT_VALUES_DOUBLE_OUT_3_DARTS.includes(Number(toGo));
    }
    return false;
}

export function getToGo(game, playerCounter) {
    if( game.abgeschlossen) {
        return 0;
    }
    const leg = getLeg(game, playerCounter);
    let scores = leg.scores;
    let togo = Number(game.startpunkte);
    for (const score of scores) {
        if( score.togo !== "") {
            togo = score.togo;
        }
    }
    return togo;
}

export function getToGoWithAutodarts(game, playerCounter, autodartsValue) {
    if( game.abgeschlossen) {
        return 0;
    }
    const leg = getLeg(game, playerCounter);
    let scores = leg.scores;
    let togo = Number(game.startpunkte);
    for (const score of scores) {
        if( score.togo !== "") {
            togo = score.togo;
        }
    }
    return togo - autodartsValue;
}

export function getToGoByPlayer(game, player) {
    if( game.abgeschlossen) {
        return 0;
    }
    const leg = getLegByPlayer(player);
    let scores = leg.scores;
    let togo = Number(game.startpunkte);
    for (const score of scores) {
        if( score.togo !== "") {
            togo = score.togo;
        }
    }
    return togo;
}

export function getLastThrowByPlayer(game, playerCounter) {
    if( game.abgeschlossen) {
        return "-";
    }
    let player = getPlayer(game, playerCounter);

    const leg = getLegByPlayer(player);
    let scores = leg.scores;
    for(let i = scores.length - 1 ; i >= 0 ; i--) {
        let score = scores[i];
        if( score.invalid !== undefined && score.invalid && score.value !== "" ) {
            return 0
        }
        if( (score.invalid === undefined || !score.invalid) && score.value !== "") {
            return score.value;
        }
    }
    return undefined;
}


export function getSetStarter(game, setnummer) {
    let players = game.players;
    for (const player of players) {
        // sobald im Set im Leg 1 (im array 0) im score 1 (im array 0) der counter auf 1 steht, hatter der Player den ersten Wurf.
        if( player.sets[setnummer-1].legs[0].scores.length > 0 && player.sets[setnummer-1].legs[0].scores[0].counter === 1 ) {
            return player.playernummer -1;
        }
    }
    return undefined;
}

export function berchneToGoNeu(game, koordinaten) {
    let leg = game.players[koordinaten[0]].sets[koordinaten[1]].legs[koordinaten[2]];
    let scores = leg.scores;
    let togo = Number(game.startpunkte);
    let counterIndex = -1;
    for (const score of scores) {
        counterIndex++;
        if( score.value === "") {
            continue;
        }
        if (Number(score.value) <= Number(togo)) {
            let newTogo = togo - score.value;
            if( newTogo === 1 && game.modusId !== 'straight_out') {
                game.players[koordinaten[0]].sets[koordinaten[1]].legs[koordinaten[2]].scores[counterIndex].invalid = true;
                game.players[koordinaten[0]].sets[koordinaten[1]].legs[koordinaten[2]].scores[counterIndex].togo = togo;
            } else {
                game.players[koordinaten[0]].sets[koordinaten[1]].legs[koordinaten[2]].scores[counterIndex].invalid = false;
                game.players[koordinaten[0]].sets[koordinaten[1]].legs[koordinaten[2]].scores[counterIndex].togo = newTogo;
                togo = newTogo;
            }
            if( newTogo === 0) {
                game.players[koordinaten[0]].sets[koordinaten[1]].legs[koordinaten[2]].gewonnen = true;
                for (let counter = 0; counter < game.players.length; counter++) {
                    game.players[counter].sets[koordinaten[1]].legs[koordinaten[2]].abgeschlossen = true;
                }
            }
        } else {
            game.players[koordinaten[0]].sets[koordinaten[1]].legs[koordinaten[2]].scores[counterIndex].togo = togo;
            game.players[koordinaten[0]].sets[koordinaten[1]].legs[koordinaten[2]].scores[counterIndex].invalid = true;
        }
    }
    return game;
}
