import {Button, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "./CustomFontTheme";
import React, {useCallback, useEffect, useState} from "react";
import {
    get100Plus,
    get140Plus,
    get171er,
    get180er,
    getAverage,
    getAverageFirst9,
    getBestLeg,
    getGewonneneLegs,
    getGewonneneSets,
    getHighFinishes,
    getHighOut,
    getHighscores,
    getPlayer,
    getWorstLeg,
    isSetModus
} from "./ScoreHelper";
import {getFromLocalStorage} from "../localStorageZugriff";
import {NAVIGATION_ITEM} from "../constants/navigationItems";


export default function GameDetailView({id, type}) {

    const [timerCounter, setTimerCounter] = useState(120);


    let [game, setGame] = useState(undefined);
    useEffect(() => {
        let tmp = getFromLocalStorage(id);
        setGame(tmp);
    }, [id]);


    const openSchliessen = () => {
        if( type !== undefined && type === "game") {
            window.open(NAVIGATION_ITEM.GAME.route + "?id=dh_game_" + game.id, "_self");
        } else {
            window.open(NAVIGATION_ITEM.GAMEOVERVIEW_CLOSED.route, "_self");
        }
    }

    const onKeyDown = useCallback(event => {
        switch (event.key) {
            case "Escape":
            case "*":
                openSchliessen();
                event.preventDefault();
                break;
            default:
                break;
        }
        event.stopPropagation();
    }, [game]);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        }
    }, [onKeyDown]);

    useEffect(() => {
        if (timerCounter <= 0) {
            changeToDashboard();
        }

        setTimeout(() => {
            setTimerCounter(timerCounter - 1);
        }, 1000);
    }, [timerCounter]);

    const isTurnierGame = () => {
        return game.turnierId !== null && game.turnierId !== undefined;
    }

    const changeToDashboard = () => {
        if( game === null || game === undefined) {
            window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self");
        } else if( isTurnierGame()) {
            window.open(NAVIGATION_ITEM.TOURNAMENT.route, "_self");
        } else {
            window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self");
        }
    }

    const getAnzahlWuerfe = (scores) => {
        let counter = 0;
        for (const score of scores) {
            if( score.wuerfe === null || score.wuerfe === undefined) {
                counter = counter + 3;
            }
            if( score.wuerfe !== null && score.wuerfe !== undefined) {
                counter = counter + score.wuerfe;
            }
        }
        return counter;
    }

    const getHighLightsCol = (key, label, func, searchLength) => {
        let rows = [];

        if( searchLength !== undefined && searchLength ) {
            for (let playerCounter = 0 ; playerCounter < game.players.length ; playerCounter++) {
                let wert = func(game, playerCounter).length;
                rows.push(<div key={key + "_player_" + playerCounter} style={{padding:10, margin:0, borderBottom:"1px solid white"}}>{wert}</div>);
            }
        } else {
            for (let playerCounter = 0 ; playerCounter < game.players.length ; playerCounter++) {
                let wert = func(game, playerCounter);
                rows.push(<div key={key + "_player_" + playerCounter} style={{padding:10, margin:0, borderBottom:"1px solid white"}}>{wert}</div>);
            }
        }

        return <div key={key} style={{padding:0, borderRight:"1px solid white", textAlign:"center"}}>
            <div style={{padding:10, margin:0, borderBottom:"1px solid white", whiteSpace: "nowrap"}}>{label}</div>
            {rows}
        </div>
    }

    const getHightLights =() => {
        let cols = [];
        cols.push(getHighLightsCol("key_hightlight_average", "Average", getAverage));
        cols.push(getHighLightsCol("key_hightlight_averagefirst9", "First 9 Avg.", getAverageFirst9));
        cols.push(getHighLightsCol("key_hightlight_100plus", "100+", get100Plus));
        cols.push(getHighLightsCol("key_hightlight_140plus", "140+", get140Plus));
        cols.push(getHighLightsCol("key_hightlight_171", "171", get171er));
        cols.push(getHighLightsCol("key_hightlight_180", "180", get180er));
        cols.push(getHighLightsCol("key_hightlight_best", "Best", getBestLeg));
        cols.push(getHighLightsCol("key_hightlight_worst", "Worst", getWorstLeg));
        cols.push(getHighLightsCol("key_hightlight_highout", "High Out", getHighOut));
        cols.push(getHighLightsCol("key_hightlight_highfinishes", "Highfinishes", getHighFinishes, true));
        return <div style={{display:"flex", flexDirection:"row", fontSize:20, color:"white", marginLeft:20, marginBottom:20, overflowX: "scroll"}}>
            {getPlayerCol("highlights")}
            {cols}
        </div>
    }

    const getPlayerCol = (key) => {
        let rows = [];

        for (let playerCounter = 1 ; playerCounter <= game.players.length ; playerCounter++) {
            rows.push(<div key={"key_" + key + "_row_player_" + playerCounter} style={{padding:10, margin:0, borderBottom:"1px solid white", width:110}}>Spieler {playerCounter}</div>);
        }

        return <div key={"key_" + key + "_area_player_"} style={{padding:0, borderRight:"1px solid white", textAlign:"center"}}>
            <div style={{padding:10, margin:0, borderBottom:"1px solid white"}}>-</div>
            {rows}
        </div>
    }

    const getHightscores = () => {
        let rows = [];
        for (let playerCounter = 0 ; playerCounter < game.players.length ; playerCounter++) {
            let tmpWert = getHighscores(game, playerCounter);
            rows.push(<div key={"key_hightlight_highscores_row_player_" + playerCounter} style={{padding:10, margin:0, borderBottom:"1px solid white"}}>{tmpWert !== "" ? tmpWert : "-"}</div>);
        }

        return <div style={{display:"flex", flexDirection:"row", fontSize:20, color:"white", marginLeft:20, marginBottom:20}}>
            {getPlayerCol("highscores")}
            <div key={"key_hightlight_highscores"} style={{padding:0, textAlign:"center"}}>
                <div style={{padding:10, margin:0, borderBottom:"1px solid white"}}>Highscores</div>
                {rows}
            </div>
        </div>
    }

    const getHightfinishes =() => {
        let rows = [];
        for (let playerCounter = 0 ; playerCounter < game.players.length ; playerCounter++) {
            let highFinishes = getHighFinishes(game, playerCounter);
            let wert = "";
            for (const tmp of highFinishes) {
                if( wert !== "") {
                    wert += ", ";
                }
                wert += tmp;
            }
            rows.push(<div key={"key_hightlight_highfinishes_row_player_" + playerCounter} style={{padding:10, margin:0, borderBottom:"1px solid white"}}>{wert !== "" ? wert : "-"}</div>);
        }

        return <div style={{display:"flex", flexDirection:"row", fontSize:20, color:"white", marginLeft:20, marginBottom:20}}>
            {getPlayerCol("highfinishes")}
            <div key={"key_hightlight_highfinishes"} style={{padding:0, textAlign:"center"}}>
                <div style={{padding:10, margin:0, borderBottom:"1px solid white"}}>Highfinishes</div>
                {rows}
            </div>
        </div>
    }

    const getBestRow = () => {
        let cols = [];
        let sets = game.players[0].sets;

        for(let setCounter = 0 ; setCounter < sets.length ; setCounter++) {
            let tmpSet = game.players[0].sets[setCounter];
            let legs = tmpSet.legs;
            for(let legCounter = 0 ; legCounter < legs.length ; legCounter++) {
                let tmpleg = legs[legCounter];
                let rows = [];
                for(let playerCounter = 0 ; playerCounter < game.players.length ; playerCounter++) {
                    let leg = game.players[playerCounter].sets[setCounter].legs[legCounter];
                    let tmpString = "-";
                    if( leg.gewonnen !== null && leg.gewonnen !== undefined && leg.gewonnen) {
                        tmpString = getAnzahlWuerfe(leg.scores);
                    } else {
                        tmpString = "-";
                    }
                    rows.push(<div key={"key_best_set_" + setCounter + "_leg_" + legCounter +"_player_" + playerCounter} style={{padding:10, margin:0, borderBottom:"1px solid white"}}>{tmpString}</div>)
                }
                cols.push(<div key={"key_best_set_" + setCounter + "_leg_" + legCounter} style={{padding:0, borderRight:"1px solid white", textAlign:"center"}}>
                    <div style={{padding:10, margin:0, borderBottom:"1px solid white"}}>S:{tmpSet.setnummer}/L:{tmpleg.legnummer}</div>
                    {rows}
                </div>);
            }
        }
        return <div style={{display:"flex", flexDirection:"row", fontSize:20, color:"white", marginLeft:20, overflow:"auto"}}>
            {getPlayerCol("best")}
            {cols}
        </div>
    }

    const getErgebnis = () => {
        let tmpIsSetModus = isSetModus(game);

        let tmpName = "";
        let tmpErgebnis = "";
        for (let playerCounter = 0 ; playerCounter < game.players.length ; playerCounter++) {
            let playerName = getPlayer(game, playerCounter).name;
            if( tmpName !== "") {
                tmpName += " vs. ";
            }
            tmpName += playerName + " ( Spieler " + (playerCounter + 1) + " )";
            let tmpGewonnen;
            if( tmpIsSetModus) {
                tmpGewonnen = getGewonneneSets(game, playerCounter);
            } else {
                tmpGewonnen = getGewonneneLegs(game, playerCounter);
            }
            if( tmpErgebnis !== "") {
                tmpErgebnis += " : ";
            }
            tmpErgebnis += tmpGewonnen;
        }

        return <div style={{fontSize:20, color:"white", marginLeft:20, marginBottom:20}}>
            <div>{tmpName}</div>
            <div>Ergebnis: {tmpErgebnis}</div>
        </div>
    }

    const header = (text) => {
        return <div style={{backgroundColor:"#ac1c27", color:"white", fontSize:30, padding:20}}>{text}</div>
    }

    if( game === undefined) {
        return <div>Wir laden...</div>
    }

    if( game.abgeschlossen === null || game.abgeschlossen === undefined || !game.abgeschlossen ) {
        return <div style={{height: "100%", minHeight: "100vh", width: "100%", backgroundColor:"#022836", paddingBottom:20}}>
            <div style={{fontSize:30, color:"white",padding:20}}>
                <span style={{marginRight:50}}>Details zum Game</span>
                <ThemeProvider theme={CustomFontTheme}>
                    <Button variant="text" onClick={() => openSchliessen()}>Schliessen (ESC o. *)</Button>
                </ThemeProvider>
            </div>
            <div style={{color: "white", marginTop: 20, fontSize:30, padding:20}}>Du wirst in {timerCounter} Sekunden auf das Dashboard weitergeleitet.</div>
            {header("Spiel muss abgeschlossen sein.")}
        </div>
    }

    return <div style={{height: "100%", minHeight: "100vh", width: "100%", backgroundColor:"#022836", paddingBottom:20}}>
        <div style={{position:"absolute", right:0, top:0, fontSize:30, color:"white",padding:20}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" onClick={() => openSchliessen()}>Schliessen (ESC o. *)</Button>
            </ThemeProvider>
        </div>
        <div style={{fontSize:30, color:"white",padding:20}}>
            <span style={{marginRight:50}}>Details zum Game</span>
        </div>
        <div style={{color: "white", marginTop: 20, fontSize:30}}>Du wirst in {timerCounter} Sekunden auf das Dashboard weitergeleitet.</div>
        {header("Ergebnis")};
        {getErgebnis()}
        {header("Highlights")};
        {getHightLights()}
        {getHightfinishes()}
        {getHightscores()}
        {header("Checkdarts")};
        {getBestRow()}
    </div>
}
