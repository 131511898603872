import {FormControl, NativeSelect, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";

export function StatisticOrientationComponent({label, value, onChanged}) {
    const getOptions = () => {
        let options = [];
        options.push(<option key={"key_statistic_liste" + label} value="liste">Liste</option>);
        options.push(<option key={"key_statistic_kachel" + label} value="kachel">Kachel</option>);
        return options;
    }

    return <FormControl style={{width:300, color:"white"}}>
        <ThemeProvider theme={CustomFontTheme}>
            <NativeSelect
                sx={{color:"white"}}
                defaultValue={value}
                onChange={(event) => onChanged(event.target.value)}
                inputProps={{
                    name: 'statisticorientation',
                    id: 'uncontrolled-native',
                    sx:{color:"black", backgroundColor:"gray"}
                }}
            >
                {getOptions()}
            </NativeSelect>
        </ThemeProvider>
    </FormControl>
}
