import React from "react";
import {makeStyles} from "@mui/styles";
import {Button, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "./CustomFontTheme";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import UndoOutlined from "@mui/icons-material/UndoOutlined";
import KeyboardOutlined from '@mui/icons-material/KeyboardOutlined';

const useStyles = makeStyles({
    row: {
        width: '100%',
        padding: 0,
        margin: 0,
    },
    column: {
        display: "table-cell",
        textAlign: "center",
        verticalAlign: "middle",
        border: "1px solid #064d6b",
        color: "white",
        fontSize: 30,
        fontWeight: "bold",
        backgroundColor:"#056f9a",
    },
});

export default function TabletTastaturQuerformatFastScoreArea({selectedValue, showTableScoreValue, showFastScoreButtons, showUndo, leftScoreAktiv, firstAktive, secondAktive, thirdAktive, gameAbgeschlossen, fontSize, onFinishLeg, onSaveLeftScore, onShowEinstellungenDialog, onChangeToDashboard, onChangeToFormat, onUndo, onKeyboardChange}) {

    const classes = useStyles();

    const addRestColumn = (value, action, active) => {
        if( active ) {
            return <div className={"col " + classes.column} style={{borderTop:"3px solid white", width:"100%", fontSize:fontSize}} onClick={() => action()}>
                {value}
            </div>
        }
        return <div className={"col " + classes.column} style={{borderTop:"3px solid white", width:"100%", backgroundColor:"gray", fontSize:fontSize}}>
            {value}
        </div>
    }

    return <div style={{margin:0, width:"100%"}}>
        {showTableScoreValue && <div style={{position:"absolute", top:-180, right:0, width:300, height:180, textAlign:"center", backgroundColor:"yellow", fontSize:150}}>{selectedValue}</div> }
        <ThemeProvider theme={CustomFontTheme}>
            <Button variant="text" startIcon={<ExitToAppOutlinedIcon style={{fontSize: 50}}/>}
                    onClick={() => onChangeToDashboard()}/>
        </ThemeProvider>
        {!gameAbgeschlossen &&
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" startIcon={<SettingsOutlinedIcon style={{fontSize: 50}}/>}
                        onClick={() => onShowEinstellungenDialog()}/>
            </ThemeProvider>
        }
        <ThemeProvider theme={CustomFontTheme}>
            <Button variant="text" startIcon={<FormatSizeIcon style={{fontSize: 50}}/>}
                    onClick={() => onChangeToFormat()}/>
        </ThemeProvider>
        {showUndo &&
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" startIcon={<UndoOutlined style={{fontSize: 50}}/>}
                        onClick={() => onUndo()}/>
            </ThemeProvider>
        }
        <ThemeProvider theme={CustomFontTheme}>
            <Button variant="text" startIcon={<KeyboardOutlined style={{fontSize: 50}}/>}
                    onClick={() => onKeyboardChange()}/>
        </ThemeProvider>

        {showFastScoreButtons &&
            <div className="grid" style={{padding:0, margin:0, width:"100%"}}>
                    {addRestColumn("Rest", () => onSaveLeftScore(), leftScoreAktiv)}
                    {addRestColumn("1st", () => onFinishLeg(1), firstAktive)}
                    {addRestColumn("2nd", () => onFinishLeg(2), secondAktive)}
                    {addRestColumn("3rd", () => onFinishLeg(3), thirdAktive)}
            </div>
        }
    </div>
}
