import {getAllGamesFromLocalStorage, removeFromLocalStorage} from "./localStorageZugriff";
import moment from "moment/moment";

export default function deleteGames()  {
    let games = getAllGamesFromLocalStorage()
    let searchTimestamp = moment().subtract(14, "d");
    games.forEach(game => {
        let isBefore = moment(game.timestamp).isBefore(searchTimestamp)
        if( isBefore) {
            removeFromLocalStorage("dh_game_" + game.id);
        }
    })
}
