import {getLeg} from "./ScoreHelper";
import ScoreElement from "./ScoreElement";
import React from "react";

function StartpunkteArea({players, startpunkte, fontSize}) {
    let lineStyle = {
        verticalAlign: "middle",
        textAlign: "center",
        borderBottom: "1px solid black",
        margin: 0,
        padding: 0,
        backgroundColor: "#064d6b",
        color: "white",
        fontSize: fontSize,
    };

    let colsStartpunkte = [];
    colsStartpunkte.push(<div key={"key_startpunkte_wurf_beginn"}><AnzahlWurfArea counter={undefined} fontSize={fontSize} divkey={"begin"}/></div>);
    for (const player of players) {
        colsStartpunkte.push(<div key={"key_startpunkte_player_one_" + player.playernummer} className="col"></div>);
        colsStartpunkte.push(<div key={"key_startpunkte_player_two_" + player.playernummer} className="col" style={{borderRight: "1px solid gray"}}>{startpunkte}</div>);
    }
    colsStartpunkte.push(<div key={"key_startpunkte_wurf_ende"}><AnzahlWurfArea counter={undefined} fontSize={fontSize} divkey={"ende"}/></div>);
    return <div key={"key_startpunkte_complete"} className={"grid"} style={lineStyle}>
        {colsStartpunkte}
    </div>;
}

function AnzahlWurfArea({counter, fontSize, divkey}) {
    return <div key={"key_wurf_area_" + counter + "_" + divkey} className={"col-fixed" }
         style={{
             width: 90,
             margin: "auto",
             verticalAlign: "middle",
             borderRight: "1px solid gray",
             fontSize: fontSize,
         }}>{counter === undefined ? "-" : (counter + 1) * 3}</div>
}

function HeaderArea({players, fontSize}) {
    let colsHeader = [];
    colsHeader.push(<div key={"key_header_col_first"} className={"col-fixed"} style={{borderBottom: "2px solid black", width: 90, backgroundColor: "white"}}/>);
    for (const player of players) {
        colsHeader.push(<div key={"key_header_col_one_" + player.playernummer} className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>scored</div>);
        colsHeader.push(<div key={"key_header_col_two_" + player.playernummer} className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>to go</div>);
    }
    colsHeader.push(<div key={"key_header_col_last"} className={"col-fixed"} style={{borderBottom: "2px solid black", width: 90, backgroundColor: "white"}}/>);
    return <div key={"key_header"} className="grid" style={{
        width: "100%",
        padding: 0,
        margin: 0,
        textAlign: "center",
        alignContent:"center",
        fontWeight: "bold",
        fontSize: fontSize,
    }}>
        {colsHeader}
    </div>;
}

export function ScoreAreaTablet({game, players, anzScores, startpunkte, selectedScore, selectedValue, fontSize, fontSizeHeader, setSelectedScore}) {
    let lineStyle = {
        verticalAlign: "middle",
        textAlign: "center",
        borderBottom: "1px solid black",
        margin: 0,
        padding: 0,
        backgroundColor: "#064d6b",
        color: "white",
    };

    let rows = [];
    rows.push(<StartpunkteArea players={players} startpunkte={startpunkte} fontSize={fontSize}/>);
    for (let i = 0; i < anzScores; i++) {
        let modulo = i % 2;
        let currentStyle = modulo === 1 ? {...lineStyle, backgroundColor: "#064d6b"} : {
            ...lineStyle,
            backgroundColor: "#022836"
        };
        let playerScoreCols = [];
        playerScoreCols.push(<div key="key_anzahlwurfarea_1"><AnzahlWurfArea counter={i} fontSize={fontSize} divkey={"begin"}/></div>);
        for (const player of players) {
            let scores= getLeg(game, player.playernummer -1).scores
            let scorePlayer = scores[i];

            if( scorePlayer !== undefined ) {
                playerScoreCols.push(<ScoreElement isTablet={true} scorecounter={scorePlayer.counter} selectedScore={selectedScore}
                                                   selectedValue={selectedValue} value={scorePlayer.value}
                                                   invalid={scorePlayer.invalid !== undefined ? scorePlayer.invalid : false} fontSize={fontSize} setSelectedScore={setSelectedScore}/>);
            } else{
                playerScoreCols.push(<div className="col"></div>);
            }
            playerScoreCols.push(<div className="col" style={{borderRight: "1px solid gray", fontSize:fontSize}}>{scorePlayer !== undefined && scorePlayer.togo}</div>);
        }
        playerScoreCols.push(<div key="key_anzahlwurfarea_2"><AnzahlWurfArea counter={i} fontSize={fontSize} divkey={"end"}/></div>);
        rows.push(<div key={"score_" + i} className="grid" style={currentStyle}>
            {playerScoreCols}
        </div>)
    }

    rows.push(<div key="key_headerarea"><HeaderArea players={players} fontSize={fontSizeHeader}/></div>);

    let rowsGedreht = rows.reverse();
    return <div className="grid"
                style={{
                    margin: 0,
                    padding: 0,
                }}>
        <div className="col" style={{margin: 0, padding: 0}}>
            {rowsGedreht}
        </div>
    </div>
}
