import './settings.css';
import {useEffect, useState} from "react";
import {YesNoComponent} from "./YesNoComponent";
import {getFromLocalStorage, saveToLocalStorage} from "../localStorageZugriff";
import {DesktopTabletComponent} from "./DesktopTabletComponent";
import {TextField, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";


function SettingsAnzeige() {

    const [geraet, setGeraet] = useState ( getFromLocalStorage("dh_default_geraetetyp"));
    const [showFinishQuestion, setShowFinishQuestion] = useState ( getFromLocalStorage("dh_default_x01_show_finishquestion"));
    const [showCheckout, setShowCheckout] = useState ( getFromLocalStorage("dh_default_x01_show_checkout"));
    const [showAverage, setShowAverage] = useState ( getFromLocalStorage("dh_default_x01_show_average"));
    const [showAverageFirst9, setShowAverageFirst9] = useState ( getFromLocalStorage("dh_default_x01_show_average_first_9"));
    const [showAverageLeg, setShowAverageLeg] = useState ( getFromLocalStorage("dh_default_x01_show_average_leg"));
    const [show100Plus, setShow100Plus] = useState ( getFromLocalStorage("dh_default_x01_show_100plus"));
    const [show140Plus, setShow140Plus] = useState ( getFromLocalStorage("dh_default_x01_show_140plus"));
    const [show171, setShow171] = useState ( getFromLocalStorage("dh_default_x01_show_171"));
    const [show180, setShow180] = useState ( getFromLocalStorage("dh_default_x01_show_180"));
    const [showBestLeg, setShowBestLeg] = useState ( getFromLocalStorage("dh_default_x01_show_bestleg"));
    const [showWorstLeg, setShowWorstLeg] = useState ( getFromLocalStorage("dh_default_x01_show_worstleg"));
    const [showHighOut, setShowHighOut] = useState ( getFromLocalStorage("dh_default_x01_show_highout"));
    const [showFinishColors, setShowFinishColors] = useState ( getFromLocalStorage("dh_default_x01_show_finish_colors"));
    const [showTableScoreAreaBig, setShowTableScoreAreaBig] = useState ( getFromLocalStorage("dh_default_show_table_score_value"));
    const [secondsToDashboard, setSecondsToDashboard] = useState ( getFromLocalStorage("dh_default_seconds_to_dashboard"));
    const [secondsToSendTournamentGame, setSecondsToSendTournamentGame] = useState ( getFromLocalStorage("dh_default_seconds_to_send_tournament_game"));

    useEffect(() => {
        saveToLocalStorage("dh_default_geraetetyp", geraet);
        saveToLocalStorage("dh_default_x01_show_finishquestion", showFinishQuestion);
        saveToLocalStorage("dh_default_x01_show_checkout", showCheckout);
        saveToLocalStorage("dh_default_x01_show_average", showAverage);
        saveToLocalStorage("dh_default_x01_show_average_first_9", showAverageFirst9);
        saveToLocalStorage("dh_default_x01_show_average_leg", showAverageLeg);
        saveToLocalStorage("dh_default_x01_show_100plus", show100Plus);
        saveToLocalStorage("dh_default_x01_show_140plus", show140Plus);
        saveToLocalStorage("dh_default_x01_show_171", show171);
        saveToLocalStorage("dh_default_x01_show_180", show180);
        saveToLocalStorage("dh_default_x01_show_bestleg", showBestLeg);
        saveToLocalStorage("dh_default_x01_show_worstleg", showWorstLeg);
        saveToLocalStorage("dh_default_x01_show_highout", showHighOut);
        saveToLocalStorage("dh_default_x01_show_finish_colors", showFinishColors);
        saveToLocalStorage("dh_default_show_table_score_value", showTableScoreAreaBig);
        saveToLocalStorage("dh_default_seconds_to_dashboard", secondsToDashboard);
        saveToLocalStorage("dh_default_seconds_to_send_tournament_game", secondsToSendTournamentGame);
    }, [geraet, showFinishQuestion, showCheckout, showAverage, showAverageFirst9,
        showAverageLeg, show100Plus, show140Plus, show171, show180, showBestLeg, showWorstLeg,
        showHighOut, showFinishColors, showTableScoreAreaBig, secondsToDashboard, secondsToSendTournamentGame]);

    return <div className="grid">
        <div className="col" style={{width: 300}}>
            <h2>Geräteeinstellung</h2>
            <div style={{marginBottom: 40}}>
                <DesktopTabletComponent value={geraet} onChanged={(value) => setGeraet(value)}/>
                <div style={{fontSize: 18}}>Hinweis: Wähle ob du ein Desktopgerät mit Tastatur oder ein
                    Tablet/Touchgerät hast. Beim Scoring wird dir dann eine optimierte Anzeige bereitgestellt.
                </div>
            </div>
            <h2>Einstellung Anzeige im Game</h2>
            <div style={{marginBottom: 40}}>
                <YesNoComponent label="Frage Anz. der Finishwürfe bgzl. Avg. Berechnung" value={showFinishQuestion}
                                onChanged={(value) => setShowFinishQuestion(value)}/>
                <div style={{fontSize: 18}}>Hinweis: Sobald du hier auf 'Ja' stellst, wirst du beim Finish gefragt mit
                    welchem Wurf du getroffen hast. Damit ist dein Average genauer, kann aber im Game auch stören.<br/>Bei
                    'Nein' werden 3 Würfe angenommen, dies verzerrt den Avg. leicht.
                </div>
            </div>
            <div style={{marginBottom: 40}}>
                <YesNoComponent label="Checkout" value={showCheckout} onChanged={(value) => setShowCheckout(value)}/>
                <div style={{fontSize: 18}}>Hinweis: Checkouts werden nur bei double out angezeigt und sobald eine
                    Checkoutmöglichkeit vorhanden ist.
                </div>
            </div>
            <div style={{marginBottom: 20}}>
                <YesNoComponent label="Average" value={showAverage} onChanged={(value) => setShowAverage(value)}/>
            </div>
            <div style={{marginBottom: 20}}>
                <YesNoComponent label="First 9 Average" value={showAverageFirst9}
                                onChanged={(value) => setShowAverageFirst9(value)}/>
            </div>
            <div style={{marginBottom: 40}}>
                <YesNoComponent label="Leg Average" value={showAverageLeg}
                                onChanged={(value) => setShowAverageLeg(value)}/>
            </div>
            <div style={{marginBottom: 20}}>
                <YesNoComponent label="100+" value={show100Plus} onChanged={(value) => setShow100Plus(value)}/>
            </div>
            <div style={{marginBottom: 20}}>
                <YesNoComponent label="140+" value={show140Plus} onChanged={(value) => setShow140Plus(value)}/>
            </div>
            <div style={{marginBottom: 20}}>
                <YesNoComponent label="171" value={show171} onChanged={(value) => setShow171(value)}/>
            </div>
            <div style={{marginBottom: 40}}>
                <YesNoComponent label="180" value={show180} onChanged={(value) => setShow180(value)}/>
            </div>
            <div style={{marginBottom: 20}}>
                <YesNoComponent label="Best Leg" value={showBestLeg} onChanged={(value) => setShowBestLeg(value)}/>
            </div>
            <div style={{marginBottom: 20}}>
                <YesNoComponent label="Worst Leg" value={showWorstLeg} onChanged={(value) => setShowWorstLeg(value)}/>
            </div>
            <div style={{marginBottom: 20}}>
                <YesNoComponent label="High Out" value={showHighOut} onChanged={(value) => setShowHighOut(value)}/>
            </div>
            <div style={{marginBottom: 20}}>
                <YesNoComponent label="Farben bei möglichem Finish" value={showFinishColors}
                                onChanged={(value) => setShowFinishColors(value)}/>
            </div>
            <div style={{marginBottom: 20}}>
                <YesNoComponent label="Tablet - Score groß anzeigen" value={showTableScoreAreaBig}
                                onChanged={(value) => setShowTableScoreAreaBig(value)}/>
            </div>
            <div style={{marginBottom: 20}}>
                <ThemeProvider theme={CustomFontTheme}>
                    <TextField id="standard-dashboard" type="number" label="Sekunden > Dashboard" variant="standard"
                               InputProps={{inputProps: {min: 5}}}
                               value={secondsToDashboard} onChange={(e) => setSecondsToDashboard(e.target.value)}/>
                </ThemeProvider>
                <div>Nach einem Game wirst du automatisch auf das Dashboard umgeleitet. Hier kannst du die Sekunden bis zur Weiterleitung einstellen.
                </div>
            </div>
            <div style={{marginBottom: 20}}>
                <ThemeProvider theme={CustomFontTheme}>
                    <TextField id="standard-send-game" type="number" label="Sekunden > Gameübertragen "
                               variant="standard"
                               InputProps={{inputProps: {min: 5}}}
                               value={secondsToSendTournamentGame} onChange={(e) => setSecondsToSendTournamentGame(e.target.value)}/>
                </ThemeProvider>
                <div>DH stellt nach einem Turniergame die Frage ob das Game übertragen werden soll. Hier kannst du die Sekunden einstellen wann es automatisch übertragen wird. Bitte beachte, der Dialog soll verhindern dass Spiele übertragen werden die versehentlich beendet wurden.!!!</div>
            </div>

        </div>
    </div>
}

export default SettingsAnzeige;
