export const MODUS_ZUR_AUSWAHL = [
    {bezeichnung: 'straight out', id: 'straight_out'},
    {bezeichnung: 'double out', id: 'double_out'},
    {bezeichnung: 'do.in do.out', id: 'double_in_double_out'},
    {bezeichnung: 'triple out', id: 'triple_out'},
    {bezeichnung: 'master out', id: 'master_out'},
];

export function getModusById(modusId) {
    if( modusId === undefined || modusId === null) {
        return {bezeichnung: '', id: ''};
    }
    for (const moduszurauswahlElement of MODUS_ZUR_AUSWAHL) {
        if( moduszurauswahlElement.id === modusId) {
            return moduszurauswahlElement;
        }
    }
    return {bezeichnung: '', id: ''};
}
