import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppContainer from "./AppContainer";
import {BrowserRouter} from "react-router-dom";
import updateLocalStorage from "./update";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

console.log(`This is: ${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)

updateLocalStorage();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppContainer/>
        </BrowserRouter>
    </React.StrictMode>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();