import React, {useCallback, useEffect, useState} from 'react';
import {getFromLocalStorage, saveToLocalStorage} from "../localStorageZugriff";
import {Alert, Button, Snackbar, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "./CustomFontTheme";
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import {ModusArea} from "./ScoreComponents";
import {ermittleGewinner, getPlayer} from "./ScoreHelper";
import {isDesktop, isSmartphone} from "../util/GeraeteArtUtil";
import FetchUtil from "../util/FetchUtil";
import {GameUebermittelnArea} from "./GameUebermittelnArea";

export default function ScoringEdartX01View({gameId}) {

    const [game, setGame] = useState(undefined);
    const [message, setMessage] = useState(undefined);
    const url = getFromLocalStorage("dh_url");
    const token = getFromLocalStorage("dh_token");
    const boardToken = getFromLocalStorage("dh_board_token");
    const boardName = getFromLocalStorage("dh_board_bezeichnung");
    const [waitingSeconds, setWaitingSeconds] = useState(undefined);
    const [showUebermittelnArea, setShowUebermittelnArea] = useState(false);

    const [lastChangePlayer, setLastChangePlayer] = useState(undefined);
    const [legsPlayer1, setLegsPlayer1] = useState(0);
    const [legsPlayer2, setLegsPlayer2] = useState(0);

    useEffect(() => {
        let game = getFromLocalStorage(gameId);
        setGame(game);
    }, [gameId]);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if( waitingSeconds === undefined) {
                clearInterval(myInterval);
                return;
            }
            if( waitingSeconds <= 0 ) {
                clearInterval(myInterval);
                changeToDashboard();
                return;
            }
            setWaitingSeconds(waitingSeconds - 1);
        }, 1000);

        return () => clearInterval(myInterval);
    }, [waitingSeconds]);

    const changePlayer1 = (value) => {
        setLegsPlayer1(legsPlayer1 + value);
        setLastChangePlayer(1);
        sendGameShortInfoToBackend(game, legsPlayer1 + value, legsPlayer2);
    }

    const changePlayer2 = (value) => {
        setLegsPlayer2(legsPlayer2 + value);
        setLastChangePlayer(2);
        sendGameShortInfoToBackend(game, legsPlayer1, legsPlayer2 + value);
    }

    const onKeyDown = useCallback((event) => {
        if( showUebermittelnArea) {
            return;
        }

        switch (event.key) {
            case "Escape":
            case "*":
                changeToDashboard();
                event.preventDefault();
                break;
            case "F1":
            case "1":
                if( legsPlayer1 > 0) {
                    changePlayer1(-1);
                }
                event.preventDefault();
                break;
            case "F2":
            case "2":
                changePlayer1(1);
                setGameAbgeschlossen(legsPlayer1 + 1, legsPlayer2);
                event.preventDefault();
                break;
            case "F3":
            case "3":
                if( legsPlayer2 > 0) {
                    changePlayer2(-1);
                }
                event.preventDefault();
                break;
            case "F4":
            case "4":
                changePlayer2(1);
                setGameAbgeschlossen(legsPlayer1 , legsPlayer2 + 1);
                event.preventDefault();
                break;
            case "F5":
            case "F6":
            case "F7":
            case "F8":
            case "F9":
            case "F10":
            case "F11":
            case "F12":
                // F Tasten werden damit abgeschalten.
                event.preventDefault();
                break;
            default:
                break;
        }
        event.stopPropagation();
    }, [changePlayer1, changePlayer2]);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        }
    }, [onKeyDown]);

    const setGameAbgeschlossen = (tmpLegsPlayer1, tmpLegsPlayer2) => {
        if( game === undefined) {
            return;
        }
        if( game.firsttolegs !== undefined && game.firsttolegs !== "" ) {
            let tmpGame = {...game};
            let firsttolegs = Number(game.firsttolegs);
            let player1Gewonnen = tmpLegsPlayer1 === firsttolegs;
            let player2Gewonnen = tmpLegsPlayer2 === firsttolegs;
            if( player1Gewonnen) {
                tmpGame.players[0].gewonnen = true;
            }
            if( player2Gewonnen) {
                tmpGame.players[1].gewonnen = true;
            }
            if( player1Gewonnen || player2Gewonnen) {
                let tmpGame = game;
                tmpGame.abgeschlossen = true;
                setGame(tmpGame);
                setShowUebermittelnArea(true);
            }
        } else if( game.bestoflegs !== undefined && game.bestoflegs !== "" ) {
            let bestoflegs = Number(game.bestoflegs);
            if( (tmpLegsPlayer1 + tmpLegsPlayer2) === bestoflegs) {
                // es wurde die maximale anzahl an legs gespielt, unentschieden. set ist beendet.
                // kann nur vorkommen, wenn kein Setmodus (technisch immer set modus, dann halt nur 1 set)
                let tmpGame = game;
                let player1Gewonnen = tmpLegsPlayer1 > tmpLegsPlayer2;
                let player2Gewonnen = tmpLegsPlayer2 > tmpLegsPlayer1;
                if( player1Gewonnen) {
                    tmpGame.players[0].gewonnen = true;
                } else  if( player2Gewonnen) {
                    tmpGame.players[1].gewonnen = true;
                }
                if( tmpLegsPlayer1 === tmpLegsPlayer2) {
                    tmpGame.unentschieden = true;
                }
                tmpGame.abgeschlossen = true;
                setGame(tmpGame);
                setShowUebermittelnArea(true);
            }
            let legsZumSieg = Math.floor(bestoflegs / 2) + 1;
            if( legsZumSieg === tmpLegsPlayer1 || legsZumSieg === tmpLegsPlayer2 ) {
                let tmpGame = game;
                let player1Gewonnen = tmpLegsPlayer1 > tmpLegsPlayer2;
                let player2Gewonnen = tmpLegsPlayer2 > tmpLegsPlayer1;
                if( player1Gewonnen) {
                    tmpGame.players[0].gewonnen = true;
                } else  if( player2Gewonnen) {
                    tmpGame.players[1].gewonnen = true;
                }
                if( tmpLegsPlayer1 === tmpLegsPlayer2) {
                    tmpGame.unentschieden = true;
                }
                tmpGame.abgeschlossen = true;
                setGame(tmpGame);
                setShowUebermittelnArea(true);
            }
        }
    }

    const isTurnierGame = () => {
        return game.turnierId !== null && game.turnierId !== undefined;
    }

    const changeToDashboard = () => {
        if( game === null || game === undefined) {
            window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self");
        } else if( isTurnierGame()) {
            window.open(NAVIGATION_ITEM.TOURNAMENT.route, "_self");
        } else {
            window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self");
        }
    }

    const sendGameShortInfoToBackend = async (tmpGame, tmpLegsPlayer1, tmpLegsPlayer2) => {
        if (!tmpGame.publishTournament) {
            // nur wenn Spiel veröffentlicht wird.
            return;
        }

        let players = [];
        players.push({playerId: tmpGame.players[0].id, name: tmpGame.players[0].name, gewonneneLegs: tmpLegsPlayer1});
        players.push({playerId: tmpGame.players[1].id, name: tmpGame.players[1].name, gewonneneLegs: tmpLegsPlayer2});
        let data = {gameId: tmpGame.id, tournamentId: tmpGame.turnierId,
            modusId: tmpGame.modusId, startpunkte: Number(tmpGame.startpunkte),
            boardId: tmpGame.boardId,
            boardBezeichnung: tmpGame.boardBezeichnung,
            schreiberId: tmpGame.schreiberId,
            schreiberName: tmpGame.schreiberName,
            dartType: tmpGame.dartType,
            inGame: tmpLegsPlayer1 > 0 || tmpLegsPlayer2 > 0 ? false : true,
            startssoon: false,
            sets: Number(tmpGame.sets), bestOfLegs: tmpGame.bestoflegs !== undefined ? Number(tmpGame.bestoflegs) : undefined,
            firstToLegs: tmpGame.firsttolegs !== undefined ? Number(tmpGame.firsttolegs) : undefined,
            boardName: boardName, gewinnerPlayerId: undefined, abgeschlossen: tmpGame.abgeschlossen, dhGameShortPlayerInfoDtos: players};
        FetchUtil.fetchPost(url + "/saveShortInfo", token, boardToken,
            data,
            () => {},
            responseNotOk => {
                // nix tun, es gibt Probleme aber an der Stelle unwichtig
                console.log("Fehler: " + responseNotOk.message);
            },
            error => {
                // nix tun, es gibt Probleme aber an der Stelle unwichtig
                console.log("Fehler: " + error.message);
            });
    }

    const uebertrageErgebnisAnsBackend = () => {
        if( game.turnierId === undefined || game.turnierId === null ) {
            return;
        }
        FetchUtil.fetchPost(url + "/saveEdartGame", token, boardToken,
            {gameId: game.id, legsPlayer1: legsPlayer1, legsPlayer2: legsPlayer2},
            () => {
                let tmpGame = game;
                tmpGame.uebertragen = "OK";
                setWaitingSeconds(30);
                saveToLocalStorage("dh_game_" + game.id, tmpGame);
                setShowUebermittelnArea(false);
            },
            (responseNotOk) => {
                // nix tun, es gibt Probleme aber an der Stelle unwichtig
                console.log("Fehler: " + responseNotOk.message);
                let tmpGame = game;
                tmpGame.uebertragen = "FEHLER";
                setGame(tmpGame);
                saveToLocalStorage("dh_game_" + game.id, tmpGame);
                setShowUebermittelnArea(false);
            },
            (error) => {
                // nix tun, es gibt Probleme aber an der Stelle unwichtig
                console.log("Fehler: " + error.message);
                let tmpGame = game;
                tmpGame.uebertragen = "FEHLER";
                setGame(tmpGame);
                saveToLocalStorage("dh_game_" + game.id, tmpGame);
                setShowUebermittelnArea(false);
            });
    }

    if( game === undefined) {
        return <div>Wir laden das Spiel für dich....</div>
    }

    if( showUebermittelnArea ) {
        let gewinnerName = undefined;
        let gewinner = ermittleGewinner(game);
        if( gewinner !== undefined) {
            gewinnerName = gewinner.name;
        }
        let unentschieden = legsPlayer1 === legsPlayer2;

        return <GameUebermittelnArea
            gewinner={gewinnerName}
            unentschieden={unentschieden}
            onClose={() => {
                setShowUebermittelnArea(false);
                let tmpGame = {...game};
                tmpGame.players[0].gewonnen = false;
                tmpGame.players[1].gewonnen = false;
                tmpGame.abgeschlossen = false;
                setGame(tmpGame);
                if( lastChangePlayer === 1) {
                    setLegsPlayer1(legsPlayer1-1);
                    sendGameShortInfoToBackend(tmpGame, legsPlayer1 - 1, legsPlayer2);
                }
                if( lastChangePlayer === 2) {
                    setLegsPlayer2(legsPlayer2-1);
                    sendGameShortInfoToBackend(tmpGame, legsPlayer1, legsPlayer2 - 1);
                }
            }}
            onUebermitteln={() => uebertrageErgebnisAnsBackend()} />
    }

    return <div className="bg"
                style={{height: "100%", minHeight: "100vh", width: "100%"}}>
        <Snackbar open={message !== undefined} autoHideDuration={3000} onClose={() => setMessage(undefined)}
                  anchorOrigin={{ vertical:"top", horizontal:"center" }}>
            <Alert onClose={() => setMessage(undefined)} severity="error" sx={{ width: '100%', fontSize:30 }}>
                {message}
            </Alert>
        </Snackbar>
        <div style={{position: "fixed", left: 5, bottom: 5}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" startIcon={<ExitToAppOutlinedIcon style={{fontSize: 50}}/>}
                        onClick={() => changeToDashboard()}/>
            </ThemeProvider>
            {isDesktop() &&
                <ThemeProvider theme={CustomFontTheme}>
                    <Button variant="text"
                            onClick={() => changeToDashboard()}>Esc/*</Button>
                </ThemeProvider>
            }
        </div>
        <div style={{paddingBottom:10, color:"white"}}>
            <div className="grid" style={{padding:0, margin:0}}>
                <div className="col" style={{backgroundColor:"#ac1c27", textAlign: "center",fontSize: isSmartphone() ? "8.0vw" :  "6.0vw", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                    {getPlayer(game, 0).name}
                </div>
                <div className="col" style={{backgroundColor:"#ac1c27", textAlign: "center",fontSize: isSmartphone() ? "8.0vw" :  "6.0vw", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                    {getPlayer(game, 1).name}
                </div>
            </div>
        </div>
        <div style={{paddingBottom:10}}>
            <div className="grid" style={{padding:0, margin:0}}>
                <div className="col" style={{backgroundColor:"#064d6b", color: "white", textAlign: "center", fontSize: game.abgeschlossen ? "2.0vw" : isSmartphone() ? "6.0vw" :  "4.0vw"}}>
                    Legs: {legsPlayer1}
                </div>
                <div className="col" style={{backgroundColor:"#064d6b", color: "white",  textAlign: "center",fontSize: game.abgeschlossen ? "2.0vw" : isSmartphone() ? "6.0vw" :  "4.0vw"}}>
                    Legs: {legsPlayer2}
                </div>
            </div>
        </div>
        <div style={{paddingBottom:10}}>
            <div>
                <ModusArea sets={game.sets} firsttolegs={game.firsttolegs}
                           bestoflegs={game.bestoflegs} modusid={game.modusId}/>
            </div>
            {!game.abgeschlossen &&
                <div style={{
                    width: "100%", height: 50,
                    padding: 0,
                    margin: 0,
                    backgroundColor: "white",
                    textAlign: "center",
                    fontSize: 25}}>
                    Startpunkte: {game.startpunkte}
                </div>
            }
        </div>
        {!game.abgeschlossen &&
            <div style={{paddingBottom:10}}>
                <div className="grid" style={{padding:0, margin:0}}>
                    <div className="col" style={{backgroundColor:"#064d6b", color: "white", textAlign: "center", padding:0}}>
                        <div style={{padding:0}}>
                            <Button style={{color:"white", fontSize: isSmartphone() ? "5.0vw" : "4.0vw", margin:5}} color="warning" variant="contained"
                                    onClick={() => {
                                        changePlayer1(-1);
                                    }}
                                    disabled={legsPlayer1 === 0}>
                                Leg-
                            </Button>
                            <Button style={{color:"white", fontSize: isSmartphone() ? "5.0vw" : "4.0vw", margin:5}} color="warning" variant="contained"
                                    onClick={() => {
                                        setGameAbgeschlossen(legsPlayer1 +1, legsPlayer2);
                                        changePlayer1(1);
                                    }}>
                                Leg+
                            </Button>
                        </div>
                        {isDesktop() &&
                            <div>
                                <span style={{fontSize: "2.0vw"}}>Leg- = F1/1 *** Leg+ = F2/2</span>
                            </div>
                        }
                    </div>
                    <div className="col" style={{backgroundColor:"#064d6b", color: "white",  textAlign: "center", padding:0}}>
                        <div style={{padding:0}}>
                            <Button style={{color:"white", fontSize: isSmartphone() ? "5.0vw" : "4.0vw", margin:5}} color="warning" variant="contained"
                                    onClick={() => changePlayer2(-1)} disabled={legsPlayer2 === 0}>
                                Leg-
                            </Button>
                            <Button style={{color:"white", fontSize: isSmartphone() ? "5.0vw" : "4.0vw", margin:5}} color="warning" variant="contained"
                                    onClick={() => {
                                        setGameAbgeschlossen(legsPlayer1 , legsPlayer2 + 1);
                                        changePlayer2(1);
                                    }}>
                                Leg+
                            </Button>
                        </div>
                        {isDesktop() &&
                            <div>
                                <span style={{fontSize: "2.0vw"}}>Leg- = F3/3 *** Leg+ = F4/4</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
        {game.abgeschlossen !== undefined && game.abgeschlossen &&
            <div style={{paddingBottom:10, paddingTop:10, width:"100%", textAlign:"center"}}>
                <div>
                    {game.uebertragen !== undefined && game.uebertragen === "OK" && <div style={{color:"green", fontSize: isSmartphone() ? "4.0vw" : "2.0vw"}}>Das Spiel wurde an darthelfer.de übertragen.</div>}
                    {(game.uebertragen !== undefined && game.uebertragen === "FEHLER") && <div style={{backgroundColor:"red", color:"white", fontSize: isSmartphone() ? "4.0vw" : "2.0vw", cursor:"pointer"}}
                                                                                                                     onClick={() => uebertrageErgebnisAnsBackend()}>
                        <div>Fehler beim Übertragen an darthelfer.de</div>
                        <div>....Erneut übertragen...</div>
                        <div>Alternativ Ergebnis von Hand im Turnier/Liga eintragen.</div>
                    </div>}
                    {waitingSeconds !== undefined && <>
                        <div style={{color: "white", marginTop: 20, fontSize:20}}>Du wirst in {waitingSeconds} Sekunden auf das
                            Dashboard weitergeleitet.</div>
                        <div style={{color: "white", marginTop: 20, fontSize:20, cursor:"pointer"}} onClick={() => setWaitingSeconds(undefined)}>Klicke hier,<br/>damit wirst du nicht automatisch auf das Dashboard weitergeleitet.</div>
                    </>
                    }
                    <h1 style={{color: "white", marginTop:30}}>Spiel ist beendet</h1>
                    {game.unentschieden !== undefined &&
                        <h1 style={{color: "white", marginTop:20}}>Unentschieden</h1>
                    }

                    {game.unentschieden === undefined &&
                        <h1 style={{color: "white", marginTop:20}}>Gewinner<br/>{ermittleGewinner(game).name}</h1>
                    }
                </div>
            </div>
        }
    </div>
}
