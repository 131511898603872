import {Button, Dialog, DialogActions, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";
import React, {useEffect, useState} from "react";
import {NAVIGATION_ITEM} from "../constants/navigationItems";


export default function LiveViewControlDialog({onCancel}) {

    const [existiertZweiterMonitor, setExistiertZweiterMonitor] = useState(false);

    useEffect(() => {
        checkExistiertZweiterMonitor();
    }, []);

    const isChromeBrowser = () => {
        return window.chrome
    }

    const checkExistiertZweiterMonitor = async () => {
        const screenDetails = await window.getScreenDetails();
        setExistiertZweiterMonitor(screenDetails.screens.length === 2);
    }

    const openLiveViewLokal = (neuesFenster) => {
        if( neuesFenster) {
            window.open(NAVIGATION_ITEM.LIVEVIEW_LOKAL.route, "_blank", "popup");
        } else {
            window.open(NAVIGATION_ITEM.LIVEVIEW_LOKAL.route, "_self");
        }
    }

    const openLiveViewInternet = (neuesFenster) => {
        if( neuesFenster) {
            window.open(NAVIGATION_ITEM.LIVEVIEW_INTERNET.route, "_blank", "popup");
        } else {
            window.open(NAVIGATION_ITEM.LIVEVIEW_INTERNET.route, "_self");
        }
    }

    const openLiveViewOtherWindow = async (lokal) => {
        const screenDetails = await window.getScreenDetails();
        console.log("openLiveViewOtherWindow");
        if( screenDetails.screens.length === 2) {
            console.log(screenDetails);
            console.log(screenDetails.screens);
            let currentScreen = screenDetails.screens.filter(tmpScreen => tmpScreen.left === screenDetails.currentScreen.left)[0];
            console.log("current", currentScreen);
            let otherScreen = screenDetails.screens.filter(tmpScreen => tmpScreen.left !== currentScreen.left)[0];
            console.log("other", otherScreen);

            let currentScreenMitPrimary = screenDetails.screens.filter(tmpScreen => tmpScreen.isPrimary === screenDetails.currentScreen.isPrimary)[0];
            console.log("current 2", currentScreenMitPrimary);
            let otherScreenMitPrimary = screenDetails.screens.filter(tmpScreen => tmpScreen.isPrimary !== currentScreen.isPrimary)[0];
            console.log("other 2", otherScreenMitPrimary);

            let url;
            if( lokal ) {
                url = window.location.href + NAVIGATION_ITEM.LIVEVIEW_LOKAL.route.substring(1);
            } else {
                url = window.location.href + NAVIGATION_ITEM.LIVEVIEW_INTERNET.route.substring(1);
            }
            let features = "width=" + otherScreen.availWidth +",height=" + otherScreen.availHeight + ",left=" + otherScreen.left + ",top=0";
            console.log("features", features)
            window.open(
                url,
                '_blank',
                features
            )
        }
    }

    return <Dialog onClose={onCancel} open={true}>
        <div style={{padding:20}}>
            {isChromeBrowser() ?
                <div>
                    Du nutzt den Browser 'Chrome'.{existiertZweiterMonitor && " Zweiter Monitor wurde erkannt."}<br/>
                    Wir benutzen hier Features der aktuellsten Version von Chrome.<br/>
                    Sofern du Probleme beim Öffnen des Live-Views hast, stelle bitte sicher, dass du die aktuellste Version von Chrome benutzt.
                </div>
                :
                <div>Dir stehen hier nicht alle möglichen Aktionen zur Verfügung, da du nicht den Browser 'Chrome' benutzt.</div>
            }
            <div style={{marginTop:20, fontWeight:"bold"}}>
                Auswahlmöglichkeiten für Live-View bei dem das Scoring auf dem gleichem Gerät erfolgt wie der Live-View angezeigt wird.
            </div>
            <div style={{marginTop:10}}>
                {existiertZweiterMonitor &&
                    <div style={{marginBottom:5}}>
                        <Button style={{width:"100%"}} variant="contained" onClick={() => openLiveViewOtherWindow(true)}>Öffnen Live-View auf zweiten Monitor.</Button>
                    </div>
                }
                <div style={{marginBottom:5}}>
                    <Button style={{width:"100%"}} variant="contained" onClick={() => openLiveViewLokal(true)}>Öffnen Live-View auf aktuellem Monitor, zweites Fenster.</Button>
                </div>
                <div style={{marginBottom:5}}>
                    <Button style={{width:"100%"}} variant="contained" onClick={() => openLiveViewLokal(false)}>Öffnen Live-View im aktuellem Fenster.</Button>
                </div>
            </div>
            <div style={{marginTop:20, fontWeight:"bold"}}>
                Auswahlmöglichkeiten für Live-View bei dem das Scoring auf einem anderen Gerät (z.B. Tablet) erfolgt und du das Spiel auf diesem Gerät beobachten möchtest. Die Synchronistion der Daten läuft über das Internet und ist nur bei einer stabilen Internetverbindung empfohlen.
            </div>
            <div style={{marginTop:10}}>
                {existiertZweiterMonitor &&
                    <div style={{marginBottom:5}}>
                        <Button style={{width:"100%"}} variant="contained" onClick={() => openLiveViewOtherWindow(false)}>Öffnen Live-View auf zweiten Monitor.</Button>
                    </div>
                }
                <div style={{marginBottom:5}}>
                    <Button style={{width:"100%"}} variant="contained" onClick={() => openLiveViewInternet(true)}>Öffnen Live-View auf aktuellem Monitor, zweites Fenster.</Button>
                </div>
                <div style={{marginBottom:5}}>
                    <Button style={{width:"100%"}} variant="contained" onClick={() => openLiveViewInternet(false)}>Öffnen Live-View im aktuellem Fenster.</Button>
                </div>
            </div>
        </div>
        <DialogActions style={{padding:20}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" onClick={() => onCancel()}>Abbrechen</Button>
            </ThemeProvider>
        </DialogActions>
    </Dialog>
}
