class NavigationItem {
    constructor(text, route) {
        this.text = text;
        this.route = route;
    }
}

export const NAVIGATION_ITEM = {
    DASHBOARD: new NavigationItem("Dashboard", "/"),
    GAME: new NavigationItem("Dashboard", "/game"),
    EDARTGAME: new NavigationItem("Dashboard", "/edartgame"),
    GAMEDETAIL: new NavigationItem("Dashboard", "/gamedetail"),
    SETTINGS: new NavigationItem("Settings", "/settings"),
    GAMEOVERVIEW_OPEN: new NavigationItem("Gameoverview", "/gameoverviewopen"),
    GAMEOVERVIEW_CLOSED: new NavigationItem("Gameoverview", "/gameoverviewclosed"),
    TOURNAMENT: new NavigationItem("TournamentGameOverview", "/tournament"),
    LIVEVIEW_INTERNET: new NavigationItem("Live-View", "/liveviewinternet"),
    LIVEVIEW_LOKAL: new NavigationItem("Live-View", "/liveviewlokal"),
    BOARD_SERVER_CONNECT: new NavigationItem("Connect", "/connect"),
};
