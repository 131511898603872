import React from 'react';
import {
    ModusArea,
    ModusAreaSmartphone,
    PCSmallWindowPlayerArea,
    PlayerChangeArea,
    PlayerSingleArea,
    PlayerStatistikMultiplayerArea,
    PlayerTableArea,
    PlayerToGoMultiplayerArea,
    ScoreAreaDesktopTwoPlayersBig,
    ScoreAreaMulitplayerBig,
    ScoreAreaSmartphone,
    ScoreHeader,
    ScoreHeaderSmartphone,
    StatistikArea,
    StatistikAreaSmartphone
} from "./ScoreComponents";
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import NewGameArea from "./NewGameArea";
import PropTypes from "prop-types";
import {
    berchneToGoNeu,
    canCheck,
    ermittleGewinner,
    findScoreKoordinatenInGame,
    get100Plus,
    get140Plus,
    get171er,
    get180er,
    getAnzahleWuerfeImAktivenLeg,
    getAverage,
    getAverageFirst9,
    getAverageLeg,
    getBestLeg,
    getGewonneneLegs,
    getGewonneneSets,
    getHighOut,
    getHoechstenScoreCounter,
    getLastThrowByPlayer,
    getLeg,
    getLegByPlayer,
    getPlayer, getSelectedScoreIndex,
    getSetByPlayer,
    getSetStarter,
    getToGo,
    getToGoByPlayer, getToGoWithAutodarts,
    getWertImAktivenLeg,
    getWorstLeg,
    isLegBeginner,
    isPlayerActive,
    isPlayerActiveByPlayer,
    isSetBeendet,
    isSetModus,
    istGameBeendet,
    isUnentschieden
} from "./ScoreHelper";
import {
    CHECKOUT_VALUES_DOUBLE_OUT_1_DART,
    CHECKOUT_VALUES_DOUBLE_OUT_2_DARTS,
    CHECKOUT_VALUES_DOUBLE_OUT_3_DARTS,
    getCheckout
} from "../constants/checkouts";
import {newLeg, newScore, newSet, newSetWithoutScore} from "./GameHelper";
import {Alert, Backdrop, Button, Slider, Snackbar, Stack, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "./CustomFontTheme";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import UndoOutlined from "@mui/icons-material/UndoOutlined";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import './scoreX01View.css';
import {FinishDartsDialog} from "./FinishDartsDialog";
import {
    getFromLocalStorage,
    getFromLocalStorageWithDefault,
    removeFromLocalStorage,
    saveToLocalStorage
} from "../localStorageZugriff";
import FetchUtil from "../util/FetchUtil";
import TabletTastaturQuerformat from "./TabletTastaturQuerformat";
import {ScoreAreaTablet} from "./ScoreAreaTablet";
import {isSmartphone, isTablet, isTabletHochformat, isTabletQuerformat} from "../util/GeraeteArtUtil";
import TabletTastaturHochformat from "./TabletTastaturHochformat";
import {X01EinstellungenDialog} from "./X01EinstellungenDialog";
import TabletTastaturQuerformatFastScoreArea from "./TabletTastaturQuerformatFastScoreArea";
import {GameUebermittelnArea} from "./GameUebermittelnArea";
import SmartphoneTastatur from "./SmartphoneTastatur";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import {StatisticOrientationComponent} from "../settings/StatisticOrientationComponent";
import AutoScoreComponent from "./AutoScoreComponent";
import AutodartsConnection from "./autodarts/AutodartsConnection";

class ScoringX01View extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            game: undefined,
            autodartsValue: 0,
            ansicht: 1,
            forbittenCounter: 0,
            savingGameOnServer: false,
            retrySeconds: 3,
            retries: 0,
            waitingSeconds: undefined,
            waiting: false,
            selectedValue: "",
            selectedValueIsNew: true,
            showFinishQuestion: false,
            showEinstellungenDialog: false,
            showStatistic: false,
            showFormatDialog: false,
            boardName: getFromLocalStorage("dh_board_bezeichnung"),
            url: getFromLocalStorage("dh_url"),
            token: getFromLocalStorage("dh_token"),
            boardToken: getFromLocalStorage("dh_board_token"),
            autodartsBoardId: getFromLocalStorage("dh_autodarts_boardid"),
            showKeyBoard: getFromLocalStorage("dh_autodarts_boardid") === undefined || getFromLocalStorage("dh_autodarts_boardid") === "",
            showSettings: {
                secondsToDashboard: getFromLocalStorage("dh_default_seconds_to_dashboard"),
                showFinishQuestion: getFromLocalStorage("dh_default_x01_show_finishquestion"),
                showCheckout: getFromLocalStorage("dh_default_x01_show_checkout"),
                showAverage: getFromLocalStorage("dh_default_x01_show_average"),
                showAverageFirst9: getFromLocalStorage("dh_default_x01_show_average_first_9"),
                showAverageLeg: getFromLocalStorage("dh_default_x01_show_average_leg"),
                show100Plus: getFromLocalStorage("dh_default_x01_show_100plus"),
                show140Plus: getFromLocalStorage("dh_default_x01_show_140plus"),
                show171: getFromLocalStorage("dh_default_x01_show_171"),
                show180: getFromLocalStorage("dh_default_x01_show_180"),
                showBestLeg: getFromLocalStorage("dh_default_x01_show_bestleg"),
                showWorstLeg: getFromLocalStorage("dh_default_x01_show_worstleg"),
                showHighOut: getFromLocalStorage("dh_default_x01_show_highout"),
                showFinishColors: getFromLocalStorage("dh_default_x01_show_finish_colors"),
                showTableScoreValue: getFromLocalStorage("dh_default_show_table_score_value"),
                alleSpieleImLiveView: getFromLocalStorage("dh_alle_spiele_im_live_view"),
                taste1: getFromLocalStorage("dh_default_x01_taste1"),
                taste2: getFromLocalStorage("dh_default_x01_taste2"),
                taste3: getFromLocalStorage("dh_default_x01_taste3"),
                taste4: getFromLocalStorage("dh_default_x01_taste4"),
                taste5: getFromLocalStorage("dh_default_x01_taste5"),
                taste6: getFromLocalStorage("dh_default_x01_taste6"),
                taste7: getFromLocalStorage("dh_default_x01_taste7"),
                taste8: getFromLocalStorage("dh_default_x01_taste8"),
            },
            pcSettings: {
                toGoFontSize: Number(getFromLocalStorageWithDefault("dh_default_x01_pc_togo_fontsize", 100)),
                playerFontSize: Number(getFromLocalStorageWithDefault("dh_default_x01_pc_player_fontsize", 25)),
                statistikFontSize: Number(getFromLocalStorageWithDefault("dh_default_x01_pc_statisik_fontsize", 40)),
                modusFontSize: Number(getFromLocalStorageWithDefault("dh_default_x01_pc_modus_fontsize", 25)),
                scoreAreaHeaderFontSize: Number(getFromLocalStorageWithDefault("dh_default_x01_pc_scorearea_header_fontsize", 20)),
                scoreAreaFontSize: Number(getFromLocalStorageWithDefault("dh_default_x01_pc_scorearea_fontsize", 25)),
                statisticOrientation: getFromLocalStorageWithDefault("dh_default_x01_statistic_orientation", "liste"),
            },
            tabletSettings: {
                toGoFontSize: Number(getFromLocalStorageWithDefault("dh_default_x01_tablet_togo_fontsize", 100)),
                playerFontSize: Number(getFromLocalStorageWithDefault("dh_default_x01_tablet_player_fontsize", 25)),
                modusFontSize: Number(getFromLocalStorageWithDefault("dh_default_x01_tablet_modus_fontsize", 25)),
                scoreAreaHeaderFontSize: Number(getFromLocalStorageWithDefault("dh_default_x01_tablet_scorearea_header_fontsize", 20)),
                scoreAreaFontSize: Number(getFromLocalStorageWithDefault("dh_default_x01_tablet_scorearea_fontsize", 25)),
                shortKeyBoardFontSize: Number(getFromLocalStorageWithDefault("dh_default_x01_tablet_short_keyboard_fontsize", 30)),
                keyBoardFontSize: Number(getFromLocalStorageWithDefault("dh_default_x01_tablet_keyboard_fontsize", 30)),
                keyBoardRowHeight: Number(getFromLocalStorageWithDefault("dh_default_x01_tablet_keyboard_row_height", 80)),
            },
            windowdimensions : {
                height: window.innerHeight,
                width: window.innerWidth
            },
        };

        this.onResize = this.onResize.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.changeSelectedScore = this.changeSelectedScore.bind(this);
        this.addValueToSelectedValue = this.addValueToSelectedValue.bind(this);
        this.saveToLocalStorage = this.saveToLocalStorage.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
        window.addEventListener("keydown", this.onKeyDown);
        if (this.props.id !== null && this.props.id !== undefined) {
            this.setState({});
            setTimeout(() => {
                this.setState({waiting: false});
            }, 3);
            let game = getFromLocalStorage(this.props.id);
            let naechsterScoreCounter = undefined;
            if (game.abgeschlossen === undefined || !game.abgeschlossen) {
                naechsterScoreCounter = getHoechstenScoreCounter(game);
            }
            game.selectedScoreIndex = naechsterScoreCounter;
            saveToLocalStorage("dh_active_gameid", this.props.id);
            this.saveToLocalStorage(game);
            this.setState({waiting: true, game: game});

        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);
        window.removeEventListener("resize", this.onResize);

        removeFromLocalStorage("dh_active_gameid");
    }

    saveSettings() {
        saveToLocalStorage("dh_default_x01_tablet_togo_fontsize", this.state.tabletSettings.toGoFontSize);
        saveToLocalStorage("dh_default_x01_tablet_player_fontsize", this.state.tabletSettings.playerFontSize);
        saveToLocalStorage("dh_default_x01_tablet_modus_fontsize", this.state.tabletSettings.modusFontSize);
        saveToLocalStorage("dh_default_x01_tablet_scorearea_header_fontsize", this.state.tabletSettings.scoreAreaHeaderFontSize);
        saveToLocalStorage("dh_default_x01_tablet_scorearea_fontsize", this.state.tabletSettings.scoreAreaFontSize);
        saveToLocalStorage("dh_default_x01_tablet_short_keyboard_fontsize", this.state.tabletSettings.shortKeyBoardFontSize);
        saveToLocalStorage("dh_default_x01_tablet_keyboard_fontsize", this.state.tabletSettings.keyBoardFontSize);
        saveToLocalStorage("dh_default_x01_tablet_keyboard_row_height", this.state.tabletSettings.keyBoardRowHeight);

        saveToLocalStorage("dh_default_x01_pc_togo_fontsize", this.state.pcSettings.toGoFontSize);
        saveToLocalStorage("dh_default_x01_pc_player_fontsize", this.state.pcSettings.playerFontSize);
        saveToLocalStorage("dh_default_x01_pc_statisik_fontsize", this.state.pcSettings.statistikFontSize);
        saveToLocalStorage("dh_default_x01_pc_modus_fontsize", this.state.pcSettings.modusFontSize);
        saveToLocalStorage("dh_default_x01_pc_scorearea_header_fontsize", this.state.pcSettings.scoreAreaHeaderFontSize);
        saveToLocalStorage("dh_default_x01_pc_scorearea_fontsize", this.state.pcSettings.scoreAreaFontSize);
    }

    changeToDashboard() {
        this.componentWillUnmount();
        if( this.state.game === null || this.state.game === undefined) {
            window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self");
        } else if( this.isTurnierGame()) {
            window.open(NAVIGATION_ITEM.TOURNAMENT.route, "_self");
        } else {
            window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self");
        }
    }

    isTurnierGame() {
        return this.state.game.turnierId !== null && this.state.game.turnierId !== undefined;
    }

    onResize(event) {
        this.setState({windowdimensions: {
                height: window.innerHeight,
                width: window.innerWidth
            }});
    }

    onKeyDown(event) {
        if (this.state.game === null || this.state.game === undefined) {
            // solange kein Game da ist, wird hier nichts behandelt. Da evtl. der NeuDialog auch was eingeben will.
            return;
        }
        if( this.showUebertragenDialog()) {
            return;
        }
        if( this.state.showFinishQuestion || this.state.showEinstellungenDialog) {
            return;
        }
        if( this.state.game.abgeschlossen !== null && this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen) {
            switch (event.key) {
                case "Escape":
                case "*":
                    if( this.state.showFinishQuestion ) {
                        this.setState({showFinishQuestion: false});
                    } else {
                        this.changeToDashboard();
                    }
                    event.preventDefault();
                    break;
                case "1":
                case "d":
                case "D":
                    window.open(NAVIGATION_ITEM.GAMEDETAIL.route + "?id=dh_game_" + this.state.game.id + "&type=game", "_self");
                    event.preventDefault();
                    break;
                case "2":
                case "s":
                case "S":
                    this.setState({waitingSeconds: undefined});
                    event.preventDefault();
                    break;
                default:
                    break;
            }
            event.stopPropagation();
        }

        switch (event.key) {
            case "1":
            case "2":
            case "3":
            case "4":
            case "5":
            case "6":
            case "7":
            case "8":
            case "9":
            case "0":
                this.addValueToSelectedValue(event.key);
                event.preventDefault();
                break;
            case "Escape":
            case "*":
                if( this.state.showFinishQuestion ) {
                    this.setState({showFinishQuestion: false});
                } else {
                    this.changeToDashboard();
                }
                event.preventDefault();
                break;
            case "Enter":
                this.saveValueInSelectedScore();
                event.preventDefault();
                break;
            case "Backspace":
                this.removeFromSelectedValue();
                event.preventDefault();
                break;
            case "ArrowLeft":
                this.changeSelectedScore(-1);
                event.preventDefault();
                break;
            case "d":
            case "D":
                window.open(NAVIGATION_ITEM.GAMEDETAIL.route + "?id=dh_game_" + this.state.game.id + "&type=game", "_self");
                event.preventDefault();
                break;
            case "e":
            case "E":
                this.setState({showEinstellungenDialog:true});
                event.preventDefault();
                break;
            case "s":
            case "S":
                this.setState({waitingSeconds: undefined});
                event.preventDefault();
                break;
            case "z":
            case "Z":
                this.zurueckZumLetztenLeg();
                event.preventDefault();
                break;
            case "a":
            case "A":
                this.changeAnsicht();
                event.preventDefault();
                break;
            case "Tab":
            case "ArrowRight":
                this.changeSelectedScore(+1);
                event.preventDefault();
                break;
            case "F1":
                this.saveValueSelectedScore(this.state.showSettings.taste1);
                event.preventDefault();
                break;
            case "F2":
                this.saveValueSelectedScore(this.state.showSettings.taste2);
                event.preventDefault();
                break;
            case "F3":
                this.saveValueSelectedScore(this.state.showSettings.taste3);
                event.preventDefault();
                break;
            case "F4":
                this.saveValueSelectedScore(this.state.showSettings.taste4);
                event.preventDefault();
                break;
            case "F5":
                this.saveValueSelectedScore(this.state.showSettings.taste5);
                event.preventDefault();
                break;
            case "F6":
                this.saveValueSelectedScore(this.state.showSettings.taste6);
                event.preventDefault();
                break;
            case "F7":
                this.saveValueSelectedScore(this.state.showSettings.taste7);
                event.preventDefault();
                break;
            case "F8":
                this.saveValueSelectedScore(this.state.showSettings.taste8);
                event.preventDefault();
                break;
            case "F9":
            case "/":
                this.saveLeftScore();
                event.preventDefault();
                break;
            case "F10":
                this.finishLeg(1);
                event.preventDefault();
                break;
            case "F11":
                this.finishLeg(2);
                event.preventDefault();
                break;
            case "F12":
                this.finishLeg(3);
                event.preventDefault();
                break;
            case "-":
            case "x":
            case "X":
                this.undoLastScore();
                event.preventDefault();
                break;
            default:
                break;
        }
        event.stopPropagation();
    }

    changeScoreByPlayers() {
        let game = this.state.game;
        if( game.players.length !== 2) {
            this.setState({message:"Funktion nur bei Spieler mit 2 Playern möglich."});
            return;
        }
        let sets1 = game.players[0].sets;
        let sets2 = game.players[1].sets;
        game.players[0].sets = sets2;
        game.players[1].sets = sets1;
        this.setState({game:game});
    }

    saveToLocalStorage(game) {
        if (game === undefined) {
            return;
        }
        saveToLocalStorage("dh_game_" + game.id, game);
    }

    saveValueInSelectedScore() {
        if( this.state.game.abgeschlossen !== null && this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen) {
            return;
        }
        this.saveValueSelectedScore(this.state.selectedValue);
    }

    isSaveLeftScoreAktiv() {
        if( this.state.game.abgeschlossen) {
            return false;
        }
        let koordinaten = findScoreKoordinatenInGame(this.state.game, getSelectedScoreIndex(this.state.game));
        let toGo = getToGo(this.state.game, koordinaten[0]);
        let selectedValue = this.state.selectedValue;
        if( selectedValue === "" ) {
            return false;
        }
        let wertDerGescoredWerdenMuesste = Number(toGo) - Number(selectedValue);
        if( wertDerGescoredWerdenMuesste > 180) {
            return false;
        }
        let maximalerScoreWert = Number(toGo) - 2;
        let aktuellerScoreWert = Number(toGo) - Number(selectedValue);
        if( aktuellerScoreWert > maximalerScoreWert) {
            return false;
        }
        if( aktuellerScoreWert < 2 ) {
            return false;
        }
        if( aktuellerScoreWert <= 0) {
            return false;
        }
        return true;
    }

    saveLeftScore() {
        if( this.state.game.abgeschlossen !== null && this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen) {
            return;
        }

        if (this.state.selectedValue === "") {
            return;
        }
        if( Number(this.state.selectedValue) === 0 ) {
            this.setState({message: "left score mit 0 nicht erlaubt."});
            return;
        }
        let koordinaten = findScoreKoordinatenInGame(this.state.game, getSelectedScoreIndex(this.state.game));
        let toGo = getToGo(this.state.game, koordinaten[0]);
        let selectedValue = this.state.selectedValue

        let value = toGo - selectedValue;
        this.saveValueSelectedScore(value);
    }

    isDoubleOutModus() {
        return this.state.game.modusId === "double_out" || this.state.game.modusId === "double_in_double_out";
    }

    isCheckMitAnzahlWuerfenMoeglich(wuerfe) {
        if( this.state.game.abgeschlossen) {
            return false;
        }
        if (this.state.game.modusId === "double_out" || this.state.game.modusId === "double_in_double_out") {
            let koordinaten = findScoreKoordinatenInGame(this.state.game, getSelectedScoreIndex(this.state.game));
            let toGo = getToGo(this.state.game, koordinaten[0]);
            let checkArray;
            if( wuerfe === 1) {
                checkArray = CHECKOUT_VALUES_DOUBLE_OUT_1_DART;
            } else if( wuerfe === 2) {
                checkArray = CHECKOUT_VALUES_DOUBLE_OUT_2_DARTS;
            } else if( wuerfe === 3) {
                checkArray = CHECKOUT_VALUES_DOUBLE_OUT_3_DARTS;
            }
            return checkArray.includes(Number(toGo));
        }
        return false;
    }

    isUndoAllowed() {
        return true;
    }

    undoLastScore() {
        if( !this.isUndoAllowed() ) {
            console.log("undo not allowed");
            return;
        }
        if (this.state.game.abgeschlossen !== null && this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen) {
            return;
        }
        let game = this.state.game;
        let koordinaten = findScoreKoordinatenInGame(this.state.game, getSelectedScoreIndex(this.state.game));

        let player1Scores = game.players[0].sets[koordinaten[1]].legs[koordinaten[2]].scores;
        let player2Scores = game.players[1].sets[koordinaten[1]].legs[koordinaten[2]].scores;

        let newPlayer1Scores = [];
        let newPlayer2Scores = [];

        let lastScore;
        if( getSelectedScoreIndex(this.state.game) <= 2 ) {
            lastScore = 2; // wird unten noch einer abgezogen und auf 1 gestellt.
            // es sind nur noch 2 scores vorhanden, diese werden einfach geleert
            let player1Score = player1Scores[0];
            let player2Score = player2Scores[0];
            player1Score = {...player1Score, value: "", togo: "", invalid: false};
            player2Score = {...player2Score, value: "", togo: "", invalid: false};
            newPlayer1Scores = [player1Score];
            newPlayer2Scores = [player2Score];
        } else {
            lastScore = 1;
            for (const score of player1Scores) {
                if( score.counter > lastScore) {
                    lastScore = score.counter;
                }
            }
            for (const score of player2Scores) {
                if( score.counter > lastScore) {
                    lastScore = score.counter;
                }
            }

            player1Scores = player1Scores.filter(score => score.counter !== lastScore);
            player2Scores = player2Scores.filter(score => score.counter !== lastScore);

            for (const score of player1Scores) {
                if( score.counter === lastScore - 1) {
                    newPlayer1Scores.push({...score, value: "", togo: "", invalid: false});
                } else {
                    newPlayer1Scores.push(score);
                }
            }

            for (const score of player2Scores) {
                if( score.counter === lastScore - 1) {
                    newPlayer2Scores.push({...score, value: "", togo: "", invalid: false});
                } else {
                    newPlayer2Scores.push(score);
                }
            }
        }

        game.players[0].sets[koordinaten[1]].legs[koordinaten[2]].scores = newPlayer1Scores;
        game.players[1].sets[koordinaten[1]].legs[koordinaten[2]].scores = newPlayer2Scores;

        game.selectedScoreIndex = lastScore - 1;

        this.setState({
            game: game,
            selectedValue: "",
            selectedValueIsNew: true
        });
        this.saveToLocalStorage(game);
        this.sendGameShortInfoToBackend(game, lastScore - 1);
    }

    finishLeg(wuerfe) {
        if( this.state.game.abgeschlossen !== null && this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen) {
            return;
        }

        let koordinaten = findScoreKoordinatenInGame(this.state.game, getSelectedScoreIndex(this.state.game));
        let toGo = getToGo(this.state.game, koordinaten[0]);
        let checkArray;
        if( this.state.game.modusId === "double_out" || this.state.game.modusId === "double_in_double_out") {
            if( wuerfe === 1) {
                checkArray = CHECKOUT_VALUES_DOUBLE_OUT_1_DART;
            } else if( wuerfe === 2) {
                checkArray = CHECKOUT_VALUES_DOUBLE_OUT_2_DARTS;
            } else if( wuerfe === 3) {
                checkArray = CHECKOUT_VALUES_DOUBLE_OUT_3_DARTS;
            }
            let validToGo = checkArray.includes(Number(toGo));
            if( !validToGo ) {
                this.setState({message: "Finish " + toGo + " mit deiner Wurfanzahl " + wuerfe + " ist nicht möglich."});
                return;
            }
        }
        this.saveValueSelectedScore(toGo, wuerfe);
    }

    erzeugeNeuesLegSofernLegBeendetWurde(aktuellerPlayer, aktuellesSet, aktuellesLeg) {
        let returnValue = false;
        let game = this.state.game;
        let abgeschlossen = aktuellesLeg.abgeschlossen;
        if (abgeschlossen !== undefined && abgeschlossen) {
            // Leg ist abgeschlossen, neues Leg muss angelegt werden.
            returnValue = true;
            let setCounterImArray = aktuellesSet.setnummer - 1;
            let legnummer = aktuellesLeg.legnummer;

            // ermittle den aktuellen Leg Starter
            let naechstePlayerNummer = this.ermittleAktuellenPlayerUndGebeNaechstePlayernummerZurueck(aktuellesSet, aktuellesLeg);

            let neuePlayersInNaechsterReihenfolge = [];
            while(neuePlayersInNaechsterReihenfolge.length !== game.players.length) {
                neuePlayersInNaechsterReihenfolge.push(game.players[naechstePlayerNummer - 1]);
                if(naechstePlayerNummer === game.players.length) {
                    naechstePlayerNummer = 1;
                } else {
                    naechstePlayerNummer++;
                }
            }
            let naechsteLegnummer = ++legnummer;
            for(let tmpScoreCounter = 1 ; tmpScoreCounter < neuePlayersInNaechsterReihenfolge.length +1 ; tmpScoreCounter++) {
                let player = neuePlayersInNaechsterReihenfolge[tmpScoreCounter - 1];
                game.players[player.playernummer - 1].sets[setCounterImArray].legs.push(newLeg(naechsteLegnummer, tmpScoreCounter));
            }
        }
        return returnValue;
    }

    aktuellesSetAbschliessen(game, aktuellerPlayer, aktuellesSet) {
        for (let counterPlayer = 0; counterPlayer < game.players.length; counterPlayer++) {
            // alle Sets auf abgeschlossen setzen
            for (let counterSets = 0; counterSets < game.players[counterPlayer].sets.length; counterSets++) {
                game.players[counterPlayer].sets[counterSets].abgeschlossen = true;
            }
        }

        return game;
    }

    setzeSetGewinner(game, aktuellerPlayer, aktuellesSet) {
        // aktuelles set beim aktuellen player auf gewonnen setzen.
        game.players[aktuellerPlayer.playernummer - 1].sets[aktuellesSet.setnummer - 1].gewonnen = true;
        return game;
    }

    erzeugeNeuesSet(game, aktuellesSet) {
        let naechsteSetnummer = aktuellesSet.setnummer + 1;

        let playerCounterSetStarter = getSetStarter(game, aktuellesSet.setnummer);
        let naechsterSetStarter;
        if( playerCounterSetStarter + 1 < game.players.length) {
            naechsterSetStarter = playerCounterSetStarter + 1;
        } else {
            naechsterSetStarter = 0;
        }
        for (let counter = 0; counter < game.players.length; counter++) {
            let neuesSet;
            if( counter === naechsterSetStarter) {
                neuesSet = newSet(naechsteSetnummer, 1, 1);
            } else {
                neuesSet = newSetWithoutScore(naechsteSetnummer, 1);
            }
            game.players[counter].sets.push(neuesSet);
        }
        return game;
    }

    saveValueSelectedScore(value, wuerfe) {
        if( this.state.game.abgeschlossen !== null && this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen) {
            return;
        }

        if (value === "") {
            return;
        }
        if (value > 180) {
            return;
        }
        let game = this.state.game;
        let koordinaten = findScoreKoordinatenInGame(this.state.game, getSelectedScoreIndex(this.state.game));
        let toGo = getToGo(this.state.game, koordinaten[0]);
        if( Number(toGo) === Number(value) && this.state.showSettings.showFinishQuestion && wuerfe === undefined) {
            this.setState({showFinishQuestion: true});
            return;
        }
        game.players[koordinaten[0]].sets[koordinaten[1]].legs[koordinaten[2]].scores[koordinaten[3]].value = value;
        if( wuerfe !== undefined) {
            game.players[koordinaten[0]].sets[koordinaten[1]].legs[koordinaten[2]].scores[koordinaten[3]].wuerfe = Number(wuerfe);
        }
        let newSelectedScore = getSelectedScoreIndex(this.state.game) + 1;
        let wurdeNeuerScoreAngelegt = this.addNewScoreToNextPlayer(koordinaten, newSelectedScore);
        let neuerSelectedValue;
        if (wurdeNeuerScoreAngelegt) {
            neuerSelectedValue = "";
        } else {
            let koordinatenGesucht = findScoreKoordinatenInGame(this.state.game, newSelectedScore);
            if( koordinatenGesucht !== undefined) {
                neuerSelectedValue = game.players[koordinatenGesucht[0]].sets[koordinaten[1]].legs[koordinatenGesucht[2]].scores[koordinatenGesucht[3]].value;
            } else {
                neuerSelectedValue = "";
            }
        }
        game = berchneToGoNeu(game, koordinaten);
        let setBeendet = isSetBeendet(this.state.game);
        if (Number(game.sets) === 1 && setBeendet) {
            game = this.aktuellesSetAbschliessen(game, game.players[koordinaten[0]], game.players[koordinaten[0]].sets[koordinaten[1]]);
            let unentschieden = isUnentschieden(game);
            if( unentschieden ) {
                game.unentschieden = true;
            } else {
                this.setzeSetGewinner(game, game.players[koordinaten[0]], game.players[koordinaten[0]].sets[koordinaten[1]]);
                game.players[koordinaten[0]].gewonnen = true;
            }
            game.abgeschlossen = true;
            if( !this.showUebertragenDialog() ) {
                this.startWaitingTimer();
            }
            newSelectedScore = undefined;
            neuerSelectedValue = "";
        } else {
            if (setBeendet) {
                game = this.aktuellesSetAbschliessen(game, game.players[koordinaten[0]], game.players[koordinaten[0]].sets[koordinaten[1]]);
                this.setzeSetGewinner(game, game.players[koordinaten[0]], game.players[koordinaten[0]].sets[koordinaten[1]]);
                let gameBeendet = istGameBeendet(game);
                if (gameBeendet) {
                    game.abgeschlossen = true;
                    game.players[koordinaten[0]].gewonnen = true;
                    if( !this.showUebertragenDialog() ) {
                        this.startWaitingTimer();
                    }
                    newSelectedScore = undefined;
                    neuerSelectedValue = "";
                } else {
                    game = this.erzeugeNeuesSet(game, game.players[koordinaten[0]].sets[koordinaten[1]]);
                    newSelectedScore = 1;
                    neuerSelectedValue = "";
                }
            } else {
                let wurdeNeuesLegAngelegt = this.erzeugeNeuesLegSofernLegBeendetWurde(game.players[koordinaten[0]], game.players[koordinaten[0]].sets[koordinaten[1]], game.players[koordinaten[0]].sets[koordinaten[1]].legs[koordinaten[2]]);
                if (wurdeNeuesLegAngelegt) {
                    newSelectedScore = 1;
                    neuerSelectedValue = "";
                }
            }
        }
        game.selectedScoreIndex = newSelectedScore;
        this.setState({
            game: game,
            selectedValue: neuerSelectedValue,
            selectedValueIsNew: true
        });
        this.saveToLocalStorage(game);
        this.sendGameShortInfoToBackend(game, newSelectedScore);
    }

    addNewScoreToNextPlayer(koordinaten, newSelectedScore) {
        let koordinatenGesucht = findScoreKoordinatenInGame(this.state.game, newSelectedScore);
        if (koordinatenGesucht !== undefined) {
            // den Score mit dem Counter gibt es bereits, aus diesem Grund nichts hinzufügen.
            return false;
        }
        let game = this.state.game;
        let playerKoordinate = koordinaten[0];
        let newPlayerKoordinate = playerKoordinate + 1;
        if (game.players[newPlayerKoordinate] === undefined) {
            newPlayerKoordinate = 0;
        }
        game.players[newPlayerKoordinate].sets[koordinaten[1]].legs[koordinaten[2]].scores.push(newScore(newSelectedScore));
        this.setState({game: game});
        return true;
    }

    isPlayerChangeAllowed() {
        return getSelectedScoreIndex(this.state.game) === 1 && this.state.selectedValue === "";
    }

    changeSelectedScore(wert) {
        if( this.state.game.abgeschlossen !== null && this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen) {
            return;
        }

        if (getSelectedScoreIndex(this.state.game) === 1 && this.state.selectedValue === "") {
            let game = this.state.game;
            let koordinatenGesucht = findScoreKoordinatenInGame(game, 1);
            let aktuellerPlayer = game.players[koordinatenGesucht[0]];
            let aktuellesSet = getSetByPlayer(aktuellerPlayer);
            let aktuellesLeg = getLegByPlayer(aktuellerPlayer);
            let naechstePlayerNummer = this.ermittleAktuellenPlayerUndGebeNaechstePlayernummerZurueck(aktuellesSet, aktuellesLeg);
            let neuePlayersInNaechsterReihenfolge = [];
            while(neuePlayersInNaechsterReihenfolge.length !== game.players.length) {
                neuePlayersInNaechsterReihenfolge.push(game.players[naechstePlayerNummer - 1]);
                if(naechstePlayerNummer === game.players.length) {
                    naechstePlayerNummer = 1;
                } else {
                    naechstePlayerNummer++;
                }
            }
            let counter = 1;
            for (const player of neuePlayersInNaechsterReihenfolge) {
                game.players[player.playernummer - 1].sets[koordinatenGesucht[1]].legs[koordinatenGesucht[2]].scores[0].counter = counter;
                counter++;
            }
            this.setState({game: game});
            this.sendGameShortInfoToBackend(game, 1);
            this.saveToLocalStorage(game);
            return;
        }

        let game = this.state.game;
        let hoechstenScoreCounter = getHoechstenScoreCounter(game);
        let index = getSelectedScoreIndex(this.state.game);
        let newIndex = index + wert;
        if (newIndex < 1) {
            newIndex = 1;
        }
        if (newIndex > hoechstenScoreCounter) {
            newIndex = hoechstenScoreCounter
        }
        let value = getWertImAktivenLeg(game, newIndex);
        game.selectedScoreIndex = newIndex
        this.setState({game: game, selectedValue: value});
        this.sendGameShortInfoToBackend(game, newIndex);
        this.saveToLocalStorage(game);
    }

    isZuruecksetzenMoeglich() {
        // es darf noch keine Eingabe im aktuellen Leg vorhanden sein.
        let game = this.state.game;
        if( game.uebertragen !== undefined) {
            return false;
        }
        if( game.abgeschlossen) {
            return true;
        }
        for (const player of game.players) {
            let legByPlayer = getLegByPlayer(player);
            let setByPlayer = getSetByPlayer(player);
            if( legByPlayer.legnummer === 1 && setByPlayer.setnummer === 1) {
                // Leg 1 im Set 1 kann nicht zurück gesetzt werden.
                return false;
            }
            for (const score of legByPlayer.scores) {
                if( score.value !== "") {
                    return false;
                }
            }
        }
        return true;
    }

    retryUebertragen() {
        setTimeout(() => {
            if( this.state.retries >= 3 ) {
                return;
            }
            this.setState({retries: this.state.retries + 1, retrySeconds: this.state.retrySeconds * 2});
            this.uebertrageErgebnisAnsBackend(this.state.game);
        }, this.state.retrySeconds * 1000);
    }

    uebertrageErgebnisAnsBackend(game) {
        if( this.state.game.turnierId === undefined || this.state.game.turnierId === null ) {
            return;
        }
        if( game.uebertragen === "OK") {
            console.log("saving - uebertragen");
            return;
        }
        if( this.state.savingGameOnServer ) {
            console.log("save runs");
            return;
        }
        this.setState({savingGameOnServer: true});
        FetchUtil.fetchPost(this.state.url + "/saveGame", this.state.token, this.state.boardToken,
            game,
            () => {
                let game = this.state.game;
                game.uebertragen = "OK";
                this.startWaitingTimer();
                this.setState({game: game, savingGameOnServer: false});
                this.saveToLocalStorage(game);
            },
            (responseNotOk) => {
                // nix tun, es gibt Probleme aber an der Stelle unwichtig
                console.log("Fehler: " + responseNotOk.message);
                let game = this.state.game;
                game.uebertragen = "FEHLER";
                this.setState({game: game, waitingSeconds: undefined, savingGameOnServer: false});
                this.saveToLocalStorage(game);
                this.retryUebertragen();
            },
            (error) => {
                // nix tun, es gibt Probleme aber an der Stelle unwichtig
                console.log("Fehler: " + error.message);
                let game = this.state.game;
                game.uebertragen = "FEHLER";
                this.setState({game: game, waitingSeconds: undefined, savingGameOnServer: false});
                this.saveToLocalStorage(game);
                this.retryUebertragen();
        });
    }

    sendGameShortInfoToBackend(game, selectedScore) {
        let isTurnier = game.turnierId !== null && game.turnierId !== undefined;
        if( isTurnier && !game.publishTournament ) {
            // Turnier, soll nur übertragen werden wenn publishTournament gesetzt ist.
            return;
        }
        let uebertragenWegenSchalterWert = this.state.showSettings.alleSpieleImLiveView;
        if( !isTurnier && !uebertragenWegenSchalterWert ) {
            // normales Spiel nur übertragen wenn Schalter gesetzt
            return;
        }
        let lastThrowByPlayer0 = getLastThrowByPlayer(game, 0);
        if( lastThrowByPlayer0 === "-") {
            lastThrowByPlayer0 = undefined;
        }
        let lastThrowByPlayer1 = getLastThrowByPlayer(game, 1);
        if( lastThrowByPlayer1 === "-") {
            lastThrowByPlayer1 = undefined;
        }
        let players = [];
        players.push({playerId: game.players[0].id, name: game.players[0].name, legAnwurf: isLegBeginner(game, 0 ),
            active: isPlayerActiveByPlayer(game, game.players[0], selectedScore), lastThrow: lastThrowByPlayer0,
            toGo: getToGo(game, 0),
            leg: getLeg(game, 0),
            canCheck: canCheck(game, getToGo(game, 0), true),
            gewonneneSets: getGewonneneSets(game, 0),
            gewonneneLegs: getGewonneneLegs(game, 0),
            average: getAverage(game, 0),
            averageLeg: getAverageLeg(game, 0),
            averageFirst9: getAverageFirst9(game, 0),
            count100Plus: get100Plus(game, 0),
            count140Plus: get140Plus(game, 0),
            count180er: get180er(game, 0),
            count171er: get171er(game, 0),
            highOut: getHighOut(game, 0) !== "-" ? getHighOut(game, 0) : undefined,
            worstLeg: getWorstLeg(game, 0) !== "-" ? getWorstLeg(game, 0) : undefined,
            best: getBestLeg(game, 0) !== "-" ? getBestLeg(game, 0) : undefined});
        players.push({playerId: game.players[1].id, name: game.players[1].name, legAnwurf: isLegBeginner(game, 1 ),
            active: isPlayerActiveByPlayer(game, game.players[1], selectedScore), lastThrow: lastThrowByPlayer1,
            toGo: getToGo(game, 1),
            leg: getLeg(game, 1),
            canCheck: canCheck(game, getToGo(game, 1), true),
            gewonneneSets: getGewonneneSets(game, 1),
            gewonneneLegs: getGewonneneLegs(game, 1),
            average: getAverage(game, 1),
            averageLeg: getAverageLeg(game, 1),
            averageFirst9: getAverageFirst9(game, 1),
            count100Plus: get100Plus(game, 1),
            count140Plus: get140Plus(game, 1),
            count180er: get180er(game, 1),
            count171er: get171er(game, 1),
            highOut: getHighOut(game, 1) !== "-" ? getHighOut(game, 1) : undefined,
            worstLeg: getWorstLeg(game, 1) !== "-" ? getWorstLeg(game, 1) : undefined,
            best: getBestLeg(game, 1) !== "-" ? getBestLeg(game, 1) : undefined});
        let data = {gameId: game.id, tournamentId: game.turnierId,
            modusId: game.modusId, startpunkte: Number(game.startpunkte),
            boardId: game.boardId,
            boardBezeichnung: game.boardBezeichnung,
            mannschaftPlayer1: game.mannschaftPlayer1,
            mannschaftPlayer2: game.mannschaftPlayer2,
            dartType: game.dartType,
            inGame: false,
            startssoon: false,
            schreiberId: game.schreiberId,
            schreiberName: game.schreiberName,
            sets: Number(game.sets), bestOfLegs: game.bestoflegs !== undefined ? Number(game.bestoflegs) : undefined,
            firstToLegs: game.firsttolegs !== undefined ? Number(game.firsttolegs) : undefined,
            gewinnerPlayerId: undefined, abgeschlossen: game.abgeschlossen, dhGameShortPlayerInfoDtos: players};
        FetchUtil.fetchPost(this.state.url + "/saveShortInfo", this.state.token, this.state.boardToken,
            data,
                () => {},
            responseNotOk => {
                // nix tun, es gibt Probleme aber an der Stelle unwichtig
                console.log("Fehler: " + responseNotOk.message);
            },
            error => {
                // nix tun, es gibt Probleme aber an der Stelle unwichtig
                console.log("Fehler: " + error.message);
                console.log(error);
            },
            () => {
                // onForbitten
                if( this.state.forbittenCounter > 7 ) {
                    let showSettings = {...this.state.showSettings, alleSpieleImLiveView: false};
                    this.setState({message: "Fehlerhafte Konfiguration zum Server, Konfig wird gelöscht.",
                        forbittenCounter: this.state.forbittenCounter + 1,
                        showSettings: showSettings});
                    saveToLocalStorage("dh_alle_spiele_im_live_view", false);

                } else if( this.state.forbittenCounter > 5 ) {
                    this.setState({message: "Fehlerhafte Konfiguration zum Server.", forbittenCounter: this.state.forbittenCounter + 1});
                } else {
                    this.setState({forbittenCounter: this.state.forbittenCounter + 1});
                }
            });
    }

    zurueckZumLetztenLeg() {
        let zuruecksetzenMoeglich = this.isZuruecksetzenMoeglich();
        if( !zuruecksetzenMoeglich ) {
            return;
        }
        let game = this.state.game;
        let aktuelleSetnummerImArray;
        let aktuelleLegnummerImArray;
        if( game.abgeschlossen) {
            aktuelleSetnummerImArray = this.state.game.players[0].sets.length - 1;
            aktuelleLegnummerImArray = game.players[0].sets[aktuelleSetnummerImArray].legs.length;
            game.abgeschlossen = false;
        } else {
            aktuelleSetnummerImArray = getSetByPlayer(this.state.game.players[0]).setnummer - 1;
            aktuelleLegnummerImArray = game.players[0].sets[aktuelleSetnummerImArray].legs.length - 1;
        }
        let newSelectedScore;
        for (const player of this.state.game.players) {
            let neueLegs = [];
            let neueSetnummerImArray;
            let neueLegnummerImArray;
            if( aktuelleSetnummerImArray > 0 && aktuelleLegnummerImArray === 0 ) {
                // erstes Leg in einem Set, wir löschen das letzte Set und im letzen Leg des vorherigen Sets wird zurückgesetzt
                let newSets = [];
                for ( let setCounter = 0 ; setCounter < player.sets.length - 1; setCounter++) {
                    newSets.push(player.sets[setCounter]);
                }
                game.players[player.playernummer - 1].sets = newSets;
                neueSetnummerImArray = aktuelleSetnummerImArray - 1;
                neueLegnummerImArray = game.players[player.playernummer - 1].sets[neueSetnummerImArray].legs.length -1;
            } else {
                // leg normal zurücksetzen, auf das Vorleg
                for (let counter = 0; counter < aktuelleLegnummerImArray; counter++) {
                    neueLegs.push(player.sets[aktuelleSetnummerImArray].legs[counter]);
                }
                game.players[player.playernummer - 1].sets[aktuelleSetnummerImArray].legs = neueLegs;
                neueSetnummerImArray = aktuelleSetnummerImArray;
                neueLegnummerImArray = aktuelleLegnummerImArray - 1;
            }

            game.players[player.playernummer - 1].gewonnen = false;
            game.players[player.playernummer - 1].sets[neueSetnummerImArray].gewonnen = false;
            game.players[player.playernummer - 1].sets[neueSetnummerImArray].abgeschlossen = false;
            game.players[player.playernummer - 1].sets[neueSetnummerImArray].legs[neueLegnummerImArray].gewonnen = false;
            game.players[player.playernummer - 1].sets[neueSetnummerImArray].legs[neueLegnummerImArray].abgeschlossen = false;
            let scores = game.players[player.playernummer - 1].sets[neueSetnummerImArray].legs[neueLegnummerImArray].scores;
            let neueScores = [];
            for (const score of scores) {
                let toGoIstNull = score.togo === Number(0);
                if( score.value === "") {
                    continue;
                }
                if( toGoIstNull ) {
                    newSelectedScore = score.counter;
                    score.togo = "";
                    score.value = "";
                    neueScores.push(score);
                } else {
                    neueScores.push(score);
                }
            }
            game.players[player.playernummer - 1].sets[neueSetnummerImArray].legs[neueLegnummerImArray].scores = neueScores;
        }
        game.selectedScoreIndex = newSelectedScore;
        this.setState({game: game, waitingSeconds: undefined});
        this.saveToLocalStorage(game);
        this.sendGameShortInfoToBackend(game, newSelectedScore);
    }

    addValueToSelectedValue(value) {
        if( this.state.game.abgeschlossen !== null && this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen) {
            return;
        }
        if (!this.state.selectedValueIsNew && this.state.selectedValue.length === 3) {
            return;
        } else if (this.state.selectedValueIsNew) {
            this.setState({selectedValue: value, selectedValueIsNew: false});
        } else {
            if( this.state.selectedValue === "0" && value === "0") {
                // keine 2 nullen hintereinander.
                return;
            }
            if( this.state.selectedValue === "0") {
                // verhindert führende 0
                this.setState({selectedValue: value});
                return;
            }
            this.setState({selectedValue: this.state.selectedValue + value});
        }
    }

    removeFromSelectedValue() {
        if( this.state.game.abgeschlossen !== null && this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen) {
            return;
        }
        if (this.state.selectedValue.length > 0) {
            let newValue = this.state.selectedValue.substring(0, this.state.selectedValue.length - 1);
            this.setState({selectedValue: newValue});
        }
    }

    getCheckout(playerCounter) {
        let toGo = getToGo(this.state.game, playerCounter);
        return this.state.game.modusId === "double_out" || this.state.game.modusId === "double_in_double_out" ? getCheckout(toGo) : undefined;
    }

    changeAnsicht() {
        if( this.state.ansicht === 1) {
            this.setState({ansicht: 2});
        } else {
            this.setState({ansicht: 1});
        }
    }

    startWaitingTimer() {
        this.setState({waitingSeconds:this.state.showSettings.secondsToDashboard});
        let myInterval = setInterval(() => {
            if( this.state.waitingSeconds === undefined) {
                clearInterval(myInterval);
                return;
            }
            if( this.state.waitingSeconds <= 0 ) {
                this.changeToDashboard();
                return;
            }
            this.setState({waitingSeconds: this.state.waitingSeconds - 1});
        }, 1000);
    }

    showUebertragenDialog() {
        if( this.state.game !== null && this.state.game.abgeschlossen &&
            this.isTurnierGame() &&
            this.state.game.uebertragen === undefined) {
            return true;
        }

        return false;
    }

    renderSmartphone() {
        let anzahlScores = this.state.game.abgeschlossen ? 0 : getAnzahleWuerfeImAktivenLeg(this.state.game);

        return <div className="bg"
                    style={{height: "100%", minHeight: "100vh", width: "100%"}}>
            <AutoScoreComponent angeschlossen={this.state.game.abgeschlossen} onScore={(score) => this.changeAutoScore(score)} />
            <Snackbar open={this.state.message !== undefined} autoHideDuration={3000} onClose={() => this.setState({message: undefined})}
                      anchorOrigin={{ vertical:"top", horizontal:"center" }}>
                <Alert onClose={() => this.setState({message: undefined})} severity="error" sx={{ width: '100%', fontSize:30 }}>
                    {this.state.message}
                </Alert>
            </Snackbar>
            {this.state.showFinishQuestion && <FinishDartsDialog onClose={() => this.setState({showFinishQuestion:false})} onSave={(wuerfe) => {
                this.setState({showFinishQuestion: false});
                this.finishLeg(wuerfe);
            }}/>}
            {this.state.showEinstellungenDialog && <X01EinstellungenDialog smartphone={isSmartphone()}
                onPlayerScoreChange={() => {
                    this.changeScoreByPlayers();
                    this.setState({showEinstellungenDialog: false});
                }}
                onClose={() => this.setState({showEinstellungenDialog:false})}/>}
            <div className="grid" style={{height: "100%", minHeight: "100vh", padding:0, margin:0}}>
                <div className="col">
                        {this.state.game.players.map((player) => {
                            let active = isPlayerActive(this.state.game, player.playernummer - 1, getSelectedScoreIndex(this.state.game));
                            let legBeginner = isLegBeginner(this.state.game, player.playernummer -1);
                            let tmpCanCheck = canCheck(this.state.game, getToGo(this.state.game, player.playernummer - 1), this.state.showSettings.showFinishColors);
                            return <div className="grid" style={{width: "100%", margin: 0, marginTop: 0, padding: 0,}}>
                                <div className="col" style={{
                                color: "white"
                            }}>
                                <div className="grid" style={{
                                    fontSize:30,
                                    borderLeft: legBeginner ? "10px solid #ac1c27" : "10px solid white",
                                    borderRight: active ? "10px solid #ac1c27" : "10px solid white",
                                    backgroundColor:"white",
                                    color:"black",
                                }}>
                                    <div className="col">
                                        <span>{getPlayer(this.state.game, player.playernummer -1).name}</span><br/>
                                        {isSetModus(this.state.game) ?
                                            <span style={{fontSize:20}}>S: {getGewonneneSets(this.state.game, player.playernummer -1)} L: {getGewonneneLegs(this.state.game, player.playernummer - 1)}</span>
                                            :
                                            <span style={{fontSize:20}}>Legs: {getGewonneneLegs(this.state.game, player.playernummer - 1)}</span>
                                        }
                                    </div>
                                    <div className="col" style={{color: tmpCanCheck ? "#46FF33" : ""}}>
                                        <span>{getToGo(this.state.game, player.playernummer - 1)}</span><br/>
                                        <span style={{fontSize:20}}>Last: {getLastThrowByPlayer(this.state.game, player.playernummer - 1)}</span>
                                    </div>
                                </div>
                            </div>
                            </div>
                        })}
                    {this.state.showStatistic && <div className="grid">
                        {this.state.game.players.map((player) => {
                            return <StatistikAreaSmartphone name={getPlayer(this.state.game, player.playernummer -1).name}
                                                  player={getPlayer(this.state.game, player.playernummer -1)}
                                                  legAverage={this.state.game.abgeschlossen ? 0 : getLeg(this.state.game, player.playernummer -1).legAverage}
                                                  checkout={this.getCheckout(player.playernummer -1)}
                                                  isSetModus={isSetModus(this.state.game)}
                                                  average={getAverage(this.state.game, player.playernummer -1)}
                                                  averageFirst9={getAverageFirst9(this.state.game, player.playernummer -1)}
                                                  averageLeg={getAverageLeg(this.state.game, player.playernummer -1)}
                                                  count100Plus={get100Plus(this.state.game, player.playernummer -1)}
                                                  count140Plus={get140Plus(this.state.game, player.playernummer -1)}
                                                  count180er={get180er(this.state.game, player.playernummer -1)}
                                                  count171er={get171er(this.state.game, player.playernummer -1)}
                                                  gewonneneSets={getGewonneneSets(this.state.game, player.playernummer -1)}
                                                  gewonneneLegs={getGewonneneLegs(this.state.game, player.playernummer -1)}
                                                  bestLeg={getBestLeg(this.state.game, player.playernummer -1)}
                                                  worstLeg={getWorstLeg(this.state.game, player.playernummer -1)}
                                                  highOut={getHighOut(this.state.game, player.playernummer -1)}
                                                  showSettings={this.state.showSettings}/>
                        })}
                    </div>}
                    {!this.state.showStatistic && this.isZuruecksetzenMoeglich() &&
                        <div className="grid" style={{
                            width: "100%",
                            height: 50,
                            margin: 0,
                            marginBottom: 10,
                            textAlign: "center",
                            fontSize: 20,
                            fontWeight: "bold",
                            cursor: "pointer"
                        }} onClick={() => this.zurueckZumLetztenLeg()}>
                            <div className="col"
                                 style={{borderBottom: "2px solid black", backgroundColor: "white"}}>Zurück zum
                                letzten Leg (Z)
                            </div>
                        </div>
                    }
                    {(!this.state.showStatistic && (this.state.game.abgeschlossen === undefined || !this.state.game.abgeschlossen)) &&
                        <div>
                            {this.isPlayerChangeAllowed() &&
                                <PlayerChangeArea onChange={() => this.changeSelectedScore(0)} fontSize={15} iconFontSize={25}/>}
                            <ModusAreaSmartphone sets={this.state.game.sets} firsttolegs={this.state.game.firsttolegs}
                                       bestoflegs={this.state.game.bestoflegs} modusid={this.state.game.modusId}/>
                            <ScoreHeaderSmartphone fontSize={12}/>
                            <ScoreAreaSmartphone scoresPlayer1={getLeg(this.state.game, 0).scores}
                                                           scoresPlayer2={getLeg(this.state.game, 1).scores}
                                                           anzScores={anzahlScores}
                                                           selectedScore={getSelectedScoreIndex(this.state.game)}
                                                           selectedValue={this.state.selectedValue}
                                                           startpunkte={this.state.game.startpunkte}
                                                           setSelectedScore={(score) => {
                                                               let value = getWertImAktivenLeg(this.state.game, score);
                                                               let game = this.state.game;
                                                               game.selectedScoreIndex = score;
                                                               this.setState({game: game, selectedValue:value})
                                                           }}/>
                        </div>
                    }
                    {!this.state.showStatistic && this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen &&
                        <div style={{
                            borderLeft: "2px solid black",
                            borderRight: "2px solid black",
                            textAlign: "center"
                        }}>
                            <ModusArea sets={this.state.game.sets} firsttolegs={this.state.game.firsttolegs}
                                       bestoflegs={this.state.game.bestoflegs} modusid={this.state.game.modusId}/>
                            <h1 style={{color: "white", marginTop:30}}>Spiel ist beendet</h1>
                            {this.state.game.unentschieden !== undefined &&
                                <h1 style={{color: "white", marginTop:20}}>Unentschieden</h1>
                            }

                            {this.state.game.unentschieden === undefined &&
                                <h1 style={{color: "white", marginTop:20}}>Gewinner<br/>{ermittleGewinner(this.state.game).name}</h1>
                            }
                            {this.state.game.uebertragen !== undefined && this.state.game.uebertragen === "OK" && <div style={{color:"green", fontSize: 15}}>Das Spiel wurde an darthelfer.de übertragen.</div>}
                            {(this.state.game.uebertragen !== undefined && this.state.game.uebertragen === "FEHLER") && <div style={{backgroundColor:"red", color:"white", fontSize:20, cursor:"pointer"}}
                                                                                                                             onClick={() => this.uebertrageErgebnisAnsBackend(this.state.game)}>
                                <div>Fehler ({this.state.retries}) beim Übertragen an darthelfer.de</div>
                                <div>....Erneut übertragen...</div>
                                <div>Alternativ Ergebnis von Hand im Turnier/Liga eintragen.</div>
                            </div>}
                            <div style={{color: "white", marginTop: 20, fontSize:20, cursor:"pointer"}} onClick={() => window.open(NAVIGATION_ITEM.GAMEDETAIL.route + "?id=dh_game_" + this.state.game.id + "&type=game", "_self")}>Klicke hier und schaue dir Details zum Game an.</div>
                            {this.state.waitingSeconds !== undefined && <>
                                <div style={{color: "white", marginTop: 20, fontSize:20}}>Du wirst in {this.state.waitingSeconds} Sekunden auf das
                                    Dashboard weitergeleitet.</div>
                                <div style={{color: "white", marginTop: 20, fontSize:20, cursor:"pointer"}} onClick={() => this.setState({waitingSeconds: undefined})}>Klicke hier,<br/>damit wirst du nicht automatisch auf das Dashboard weitergeleitet.</div>
                            </>
                            }
                        </div>
                    }
                </div>
                {this.state.game.abgeschlossen && <div style={{position:"absolute", top:0, right:0, width:70}}>
                    <ThemeProvider theme={CustomFontTheme}>
                        <Button variant="text" startIcon={<ExitToAppOutlinedIcon style={{fontSize: 50}}/>}
                                onClick={() => this.changeToDashboard()}/>
                    </ThemeProvider>
                </div>
                }
                {!this.state.game.abgeschlossen &&
                    <div style={{position:"absolute", bottom:0, left:0, width:"100%"}}>
                        <SmartphoneTastatur
                            selectedValue={this.state.selectedValue}
                            showTableScoreValue={this.state.showSettings.showTableScoreValue}
                            showFastScoreButtons={this.isDoubleOutModus()}
                            showUndo={this.isUndoAllowed()}
                            leftScoreAktiv={this.isSaveLeftScoreAktiv()}
                            firstAktive={this.isCheckMitAnzahlWuerfenMoeglich(1)}
                            secondAktive={this.isCheckMitAnzahlWuerfenMoeglich(2)}
                            thirdAktive={this.isCheckMitAnzahlWuerfenMoeglich(3)}
                            gameAbgeschlossen={this.state.game.abgeschlossen}
                            onFinishLeg={(wuerfe) => this.finishLeg(wuerfe)}
                            onUndo={() => this.undoLastScore()}
                            onSaveLeftScore={() => this.saveLeftScore()}
                            onChangeToDashboard={() => this.changeToDashboard()}
                            onShowEinstellungenDialog={() => this.setState({showEinstellungenDialog: true})}
                            onShortCut={(value) => {
                                this.saveValueSelectedScore(Number(value))
                            }} onRemoveValue={() => {
                            this.removeFromSelectedValue()
                        }}
                            showStatistic={this.state.showStatistic}
                            onStatisticChange={() => this.setState({showStatistic: !this.state.showStatistic, waitingSeconds: undefined})}
                            onValueAdd={(value) => {
                                this.addValueToSelectedValue(value);
                            }}
                            onEnter={() => {
                                this.saveValueInSelectedScore();
                            }}/>
                    </div>
                }
            </div>
        </div>
    }

    render() {
        if (this.state.waiting) {
            return <div className="bg"
                        style={{
                            padding:20,
                            minHeight:"100vh",
                            height:"100%",
                            width: "100%",
                            textAlign: "center",
                            verticalAlign: "middle",
                            justifyContent: "center"
                        }}>
                <div style={{color: "white", fontSize:20}}>Viel Spaß im Game<br/>wünscht...</div>
                <img src="/images/darthelfer_border_klein.png" alt="Darthelfer" style={{width: 300}}/>
            </div>
        }
        if (this.state.game === undefined) {
            return <NewGameArea onSave={(game) => {
                this.setState({waiting: true});
                this.saveToLocalStorage(game);
                setTimeout(() => {
                    // zur Sicherheit, sofern der Store evtl. mit dem Schreiben etwas braucht.
                    window.open(NAVIGATION_ITEM.GAME.route + "?id=dh_game_" + game.id, "_self");
                }, 3000);
            }}/>
        }

        if( this.state.game.dartType === "EDART") {
            window.open(NAVIGATION_ITEM.EDARTGAME.route + "?id=dh_game_" + this.state.game.id, "_self");
        }

        if( this.showUebertragenDialog() ) {
            let gewinnerName = undefined;
            let gewinner = ermittleGewinner(this.state.game);
            if( gewinner !== undefined) {
                gewinnerName = gewinner.name;
            }
            let unentschieden = isUnentschieden(this.state.game);
            return <GameUebermittelnArea
                gewinner={gewinnerName}
                unentschieden={unentschieden}
                onClose={() => this.zurueckZumLetztenLeg()}
                onUebermitteln={() => this.uebertrageErgebnisAnsBackend(this.state.game)} />
        }

        if( isSmartphone() ) {
            return this.renderSmartphone()
        }
        if( isTablet() ) {
            return this.renderTablet()
        }
        if( this.state.game.players.length === 2) {
            return this.render2Player();
        } else {
            return this.renderMulitplayer();
        }
    }

    formatSliderRow(bezeichnung, min, max, value, onChange) {
        return <div style={{display:"flex"}}>
            <span style={{marginRight:5, minWidth:200}}>{bezeichnung}</span>
            <Slider defaultValue={value} step={2} marks min={min} max={max} onChange={onChange} style={{width:200, minWidth:100}}/>
            <span style={{marginLeft:10}} onClick={() => "" }>{value}</span>
        </div>
    }

    renderTablet() {
        let anzahlScores = this.state.game.abgeschlossen ? 0 : getAnzahleWuerfeImAktivenLeg(this.state.game);

        return <div className="bg"
             style={{height: "100%", minHeight: "100vh", width: "100%"}}>
            {this.getAutodartsConnectionArea()}
            <AutoScoreComponent angeschlossen={this.state.game.abgeschlossen} onScore={(score) => this.changeAutoScore(score)} />
            <Snackbar open={this.state.message !== undefined} autoHideDuration={3000} onClose={() => this.setState({message: undefined})}
                      anchorOrigin={{ vertical:"top", horizontal:"center" }}>
                <Alert onClose={() => this.setState({message: undefined})} severity="error" sx={{ width: '100%', fontSize:30 }}>
                    {this.state.message}
                </Alert>
            </Snackbar>
            {this.state.showFormatDialog &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}>
                    <Stack gap={1} justifyContent="left" alignItems="left" style={{fontSize:20}}>
                        <div>
                            <Button variant="text" startIcon={<CancelPresentationIcon style={{fontSize: 50, color:"red"}}/>}
                                    onClick={() => {
                                        this.setState({showFormatDialog: false});
                                        this.saveSettings()
                                    }}
                            />
                        </div>
                        <div style={{display:"flex", fontWeight:"bold"}}>
                            Schriftgrößen
                        </div>
                        {this.formatSliderRow("ToGo:", 10, 400, this.state.tabletSettings.toGoFontSize,
                            (event, newValue) => this.setState({tabletSettings: {...this.state.tabletSettings, toGoFontSize: newValue}}))
                        }
                        {this.formatSliderRow("Spieler:", 10, 250, this.state.tabletSettings.playerFontSize,
                            (event, newValue) => this.setState({tabletSettings: {...this.state.tabletSettings, playerFontSize: newValue}}))
                        }
                        {this.formatSliderRow("Modus:", 8, 100, this.state.tabletSettings.modusFontSize,
                            (event, newValue) => this.setState({tabletSettings: {...this.state.tabletSettings, modusFontSize: newValue}}))
                        }
                        {this.formatSliderRow("Scorekopfbereich:", 8, 100, this.state.tabletSettings.scoreAreaHeaderFontSize,
                            (event, newValue) => this.setState({tabletSettings: {...this.state.tabletSettings, scoreAreaHeaderFontSize: newValue}}))
                        }
                        {this.formatSliderRow("Scorebereich:", 8, 160, this.state.tabletSettings.scoreAreaFontSize,
                            (event, newValue) => this.setState({tabletSettings: {...this.state.tabletSettings, scoreAreaFontSize: newValue}}))
                        }
                        {this.formatSliderRow("Tastatur:", 8, 100, this.state.tabletSettings.keyBoardFontSize,
                            (event, newValue) => this.setState({tabletSettings: {...this.state.tabletSettings, keyBoardFontSize: newValue}}))
                        }
                        {this.formatSliderRow("Rest-usw. Tastatur:", 8, 150, this.state.tabletSettings.shortKeyBoardFontSize,
                            (event, newValue) => this.setState({tabletSettings: {...this.state.tabletSettings, shortKeyBoardFontSize: newValue}}))
                        }
                        <div style={{display:"flex", marginTop:30}}>
                            {this.formatSliderRow("Tastaturhöhe:", 10, 300, this.state.tabletSettings.keyBoardRowHeight,
                                (event, newValue) => this.setState({tabletSettings: {...this.state.tabletSettings, keyBoardRowHeight: newValue}}))
                            }
                        </div>
                    </Stack>
                </Backdrop>
            }

            {this.state.showFinishQuestion && <FinishDartsDialog onClose={() => this.setState({showFinishQuestion:false})} onSave={(wuerfe) => {
                this.setState({showFinishQuestion: false});
                this.finishLeg(wuerfe);
            }}/>}
            {this.state.showEinstellungenDialog && <X01EinstellungenDialog
                onPlayerScoreChange={() => {
                    this.changeScoreByPlayers();
                    this.setState({showEinstellungenDialog: false});
                }}
                onClose={() => this.setState({showEinstellungenDialog:false})}/>}
            <div className="grid" style={{height: "100%", minHeight: "100vh", padding:0, margin:0}}>
                <div className="col-fixed" style={{width: isTabletHochformat(this.state.windowdimensions) ? "100%" : "66%"}}>
                    <div className="grid" style={{width: "100%", margin: 0, marginTop: 0, padding: 0,}}>
                        {this.state.game.players.map((player) => {
                            return <PlayerSingleArea player={getPlayer(this.state.game, player.playernummer - 1)}
                                                     isSetModus={isSetModus(this.state.game)}
                                                     isLegBeginner={isLegBeginner(this.state.game, player.playernummer -1)}
                                                     togo={getToGoWithAutodarts(this.state.game, player.playernummer - 1, isPlayerActive(this.state.game, player.playernummer - 1, getSelectedScoreIndex(this.state.game)) ? this.state.autodartsValue : 0)}
                                                     active={isPlayerActive(this.state.game, player.playernummer - 1, getSelectedScoreIndex(this.state.game))}
                                                     canCheck={canCheck(this.state.game, getToGo(this.state.game, player.playernummer - 1), this.state.showSettings.showFinishColors)}
                                                     fontSize={this.state.tabletSettings.toGoFontSize}/>

                        })}
                    </div>
                    {!this.state.showStatistic &&
                        <div className="grid">
                            {this.state.game.players.map((player) => {
                                return <PlayerTableArea name={getPlayer(this.state.game, player.playernummer -1).name}
                                                 mannschaftName={player.playernummer - 1 === 0 ? this.state.game.mannschaftPlayer1 : this.state.game.mannschaftPlayer2}
                                                 isSetModus={isSetModus(this.state.game)}
                                                 showCheckOut={this.state.showSettings.showCheckout}
                                                 checkOut={this.getCheckout(player.playernummer - 1)}
                                                 gewonneneSets={getGewonneneSets(this.state.game, player.playernummer -1)}
                                                 gewonneneLegs={getGewonneneLegs(this.state.game, player.playernummer -1)}
                                                        fontSize={this.state.tabletSettings.playerFontSize}
                                playerCount={this.state.game.players.length}/>
                            })}
                        </div>
                    }
                    {this.state.showStatistic && <div className="grid">
                        {this.state.game.players.map((player) => {
                            return <StatistikArea orientation="left" tablet={true} name={getPlayer(this.state.game, player.playernummer -1).name}
                                           player={getPlayer(this.state.game, player.playernummer -1)}
                                           isLegBeginner={false}
                                           legAverage={this.state.game.abgeschlossen ? 0 : getLeg(this.state.game, player.playernummer -1).legAverage}
                                           checkout={this.getCheckout(player.playernummer -1)}
                                           isSetModus={isSetModus(this.state.game)}
                                           average={getAverage(this.state.game, player.playernummer -1)}
                                           averageFirst9={getAverageFirst9(this.state.game, player.playernummer -1)}
                                           averageLeg={getAverageLeg(this.state.game, player.playernummer -1)}
                                           count100Plus={get100Plus(this.state.game, player.playernummer -1)}
                                           count140Plus={get140Plus(this.state.game, player.playernummer -1)}
                                           count180er={get180er(this.state.game, player.playernummer -1)}
                                           count171er={get171er(this.state.game, player.playernummer -1)}
                                           gewonneneSets={getGewonneneSets(this.state.game, player.playernummer -1)}
                                           gewonneneLegs={getGewonneneLegs(this.state.game, player.playernummer -1)}
                                           bestLeg={getBestLeg(this.state.game, player.playernummer -1)}
                                           worstLeg={getWorstLeg(this.state.game, player.playernummer -1)}
                                           highOut={getHighOut(this.state.game, player.playernummer -1)}
                                           showSettings={this.state.showSettings}/>
                        })}
                    </div>}
                    {!this.state.showStatistic && this.isZuruecksetzenMoeglich() &&
                            <div className="grid" style={{
                                width: "100%",
                                height: 50,
                                margin: 0,
                                marginBottom: 10,
                                textAlign: "center",
                                fontSize: 25,
                                fontWeight: "bold",
                                cursor: "pointer"
                            }} onClick={() => this.zurueckZumLetztenLeg()}>
                                <div className="col"
                                     style={{borderBottom: "2px solid black", backgroundColor: "white"}}>Zurück zum
                                    letzten Leg (Z)
                                </div>
                            </div>
                    }
                    {(!this.state.showStatistic && (this.state.game.abgeschlossen === undefined || !this.state.game.abgeschlossen)) &&
                        <div>
                            {this.isPlayerChangeAllowed() &&
                                <PlayerChangeArea onChange={() => this.changeSelectedScore(0)}/>}
                            <ModusArea sets={this.state.game.sets} firsttolegs={this.state.game.firsttolegs}
                                       bestoflegs={this.state.game.bestoflegs} modusid={this.state.game.modusId}
                                       fontSize={this.state.tabletSettings.modusFontSize}/>
                            <ScoreAreaTablet game={this.state.game} players={this.state.game.players}
                                          anzScores={anzahlScores}
                                          selectedScore={getSelectedScoreIndex(this.state.game)}
                                          selectedValue={this.state.selectedValue}
                                          startpunkte={this.state.game.startpunkte}
                                          fontSize={this.state.tabletSettings.scoreAreaFontSize}
                                             fontSizeHeader={this.state.tabletSettings.scoreAreaHeaderFontSize}
                                          setSelectedScore={(score) => {
                                              let value = getWertImAktivenLeg(this.state.game, score);
                                              if( value === "" && score === 2 && getSelectedScoreIndex(this.state.game) === 1) {
                                                  this.changeSelectedScore(0);
                                              } else {
                                                  let game = this.state.game;
                                                  game.selectedScoreIndex = score;
                                                  this.setState({game: game, selectedValue: value});
                                                  this.sendGameShortInfoToBackend(game, score);
                                                  this.saveToLocalStorage(game);
                                              }
                                          }}/>
                        </div>
                    }
                    {!this.state.showStatistic && this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen &&
                        <div style={{
                            borderLeft: "2px solid black",
                            borderRight: "2px solid black",
                            textAlign: "center"
                        }}>
                            <ModusArea sets={this.state.game.sets} firsttolegs={this.state.game.firsttolegs}
                                       bestoflegs={this.state.game.bestoflegs} modusid={this.state.game.modusId}/>
                            <h1 style={{color: "white", marginTop:30}}>Spiel ist beendet</h1>
                            {this.state.game.unentschieden !== undefined &&
                                <h1 style={{color: "white", marginTop:20}}>Unentschieden</h1>
                            }

                            {this.state.game.unentschieden === undefined &&
                                <h1 style={{color: "white", marginTop:20}}>Gewinner<br/>{ermittleGewinner(this.state.game).name}</h1>
                            }
                            {this.state.game.uebertragen !== undefined && this.state.game.uebertragen === "OK" && <div style={{color:"green", fontSize: 25}}>Das Spiel wurde an darthelfer.de übertragen.</div>}
                            {(this.state.game.uebertragen !== undefined && this.state.game.uebertragen === "FEHLER") && <div style={{backgroundColor:"red", color:"white", fontSize:30, cursor:"pointer"}}
                                                                                                                             onClick={() => this.uebertrageErgebnisAnsBackend(this.state.game)}>
                                <div>Fehler ({this.state.retries}) beim Übertragen an darthelfer.de</div>
                                <div>....Erneut übertragen...</div>
                                <div>Alternativ Ergebnis von Hand im Turnier/Liga eintragen.</div>
                            </div>}
                            <div style={{color: "white", marginTop: 20, fontSize:30, cursor:"pointer"}} onClick={() => window.open(NAVIGATION_ITEM.GAMEDETAIL.route + "?id=dh_game_" + this.state.game.id + "&type=game", "_self")}>Klicke hier und schaue dir Details zum Game an.</div>
                            {this.state.waitingSeconds !== undefined && <>
                                <div style={{color: "white", marginTop: 20, fontSize:30}}>Du wirst in {this.state.waitingSeconds} Sekunden auf das
                                    Dashboard weitergeleitet.</div>
                                <div style={{color: "white", marginTop: 20, fontSize:30, cursor:"pointer"}} onClick={() => this.setState({waitingSeconds: undefined})}>Klicke hier,<br/>damit wirst du nicht automatisch auf das Dashboard weitergeleitet.</div>
                            </>
                            }
                        </div>
                    }
                </div>
            </div>
            {isTabletQuerformat(this.state.windowdimensions) &&
                <div style={{position:"absolute", bottom:0, right:0, width:"33%"}}>
                    {this.state.showKeyBoard &&
                        <TabletTastaturQuerformat
                            onShortCut={(value) => {
                                this.saveValueSelectedScore(Number(value))
                            }} onRemoveValue={() => {
                            this.removeFromSelectedValue()
                        }}
                            showStatistic={this.state.showStatistic}
                            onStatisticChange={() => this.setState({showStatistic: !this.state.showStatistic, waitingSeconds: undefined})}
                            onValueAdd={(value) => {
                                this.addValueToSelectedValue(value);
                            }}
                            onEnter={() => {
                                this.saveValueInSelectedScore();
                            }}
                            fontSize={this.state.tabletSettings.keyBoardFontSize}
                            rowHeight={this.state.tabletSettings.keyBoardRowHeight}
                        />
                    }
                </div>
            }
            {isTabletHochformat(this.state.windowdimensions) && this.state.game.abgeschlossen && <div style={{position:"absolute", top:0, left:0, width:"100%"}}>
                <ThemeProvider theme={CustomFontTheme}>
                    <Button variant="text" startIcon={<ExitToAppOutlinedIcon style={{fontSize: 50}}/>}
                            onClick={() => this.changeToDashboard()}/>
                </ThemeProvider>
            </div>
            }
            {isTabletHochformat(this.state.windowdimensions) && !this.state.game.abgeschlossen &&
                <div style={{position:"absolute", bottom:0, left:0, width:"100%"}}>
                    <TabletTastaturHochformat
                        fontSize={this.state.tabletSettings.keyBoardFontSize}
                        fontShortKeys={this.state.tabletSettings.shortKeyBoardFontSize}
                        showUndo={this.isUndoAllowed()}
                        rowHeight={this.state.tabletSettings.keyBoardRowHeight}
                        onChangeToFormat={() => this.setState({showFormatDialog: true})}
                        selectedValue={this.state.selectedValue}
                        showTableScoreValue={this.state.showSettings.showTableScoreValue}
                        showFastScoreButtons={this.isDoubleOutModus()}
                        showKeyboard={this.state.showKeyBoard}
                        onKeyboardChange={() => this.setState({showKeyBoard: !this.state.showKeyBoard})}
                        leftScoreAktiv={this.isSaveLeftScoreAktiv()}
                        firstAktive={this.isCheckMitAnzahlWuerfenMoeglich(1)}
                        secondAktive={this.isCheckMitAnzahlWuerfenMoeglich(2)}
                        thirdAktive={this.isCheckMitAnzahlWuerfenMoeglich(3)}
                        gameAbgeschlossen={this.state.game.abgeschlossen}
                        onFinishLeg={(wuerfe) => this.finishLeg(wuerfe)}
                        onSaveLeftScore={() => this.saveLeftScore()}
                        onChangeToDashboard={() => this.changeToDashboard()}
                        onShowEinstellungenDialog={() => this.setState({showEinstellungenDialog: true})}
                        onUndo={() => this.undoLastScore()}
                        onShortCut={(value) => {
                            this.saveValueSelectedScore(Number(value))
                        }} onRemoveValue={() => {
                        this.removeFromSelectedValue()
                    }}
                        showStatistic={this.state.showStatistic}
                        onStatisticChange={() => this.setState({showStatistic: !this.state.showStatistic, waitingSeconds: undefined})}
                        onValueAdd={(value) => {
                            this.addValueToSelectedValue(value);
                        }}
                        onEnter={() => {
                            this.saveValueInSelectedScore();
                        }}/>
                </div>
            }
            {isTabletQuerformat(this.state.windowdimensions) &&
                <div style={{position: "fixed", left: 5, bottom: 0, width:"66%"}}>
                    <TabletTastaturQuerformatFastScoreArea
                        selectedValue={this.state.selectedValue}
                        showTableScoreValue={this.state.showSettings.showTableScoreValue}
                        showFastScoreButtons={this.isDoubleOutModus() && this.state.showKeyBoard}
                        leftScoreAktiv={this.isSaveLeftScoreAktiv()}
                        showUndo={this.isUndoAllowed()}
                        showKeyboard={this.state.showKeyBoard}
                        firstAktive={this.isCheckMitAnzahlWuerfenMoeglich(1)}
                        secondAktive={this.isCheckMitAnzahlWuerfenMoeglich(2)}
                        thirdAktive={this.isCheckMitAnzahlWuerfenMoeglich(3)}
                        gameAbgeschlossen={this.state.game.abgeschlossen}
                        fontSize={this.state.tabletSettings.shortKeyBoardFontSize}
                        onFinishLeg={(wuerfe) => this.finishLeg(wuerfe)}
                        onSaveLeftScore={() => this.saveLeftScore()}
                        onChangeToDashboard={() => this.changeToDashboard()}
                        onShowEinstellungenDialog={() => this.setState({showEinstellungenDialog: true})}
                        onUndo={() => this.undoLastScore()}
                        onKeyboardChange={() => this.setState({showKeyBoard: !this.state.showKeyBoard})}
                        onChangeToFormat={() => this.setState({showFormatDialog: true})}
                    />
                </div>
            }
        </div>
    }

    renderMulitplayer () {
        let anzahlScores = this.state.game.abgeschlossen ? 0 : getAnzahleWuerfeImAktivenLeg(this.state.game);

        return <div className="bg"
                    style={{height: "100%", minHeight: "100vh", width: "100%"}}>
            <Snackbar open={this.state.message !== undefined} autoHideDuration={3000} onClose={() => this.setState({message: undefined})}
                      anchorOrigin={{ vertical:"top", horizontal:"center" }}>
                <Alert onClose={() => this.setState({message: undefined})} severity="error" sx={{ width: '100%', fontSize:30 }}>
                    {this.state.message}
                </Alert>
            </Snackbar>
            <div style={{position: "fixed", left: 5, bottom: 55}}>
                <ThemeProvider theme={CustomFontTheme}>
                    <Button variant="text"
                            onClick={() => this.changeToDashboard()}>Esc/*</Button>
                </ThemeProvider>
            </div>
            <div style={{position: "fixed", left: 5, bottom: 10}}>
                <ThemeProvider theme={CustomFontTheme}>
                    <Button variant="text" startIcon={<ExitToAppOutlinedIcon style={{fontSize: 50}}/>}
                            onClick={() => this.changeToDashboard()}/>
                </ThemeProvider>
            </div>
            <div className="grid" style={{padding:0, margin:0, backgroundColor:"white"}}>
                <div className="col">
                    <ModusArea sets={this.state.game.sets} firsttolegs={this.state.game.firsttolegs}
                               bestoflegs={this.state.game.bestoflegs} modusid={this.state.game.modusId}/>
                </div>
                <div className="col">
                    <div className="grid" style={{
                        width: "100%", height: 50,
                        padding: 0,
                        margin: 0,
                        textAlign: "center",
                        fontSize: 25,
                    }}>
                        <div className="col" style={{backgroundColor: "white", cursor:"pointer"}} onClick={() => this.changeAnsicht()}>Ansicht
                            <img src={this.state.ansicht === 1 ? "/images/one.png" : "/images/two.png"} style={{marginLeft:5, width:30}} alt="Ansicht" /> ist aktiv (A zum Wechseln)</div>
                    </div>
                </div>
                {this.isZuruecksetzenMoeglich() &&
                    <div className="col">
                        <div className="grid" style={{
                            width: "100%", height: 50,
                            padding: 0,
                            margin: 0,
                            textAlign: "center",
                            fontSize: 25, cursor:"pointer"}} onClick={() => this.zurueckZumLetztenLeg()}>
                            <div className="col" style={{backgroundColor: "white"}}>Zurück zum letzten Leg (Z)</div>
                        </div>
                    </div>
                }
            </div>
            {this.state.ansicht === 1 &&
                <div className="grid" style={{width:"100%", margin:0, padding:0}}>
                    <div className="col">
                        {this.state.game.players.map(player => <>
                        <PlayerStatistikMultiplayerArea player={player}
                                                        isLegBeginner={isLegBeginner(this.state.game, player.playernummer -1 )}
                                                        active={isPlayerActiveByPlayer(this.state.game, player, getSelectedScoreIndex(this.state.game))}
                                                        average={getAverage(this.state.game, player.playernummer - 1)}
                                                        averageFirst9={getAverageFirst9(this.state.game, player.playernummer - 1)}
                                                        averageLeg={getAverageLeg(this.state.game, player.playernummer - 1)}
                                                        count100Plus={get100Plus(this.state.game, player.playernummer - 1)}
                                                        count140Plus={get140Plus(this.state.game, player.playernummer - 1)}
                                                        count180er={get180er(this.state.game, player.playernummer - 1)}
                                                        count171er={get171er(this.state.game, player.playernummer - 1)}
                                                        bestLeg={getBestLeg(this.state.game, player.playernummer - 1)}
                                                        worstLeg={getWorstLeg(this.state.game, player.playernummer - 1)}
                                                        highOut={getHighOut(this.state.game, player.playernummer - 1)}
                                                        showSettings={this.state.showSettings}/>
                        </>)}
                    </div>
                </div>
            }
            <div className="grid" style={{width:"100%"}}>
                {this.state.game.players.map(player => <div className="col">
                    <PlayerToGoMultiplayerArea player={player} isSetModus={isSetModus(this.state.game)}
                                               togo={getToGoByPlayer(this.state.game, player)}
                                               isLegBeginner={isLegBeginner(this.state.game, player.playernummer -1 )}
                                               gewonneneSets={getGewonneneSets(this.state.game, player.playernummer -1)}
                                               gewonneneLegs={getGewonneneLegs(this.state.game, player.playernummer -1)}
                                               active={isPlayerActiveByPlayer(this.state.game, player, getSelectedScoreIndex(this.state.game))}
                                               showCheckout={this.state.showSettings.showCheckout}/>
                    <br/>
                    {(this.state.game.abgeschlossen === undefined || !this.state.game.abgeschlossen) &&
                        <ScoreAreaMulitplayerBig player={player}
                                                 scores={getLegByPlayer(player).scores}
                                                 anzScores={anzahlScores}
                                                 selectedScore={getSelectedScoreIndex(this.state.game)}
                                                 selectedValue={this.state.selectedValue}
                                                 startpunkte={this.state.game.startpunkte}/>
                    }
                </div>)}
            </div>
            {this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen &&
                <div className="grid"
                     style={{
                         margin: 0,
                         padding: 0,
                         textAlign: "center"
                     }}>
                    <div className="col" style={{
                        borderLeft: "2px solid black",
                        borderRight: "2px solid black",
                        textAlign: "center"
                    }}>
                        <h1 style={{color: "white", marginTop:30}}>Spiel ist beendet</h1>
                        <h1 style={{color: "white", marginTop:30}}>Gewinner<br/>{ermittleGewinner(this.state.game).name}</h1>
                        <div style={{color: "white", marginTop: 20, fontSize:30}}>Drücke 'D o. 1' = Zeige Details zum Game.</div>
                        {this.state.waitingSeconds !== undefined && <>
                            <div style={{color: "white", marginTop: 20, fontSize:30}}>Du wirst in {this.state.waitingSeconds} Sekunden auf das
                                Dashboard weitergeleitet.</div>
                            <div style={{color: "white", marginTop: 20, fontSize:30}}>Drücke 'S o. 2' = Keine Weiterleitung zum Dashboard.</div>
                        </>
                        }
                    </div>
                </div>
            }
            {this.state.showFinishQuestion && <FinishDartsDialog onClose={() => this.setState({showFinishQuestion:false})} onSave={(wuerfe) => {
                this.setState({showFinishQuestion: false});
                this.finishLeg(wuerfe);
            }}/>}
        </div>

    }

    changeAutoScore(score) {
        if( this.state.game.abgeschlossen !== null && this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen) {
            return;
        }
        let player1IsActive = isPlayerActive(this.state.game, 0, getSelectedScoreIndex(this.state.game));
        let activePlayerNumber = player1IsActive ? 0 : 1;
        let toGo = getToGo(this.state.game, activePlayerNumber);
        if( toGo < 50) {
            this.finishLeg(2);
        } else {
            this.saveValueSelectedScore("" + score);
        }
    }

    getAutodartsConnectionArea() {
        if( this.state.autodartsBoardId ) {
            return <AutodartsConnection modusId={this.state.game.modusId}
                                        toGo={isPlayerActive(this.state.game, 0, getSelectedScoreIndex(this.state.game)) ? getToGo(this.state.game, 0) : getToGo(this.state.game, 1)}
                                        showKeyBoard={this.state.showKeyBoard}
                                        onSave={(value, anzWuerfe) => this.saveValueSelectedScore(value, anzWuerfe)}
                                        onKeyboardChange={() => this.setState({showKeyBoard: !this.state.showKeyBoard})}
                                        onValueChange={(value) => this.setState({autodartsValue: value})}
                                        onError={(message) => this.setState({message: message})}/>
        }
        return "";
    }

    render2Player () {
        return <div className="bg"
                    style={{height: "100%", minHeight: "100vh", width: "100%"}}>
            {this.getAutodartsConnectionArea()}
            <AutoScoreComponent angeschlossen={this.state.game.abgeschlossen} onScore={(score) => this.changeAutoScore(score)} />
            <Snackbar open={this.state.message !== undefined} autoHideDuration={3000} onClose={() => this.setState({message: undefined})}
                      anchorOrigin={{ vertical:"top", horizontal:"center" }}>
                <Alert onClose={() => this.setState({message: undefined})} severity="error" sx={{ width: '100%', fontSize:30 }}>
                    {this.state.message}
                </Alert>
            </Snackbar>
            {this.state.showFormatDialog &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}>
                    <Stack gap={1} justifyContent="left" alignItems="left" style={{fontSize:20}}>
                        <div>
                            <Button variant="text" startIcon={<CancelPresentationIcon style={{fontSize: 50, color:"red"}}/>}
                                    onClick={() => {
                                        this.setState({showFormatDialog: false});
                                        this.saveSettings()
                                    }}
                                    />
                        </div>
                        <div>Weitere Einstellungen findest du auf dem Dashboard -> Einstellungen -> Anzeige</div>
                        <div style={{display:"flex", fontWeight:"bold", marginTop:20}}>
                            Schriftgrößen
                        </div>
                        {this.formatSliderRow("ToGo:", 10, 500, this.state.pcSettings.toGoFontSize,
                            (event, newValue) => this.setState({pcSettings: {...this.state.pcSettings, toGoFontSize: newValue}}))
                        }
                        {this.formatSliderRow("Spieler:", 10, 150, this.state.pcSettings.playerFontSize,
                            (event, newValue) => this.setState({pcSettings: {...this.state.pcSettings, playerFontSize: newValue}}))
                        }
                        {this.formatSliderRow("Statistik:", 10, 80, this.state.pcSettings.statistikFontSize,
                            (event, newValue) => this.setState({pcSettings: {...this.state.pcSettings, statistikFontSize: newValue}}))
                        }
                        {this.formatSliderRow("Modus:", 10, 80, this.state.pcSettings.modusFontSize,
                            (event, newValue) => this.setState({pcSettings: {...this.state.pcSettings, modusFontSize: newValue}}))
                        }
                        {this.formatSliderRow("Scorekopfbereich:", 10, 80, this.state.pcSettings.scoreAreaHeaderFontSize,
                            (event, newValue) => this.setState({pcSettings: {...this.state.pcSettings, scoreAreaHeaderFontSize: newValue}}))
                        }
                        {this.formatSliderRow("Scorebereich:", 10, 150, this.state.pcSettings.scoreAreaFontSize,
                            (event, newValue) => this.setState({pcSettings: {...this.state.pcSettings, scoreAreaFontSize: newValue}}))
                        }
                        <div style={{display:"flex", fontWeight:"bold", marginTop:20}}>
                            Ausrichtung Statistik
                        </div>
                        <div>
                            <StatisticOrientationComponent label="Statistik Ausrichtung" value={this.state.pcSettings.statisticOrientation}
                                                           onChanged={(value) =>
                                                               this.setState({pcSettings: {...this.state.pcSettings, statisticOrientation: value}})}/>
                        </div>
                    </Stack>
                </Backdrop>
            }
            <div style={{position: "fixed", left: 5, bottom: 55}}>
                <ThemeProvider theme={CustomFontTheme}>
                    <Button variant="text"
                            onClick={() => this.changeToDashboard()}>Esc/*</Button>
                </ThemeProvider>
            </div>
            {this.isUndoAllowed() &&
                <div style={{position: "fixed", left: 80, bottom: 55}}>
                    <ThemeProvider theme={CustomFontTheme}>
                        <Button variant="text"
                                onClick={() => this.undoLastScore()}>X/-</Button>
                    </ThemeProvider>
                </div>
            }
            <div style={{position: "fixed", left: 5, bottom: 10}}>
                <ThemeProvider theme={CustomFontTheme}>
                    <Button variant="text" startIcon={<ExitToAppOutlinedIcon style={{fontSize: 50}}/>}
                            onClick={() => this.changeToDashboard()}/>
                </ThemeProvider>
            </div>
            {this.isUndoAllowed() &&
                <div style={{position: "fixed", left: 80, bottom: 10}}>
                    <ThemeProvider theme={CustomFontTheme}>
                        <Button variant="text" startIcon={<UndoOutlined style={{fontSize: 50}}/>}
                                onClick={() => this.undoLastScore()}/>
                    </ThemeProvider>
                </div>
            }
            {(this.state.game.abgeschlossen === undefined || !this.state.game.abgeschlossen) &&
                <div style={{position: "fixed", right: 13, bottom: 55}}>
                    <ThemeProvider theme={CustomFontTheme}>
                        <Button variant="text"
                                onClick={() => this.setState({showEinstellungenDialog: true})}>E</Button>
                    </ThemeProvider>
                </div>
            }
            {(this.state.game.abgeschlossen === undefined || !this.state.game.abgeschlossen) &&
                <div style={{position: "fixed", right: 5, bottom: 10}}>
                    <ThemeProvider theme={CustomFontTheme}>
                        <Button variant="text" startIcon={<SettingsOutlinedIcon style={{fontSize: 50}}/>}
                        onClick={() => this.setState({showEinstellungenDialog:true})}/>
                    </ThemeProvider>
                </div>
            }
            <div style={{position: "fixed", right: 80, bottom: 10}}>
                <ThemeProvider theme={CustomFontTheme}>
                    <Button variant="text" startIcon={<FormatSizeIcon style={{fontSize: 50}}/>}
                            onClick={() => this.setState({showFormatDialog: true})}/>
                </ThemeProvider>
            </div>

            <div className="grid" style={{width: "100%", margin: 0, marginTop: 0, padding: 0,}}>
                <PlayerSingleArea player={getPlayer(this.state.game, 0)}
                                  isSetModus={isSetModus(this.state.game)}
                                  isLegBeginner={isLegBeginner(this.state.game, 0)}
                                  togo={getToGoWithAutodarts(this.state.game, 0, isPlayerActive(this.state.game, 0, getSelectedScoreIndex(this.state.game)) ? this.state.autodartsValue : 0)}
                                  active={isPlayerActive(this.state.game, 0, getSelectedScoreIndex(this.state.game))}
                                  fontSize={this.state.pcSettings.toGoFontSize}
                                  canCheck={canCheck(this.state.game, getToGo(this.state.game, 0), this.state.showSettings.showFinishColors)}/>
                <PlayerSingleArea player={getPlayer(this.state.game, 1)}
                                  isSetModus={isSetModus(this.state.game)}
                                  isLegBeginner={isLegBeginner(this.state.game, 1)}
                                  togo={getToGoWithAutodarts(this.state.game, 1, isPlayerActive(this.state.game, 1, getSelectedScoreIndex(this.state.game)) ? this.state.autodartsValue : 0)}
                                  fontSize={this.state.pcSettings.toGoFontSize}
                                  canCheck={canCheck(this.state.game, getToGo(this.state.game, 1), this.state.showSettings.showFinishColors)}
                                  active={isPlayerActive(this.state.game, 1, getSelectedScoreIndex(this.state.game))}/>
            </div>
            {this.state.game.mannschaftPlayer1 !== null && this.state.game.mannschaftPlayer1 !== undefined && this.state.game.mannschaftPlayer1 !== "" &&
                <div className="grid" style={{
                    margin: 0, padding: 0, color: "white",
                    backgroundColor: "#ac1c27", textAlign: "center", fontSize: this.state.pcSettings.playerFontSize
                }}>
                    <div className="col" style={{borderRight:"2px solid #022836", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                        {this.state.game.mannschaftPlayer1}
                    </div>
                    <div className="col" style={{borderLeft:"2px solid #022836", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                        {this.state.game.mannschaftPlayer2}
                    </div>
                </div>
            }
            {this.getScoreArea()}
            {this.state.showFinishQuestion && <FinishDartsDialog onClose={() => this.setState({showFinishQuestion:false})} onSave={(wuerfe) => {
                this.setState({showFinishQuestion: false});
                this.finishLeg(wuerfe);
            }}/>}
            {this.state.showEinstellungenDialog && <X01EinstellungenDialog
                onPlayerScoreChange={() => {
                    this.changeScoreByPlayers();
                    this.setState({showEinstellungenDialog: false});
                }}
                onClose={() => this.setState({showEinstellungenDialog:false})}/>}
        </div>
    }

    getScoreArea() {
        let smallWindow = false;
        if( this.state.windowdimensions.width < 1080) {
            smallWindow = true;
        }

        let anzahlScores = this.state.game.abgeschlossen ? 0 : getAnzahleWuerfeImAktivenLeg(this.state.game);
        let legAveragePlayer = this.state.game.abgeschlossen ? 0 : getLeg(this.state.game, 0).legAverage;
        let legAverageGegner = this.state.game.abgeschlossen ? 0 : getLeg(this.state.game, 1).legAverage;
        return <div>
            {smallWindow && <div style={{paddingLeft:10, paddingRight:10, paddingBottom:10}}>
                <PCSmallWindowPlayerArea
                    align="left"
                    fontSizePlayer={this.state.pcSettings.playerFontSize}
                    fontSizeStatstik={this.state.pcSettings.statistikFontSize}
                    name={getPlayer(this.state.game, 0).name}
                    legAverage={legAveragePlayer}
                    showCheckout={this.state.showSettings.showCheckout}
                    checkout={this.getCheckout(0)}
                    isSetModus={isSetModus(this.state.game)}
                    average={getAverage(this.state.game, 0)}
                    averageFirst9={getAverageFirst9(this.state.game, 0)}
                    averageLeg={getAverageLeg(this.state.game, 0)}
                    count100Plus={get100Plus(this.state.game, 0)}
                    count140Plus={get140Plus(this.state.game, 0)}
                    count180er={get180er(this.state.game, 0)}
                    count171er={get171er(this.state.game, 0)}
                    gewonneneSets={getGewonneneSets(this.state.game, 0)}
                    gewonneneLegs={getGewonneneLegs(this.state.game, 0)}
                    bestLeg={getBestLeg(this.state.game, 0)}
                    worstLeg={getWorstLeg(this.state.game, 0)}
                    highOut={getHighOut(this.state.game, 0)}
                    showSettings={this.state.showSettings}
                />
                <PCSmallWindowPlayerArea
                    align="right"
                    fontSizePlayer={this.state.pcSettings.playerFontSize}
                    fontSizeStatstik={this.state.pcSettings.statistikFontSize}
                    name={getPlayer(this.state.game, 1).name}
                    legAverage={legAverageGegner}
                    showCheckout={this.state.showSettings.showCheckout}
                    checkout={this.getCheckout(1)}
                    isSetModus={isSetModus(this.state.game)}
                    average={getAverage(this.state.game, 1)}
                    averageFirst9={getAverageFirst9(this.state.game, 1)}
                    averageLeg={getAverageLeg(this.state.game, 1)}
                    count100Plus={get100Plus(this.state.game, 1)}
                    count140Plus={get140Plus(this.state.game, 1)}
                    count180er={get180er(this.state.game, 1)}
                    count171er={get171er(this.state.game, 1)}
                    gewonneneSets={getGewonneneSets(this.state.game, 1)}
                    gewonneneLegs={getGewonneneLegs(this.state.game, 1)}
                    bestLeg={getBestLeg(this.state.game, 1)}
                    worstLeg={getWorstLeg(this.state.game, 1)}
                    highOut={getHighOut(this.state.game, 1)}
                    showSettings={this.state.showSettings}
                />
            </div>
            }
            <div className="grid" style={{padding: 0, margin: 0, height: "100%", width: "100%"}}>
                {!smallWindow &&
                    <StatistikArea orientation="left"
                                   name={getPlayer(this.state.game, 0).name}
                                   player={getPlayer(this.state.game, 0)}
                                   isLegBeginner={isLegBeginner(this.state.game, 0)}
                                   legAverage={legAveragePlayer}
                                   checkout={this.getCheckout(0)}
                                   isSetModus={isSetModus(this.state.game)}
                                   average={getAverage(this.state.game, 0)}
                                   averageFirst9={getAverageFirst9(this.state.game, 0)}
                                   averageLeg={getAverageLeg(this.state.game, 0)}
                                   count100Plus={get100Plus(this.state.game, 0)}
                                   count140Plus={get140Plus(this.state.game, 0)}
                                   count180er={get180er(this.state.game, 0)}
                                   count171er={get171er(this.state.game, 0)}
                                   gewonneneSets={getGewonneneSets(this.state.game, 0)}
                                   gewonneneLegs={getGewonneneLegs(this.state.game, 0)}
                                   bestLeg={getBestLeg(this.state.game, 0)}
                                   worstLeg={getWorstLeg(this.state.game, 0)}
                                   highOut={getHighOut(this.state.game, 0)}
                                   showSettings={this.state.showSettings}
                                   statisticOrientation={this.state.pcSettings.statisticOrientation}
                                   fontSizePlayer={this.state.pcSettings.playerFontSize}
                                   fontSizeStatistik={this.state.pcSettings.statistikFontSize}
                    />
                }
                {(this.state.game.abgeschlossen === undefined || !this.state.game.abgeschlossen) &&
                    <div className={smallWindow ? "col" : "col-6 md:col-6 lg:col-6"}
                         style={{borderLeft: "2px solid black", borderRight: "2px solid black", paddingTop:0}}>
                        {this.isPlayerChangeAllowed() && <PlayerChangeArea onChange={() => this.changeSelectedScore(0)}/> }
                        <ModusArea sets={this.state.game.sets} firsttolegs={this.state.game.firsttolegs}
                                   bestoflegs={this.state.game.bestoflegs} modusid={this.state.game.modusId} fontSize={this.state.pcSettings.modusFontSize}/>
                        <ScoreHeader fontSize={this.state.pcSettings.scoreAreaHeaderFontSize}/>
                        <ScoreAreaDesktopTwoPlayersBig scoresPlayer1={getLeg(this.state.game, 0).scores}
                                      scoresPlayer2={getLeg(this.state.game, 1).scores}
                                      anzScores={anzahlScores}
                                      selectedScore={getSelectedScoreIndex(this.state.game)}
                                      selectedValue={this.state.selectedValue}
                                      startpunkte={this.state.game.startpunkte}
                                       fontSize={this.state.pcSettings.scoreAreaFontSize}
                                      setSelectedScore={(score) => {
                                          let value = getWertImAktivenLeg(this.state.game, score);
                                          if( value === "" && score === 2 && getSelectedScoreIndex(this.state.game) === 1) {
                                              this.changeSelectedScore(0);
                                          } else {
                                              let game = this.state.game;
                                              game.selectedScoreIndex = score;
                                              this.setState({game: game, selectedValue: value});
                                          }
                                      }}/>
                        {this.isZuruecksetzenMoeglich() &&
                            <div className="grid" style={{ width: "100%", height: 50, padding: 20, margin: 0, textAlign: "center", fontSize: 25, fontWeight: "bold", cursor:"pointer"}} onClick={() => this.zurueckZumLetztenLeg()}>
                                <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white"}}>Zurück zum letzten Leg (Z)</div>
                            </div>
                        }
                    </div>
                }
                {this.state.game.abgeschlossen !== undefined && this.state.game.abgeschlossen &&
                    <div className={smallWindow ? "col" : "col-6 md:col-6 lg:col-6"} style={{
                        borderLeft: "2px solid black",
                        borderRight: "2px solid black",
                        textAlign: "center"
                    }}>
                        <ModusArea sets={this.state.game.sets} firsttolegs={this.state.game.firsttolegs}
                                   bestoflegs={this.state.game.bestoflegs} modusid={this.state.game.modusId}/>
                        {this.state.game.uebertragen === undefined &&
                            <div className="grid" style={{
                                width: "100%",
                                height: 50,
                                padding: 20,
                                margin: 0,
                                textAlign: "center",
                                fontSize: 25,
                                fontWeight: "bold",
                                cursor: "pointer"
                            }} onClick={() => this.zurueckZumLetztenLeg()}>
                                <div className="col"
                                     style={{borderBottom: "2px solid black", backgroundColor: "white"}}>Zurück zum letzten
                                    Leg (Z)
                                </div>
                            </div>
                        }
                        <h1 style={{color: "white", marginTop:30}}>Spiel ist beendet</h1>
                        {this.state.game.unentschieden !== undefined &&
                            <h1 style={{color: "white", marginTop:20}}>Unentschieden</h1>
                        }
                        {this.state.game.unentschieden === undefined &&
                            <h1 style={{color: "white", marginTop:20}}>Gewinner<br/>{ermittleGewinner(this.state.game).name}</h1>
                        }
                        {this.state.game.uebertragen !== undefined && this.state.game.uebertragen === "OK" && <div style={{color:"green", fontSize: 25}}>Das Spiel wurde an darthelfer.de übertragen.</div>}
                        {(this.state.game.uebertragen !== undefined && this.state.game.uebertragen === "FEHLER") && <div style={{backgroundColor:"red", color:"white", fontSize:30, cursor:"pointer"}}
                                                                                                                         onClick={() => this.uebertrageErgebnisAnsBackend(this.state.game)}>
                            <div>Fehler ({this.state.retries}) beim Übertragen an darthelfer.de</div>
                            <div>....Erneut übertragen...</div>
                            <div>Alternativ Ergebnis von Hand im Turnier/Liga eintragen.</div>
                        </div>}
                        <div style={{color: "white", marginTop: 20, fontSize:30}}>Drücke 'D o. 1' = Zeige Details zum Game.</div>
                        {this.state.waitingSeconds !== undefined && <>
                            <div style={{color: "white", marginTop: 20, fontSize:30}}>Du wirst in {this.state.waitingSeconds} Sekunden auf das
                                Dashboard weitergeleitet.</div>
                            <div style={{color: "white", marginTop: 20, fontSize:30}}>Drücke 'S o. 2' = Keine Weiterleitung zum Dashboard.</div>
                        </>
                        }
                    </div>
                }
                {!smallWindow &&
                    <StatistikArea orientation="right" name={getPlayer(this.state.game, 1).name}
                                   player={getPlayer(this.state.game, 1)}
                                   isLegBeginner={isLegBeginner(this.state.game, 1)}
                                   legAverage={legAverageGegner} checkout={this.getCheckout(1)}
                                   isSetModus={isSetModus(this.state.game)}
                                   average={getAverage(this.state.game, 1)}
                                   averageFirst9={getAverageFirst9(this.state.game, 1)}
                                   averageLeg={getAverageLeg(this.state.game, 1)}
                                   count100Plus={get100Plus(this.state.game, 1)}
                                   count140Plus={get140Plus(this.state.game, 1)}
                                   count180er={get180er(this.state.game, 1)}
                                   count171er={get171er(this.state.game, 1)}
                                   gewonneneSets={getGewonneneSets(this.state.game, 1)}
                                   gewonneneLegs={getGewonneneLegs(this.state.game, 1)}
                                   bestLeg={getBestLeg(this.state.game, 1)}
                                   worstLeg={getWorstLeg(this.state.game, 1)}
                                   highOut={getHighOut(this.state.game, 1)}
                                   showSettings={this.state.showSettings}
                                   statisticOrientation={this.state.pcSettings.statisticOrientation}
                                   fontSizePlayer={this.state.pcSettings.playerFontSize}
                                   fontSizeStatistik={this.state.pcSettings.statistikFontSize}
                    />
                }
            </div>
        </div>;
    }

    ermittleAktuellenPlayerUndGebeNaechstePlayernummerZurueck(aktuellesSet, aktuellesLeg) {
        let game = this.state.game;
        let setCounterImArray = aktuellesSet.setnummer - 1;
        let legCounterImArray = aktuellesLeg.legnummer - 1;

        let naechstePlayerNummer = 0;
        for (const player of game.players) {
            let scores = player.sets[setCounterImArray].legs[legCounterImArray].scores;
            if (scores.length > 0 && scores[0].counter === 1) {
                naechstePlayerNummer = player.playernummer + 1;
                break;
            }
        }

        if (naechstePlayerNummer > game.players.length) {
            naechstePlayerNummer = 1;
        }
        return naechstePlayerNummer;
    }
}

ScoringX01View.propTypes = {
    id: PropTypes.string,
};

export default ScoringX01View;
