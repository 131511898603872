import './settings.css';
import {useEffect, useState} from "react";
import {
    getFromLocalStorageWithDefault,
    removeFromLocalStorage,
    saveToLocalStorage
} from "../localStorageZugriff";
import {TextField, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";


export default function SettingsAdmin() {

    const [autoscore, setAutoscore] = useState ( getFromLocalStorageWithDefault("dh_autoscore", ""));

    useEffect(() => {
        if( autoscore === "") {
            removeFromLocalStorage("dh_autoscore");
        } else {
            saveToLocalStorage("dh_autoscore", autoscore);
        }
    }, [autoscore]);


    return <div className="grid">
        <div className="col" style={{width: 150}}>
            <h2>Einstellungen Admin</h2>
            <div style={{marginBottom: 20}}>
                <ThemeProvider theme={CustomFontTheme}>
                    <TextField id="standard-send-game" type="number" label="Autoscore"
                               variant="standard"
                               InputProps={{inputProps: {min: 1000}}}
                               value={autoscore} onChange={(e) => setAutoscore(e.target.value)}/>
                </ThemeProvider>
                <div>Sobald gefüllt, wird in diesem Interval automatisch gescored. 6000 wäre 6 Sek.</div>
            </div>
        </div>
    </div>
}
