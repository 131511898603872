import {FormControl, InputLabel, NativeSelect, ThemeProvider} from "@mui/material";

export function StartpunkteComponent({startpunkte, onStartpunkteChanged, theme, showLabel, fontSize}) {
    return <FormControl fullWidth>
        <ThemeProvider theme={theme}>
            {showLabel !== undefined && showLabel &&
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Startpunkte
                </InputLabel>
            }
            <NativeSelect
                onChange={event => onStartpunkteChanged(event.target.value)}
                defaultValue={startpunkte}
                sx={{color: '#b6b6b6', fontSize: fontSize !== undefined ? fontSize : "" }}
                inputProps={{
                    name: 'Startpunkte',
                    id: 'uncontrolled-native',
                }}
            >
                <option value={101}>101</option>
                <option value={170}>170</option>
                <option value={201}>201</option>
                <option value={301}>301</option>
                <option value={501}>501</option>
                <option value={701}>701</option>
                <option value={901}>901</option>
            </NativeSelect>
        </ThemeProvider>
    </FormControl>
}
