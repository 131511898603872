import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import BarChartIcon from '@mui/icons-material/BarChart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';

import {getFromLocalStorage} from "../localStorageZugriff";

const useStyles = makeStyles({
    row: {
        width: '100%',
        padding: 0,
        margin: 0,
    },
    column: {
        display: "table-cell",
        textAlign: "center",
        verticalAlign: "middle",
        border: "1px solid #064d6b",
        color: "white",
        fontWeight: "bold",
        backgroundColor:"#056f9a",
    },
});

function MyColumn ({value, action, fontSize}) {
    const classes = useStyles();

    let [showClicked, setShowClicked] = useState(false);

    return <div className={"col " + classes.column} style={{fontSize: fontSize, backgroundColor: showClicked ? "#037caf" : "#056f9a", verticalAlign:"middle", display:"flex", justifyContent:"center", alignItems:"center"}}  onClick={() => {
        setShowClicked(true);
        setTimeout(() => {
            setShowClicked(false);
        }, 200);
        action(value);
    }}>
        {value}
    </div>
}

function MyIconColumn ({icon, action}) {
    const classes = useStyles();

    let [showClicked, setShowClicked] = useState(false);

    return <div className={"col " + classes.column} style={{backgroundColor: showClicked ? "#037caf" : "#056f9a", verticalAlign:"middle", display:"flex", justifyContent:"center", alignItems:"center"}}  onClick={() => {
        setShowClicked(true);
        setTimeout(() => {
            setShowClicked(false);
        }, 200);
        action();
    }}>
        {icon}
    </div>
}

export default function TabletTastaturQuerformat({fontSize, rowHeight, showStatistic, onStatisticChange, onValueAdd, onShortCut, onRemoveValue, onEnter}) {

    const classes = useStyles();

    const [taste1] = useState(getFromLocalStorage("dh_default_x01_taste1"));
    const [taste2] = useState(getFromLocalStorage("dh_default_x01_taste2"));
    const [taste3] = useState(getFromLocalStorage("dh_default_x01_taste3"));
    const [taste4] = useState(getFromLocalStorage("dh_default_x01_taste4"));
    const [taste5] = useState(getFromLocalStorage("dh_default_x01_taste5"));
    const [taste6] = useState(getFromLocalStorage("dh_default_x01_taste6"));
    const [taste7] = useState(getFromLocalStorage("dh_default_x01_taste7"));
    const [taste8] = useState(getFromLocalStorage("dh_default_x01_taste8"));

    return <div style={{minHeight: "100%", height: "100%"}}>
        <div className={"grid " + classes.row} style={{height: rowHeight}}>
            <MyColumn value={taste7} action={onShortCut} fontSize={fontSize}/>
            <MyColumn value={taste8} action={onShortCut} fontSize={fontSize}/>
        </div>
        <div className={"grid " + classes.row} style={{height:rowHeight}}>
            <MyColumn value={taste4} action={onShortCut} fontSize={fontSize}/>
            <MyColumn value={taste5} action={onShortCut} fontSize={fontSize}/>
            <MyColumn value={taste6} action={onShortCut} fontSize={fontSize}/>
        </div>
        <div className={"grid " + classes.row} style={{marginBottom:10, height:rowHeight}}>
            <MyColumn value={taste1} action={onShortCut} fontSize={fontSize}/>
            <MyColumn value={taste2} action={onShortCut} fontSize={fontSize}/>
            <MyColumn value={taste3} action={onShortCut} fontSize={fontSize}/>
        </div>
        <div className={"grid " + classes.row} style={{height:rowHeight}}>
            <div className={"col-fixed " + classes.column} style={{width:80, background: showStatistic ? "#056f9a" : "#d3d2d2", verticalAlign:"middle", display:"flex", justifyContent:"center", alignItems:"center"}} onClick={() => {
                onStatisticChange();
            }}>
                <BarChartIcon style={{fontSize:50}}/>
            </div>
            <MyIconColumn icon={<ArrowBackIcon style={{fontSize:50}}/>} action={onRemoveValue}/>
        </div>
        <div className={"grid " + classes.row} style={{height:rowHeight}}>
            <MyColumn value="7" action={onValueAdd} fontSize={fontSize}/>
            <MyColumn value="8" action={onValueAdd} fontSize={fontSize}/>
            <MyColumn value="9" action={onValueAdd} fontSize={fontSize}/>
        </div>
        <div className={"grid " + classes.row} style={{height:rowHeight}}>
            <MyColumn value="4" action={onValueAdd} fontSize={fontSize}/>
            <MyColumn value="5" action={onValueAdd} fontSize={fontSize}/>
            <MyColumn value="6" action={onValueAdd} fontSize={fontSize}/>
        </div>
        <div className={"grid " + classes.row} style={{height:rowHeight}}>
            <MyColumn value="1" action={onValueAdd} fontSize={fontSize}/>
            <MyColumn value="2" action={onValueAdd} fontSize={fontSize}/>
            <MyColumn value="3" action={onValueAdd} fontSize={fontSize}/>
        </div>
        <div className={"grid " + classes.row} style={{height:rowHeight}}>
            <MyColumn value="0" action={onValueAdd} fontSize={fontSize}/>
            <MyIconColumn icon={<CheckIcon style={{fontSize:50}}/>} action={onEnter}/>
        </div>
    </div>

}
