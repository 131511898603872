import React, {useEffect, useState} from "react";
import {ModusArea, PlayerSingleArea, ScoreAreaDesktopTwoPlayersBig, ScoreHeader, StatistikArea} from "../x01/ScoreComponents";
import {
    canCheck,
    ermittleGewinner,
    get100Plus,
    get140Plus,
    get171er,
    get180er,
    getAnzahleWuerfeImAktivenLeg,
    getAverage,
    getAverageFirst9,
    getAverageLeg,
    getBestLeg,
    getGewonneneLegs,
    getGewonneneSets,
    getHighOut,
    getLeg,
    getPlayer,
    getSelectedScoreIndex,
    getToGo,
    getWorstLeg,
    isLegBeginner,
    isPlayerActive,
    isSetModus
} from "../x01/ScoreHelper";
import {getFromLocalStorage, getFromLocalStorageWithDefault, saveToLocalStorage} from "../localStorageZugriff";
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import {Backdrop, Button, Slider, Stack, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import {getCheckout} from "../constants/checkouts";

export default function LiveViewLokalOverview() {

    const [game, setGame] = useState(undefined);

    const [showFormatArea, setShowFormatArea] = useState(false);

    const [fontSettings, setFontSettings] = useState({
        toGoFontSize: Number(getFromLocalStorageWithDefault("dh_default_liveview_lokal_togo_fontsize", 100)),
        playerFontSize: Number(getFromLocalStorageWithDefault("dh_default_liveview_lokal_player_fontsize", 25)),
        statistikFontSize: Number(getFromLocalStorageWithDefault("dh_default_liveview_lokal_statisik_fontsize", 40)),
        modusFontSize: Number(getFromLocalStorageWithDefault("dh_default_liveview_lokal_modus_fontsize", 25)),
        scoreAreaHeaderFontSize: Number(getFromLocalStorageWithDefault("dh_default_liveview_lokal_scorearea_header_fontsize", 20)),
        scoreAreaFontSize: Number(getFromLocalStorageWithDefault("dh_default_liveview_lokal_scorearea_fontsize", 25)),
    });

    const showSettings = {
        showFinishQuestion: getFromLocalStorage("dh_default_x01_show_finishquestion"),
        showCheckout: getFromLocalStorage("dh_default_x01_show_checkout"),
        showAverage: getFromLocalStorage("dh_default_x01_show_average"),
        showAverageFirst9: getFromLocalStorage("dh_default_x01_show_average_first_9"),
        showAverageLeg: getFromLocalStorage("dh_default_x01_show_average_leg"),
        show100Plus: getFromLocalStorage("dh_default_x01_show_100plus"),
        show140Plus: getFromLocalStorage("dh_default_x01_show_140plus"),
        show171: getFromLocalStorage("dh_default_x01_show_171"),
        show180: getFromLocalStorage("dh_default_x01_show_180"),
        showBestLeg: getFromLocalStorage("dh_default_x01_show_bestleg"),
        showWorstLeg: getFromLocalStorage("dh_default_x01_show_worstleg"),
        showHighOut: getFromLocalStorage("dh_default_x01_show_highout"),
        showFinishColors: getFromLocalStorage("dh_default_x01_show_finish_colors"),
        showTableScoreValue: getFromLocalStorage("dh_default_show_table_score_value"),
        alleSpieleImLiveView: getFromLocalStorage("dh_alle_spiele_im_live_view"),
        taste1: getFromLocalStorage("dh_default_x01_taste1"),
        taste2: getFromLocalStorage("dh_default_x01_taste2"),
        taste3: getFromLocalStorage("dh_default_x01_taste3"),
        taste4: getFromLocalStorage("dh_default_x01_taste4"),
        taste5: getFromLocalStorage("dh_default_x01_taste5"),
        taste6: getFromLocalStorage("dh_default_x01_taste6"),
        taste7: getFromLocalStorage("dh_default_x01_taste7"),
        taste8: getFromLocalStorage("dh_default_x01_taste8"),
    };

    let [dimensions, setDimensions] = useState({
            height: window.innerHeight,
            width: window.innerWidth
        }
    );

    useEffect(() => {
        let activeGameId = getFromLocalStorage("dh_active_gameid");
        if( activeGameId !== undefined) {
            loadGameByKey(activeGameId);
        }
    }, []);

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        window.addEventListener("storage", onStorageChange);
        return () => {
            window.removeEventListener("storage", onStorageChange);
        }
    }, []);


    const onKeyDown = (event) => {
        switch (event.key) {
            case "Escape":
            case "*":
                changeToDashboard();
                event.preventDefault();
                break;
            default:
                break;
        }
        event.stopPropagation();
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        }
    }, [onKeyDown]);

    const onStorageChange = (event) => {
        if (event.key.startsWith("dh_game_")) {
            setGame(JSON.parse(event.newValue));
        } else if( event.key === "dh_active_gameid") {
            if( event.newValue === undefined) {
                setGame(undefined);
            } else {
                loadGameByKey(JSON.parse(event.newValue));
            }
        }
    }

    const loadGameByKey = (key) => {
        let game = getFromLocalStorage(key);
        setGame(game);
    }

    const getCheckoutIntern = (playerCounter) => {
        let toGo = getToGo(game, playerCounter);
        return game.modusId === "double_out" || game.modusId === "double_in_double_out" ? getCheckout(toGo) : undefined;
    }

    const getPlayerStatistikArea = (playerCounter) => {
        return <StatistikArea orientation="left"
                              name={getPlayer(game, playerCounter).name}
                              player={getPlayer(game, playerCounter)}
                              isLegBeginner={isLegBeginner(game, playerCounter)}
                              legAverage={game.abgeschlossen ? 0 : getLeg(game, playerCounter).legAverage}
                              checkout={getCheckoutIntern(playerCounter)}
                              showCheckout={showSettings.showCheckout}
                              isSetModus={isSetModus(game)}
                              average={getAverage(game, playerCounter)}
                              averageFirst9={getAverageFirst9(game, playerCounter)}
                              averageLeg={getAverageLeg(game, playerCounter)}
                              count100Plus={get100Plus(game, playerCounter)}
                              count140Plus={get140Plus(game, playerCounter)}
                              count180er={get180er(game, playerCounter)}
                              count171er={get171er(game, playerCounter)}
                              gewonneneSets={getGewonneneSets(game, playerCounter)}
                              gewonneneLegs={getGewonneneLegs(game, playerCounter)}
                              bestLeg={getBestLeg(game, playerCounter)}
                              worstLeg={getWorstLeg(game, playerCounter)}
                              highOut={getHighOut(game, playerCounter)}
                              showSettings={showSettings}
                              statisticOrientation={"liste"}
                              fontSizePlayer={fontSettings.playerFontSize}
                              fontSizeStatistik={fontSettings.statistikFontSize}
        />
    }

    const getScoreArea = () => {
        let anzahlScores = game.abgeschlossen ? 0 : getAnzahleWuerfeImAktivenLeg(game);
        return <div className="col"
                    style={{
                        borderLeft: "2px solid black",
                        borderRight: "2px solid black",
                        paddingTop: 0
                    }}>
            <ModusArea sets={game.sets} firsttolegs={game.firsttolegs}
                       bestoflegs={game.bestoflegs} modusid={game.modusId} fontSize={fontSettings.modusFontSize}/>
            <ScoreHeader fontSize={fontSettings.scoreAreaHeaderFontSize}/>
            <ScoreAreaDesktopTwoPlayersBig scoresPlayer1={getLeg(game, 0).scores}
                                           scoresPlayer2={getLeg(game, 1).scores}
                                           anzScores={anzahlScores}
                                           selectedScore={getSelectedScoreIndex(game)}
                                           selectedValue={""}
                                           startpunkte={game.startpunkte}
                                           fontSize={fontSettings.scoreAreaFontSize}
                                           setSelectedScore={(score) => ""}/>
        </div>
    }

    const formatSliderRow = (bezeichnung, min, max, value, onChange) => {
        return <div style={{display: "flex"}}>
            <span style={{marginRight: 5, minWidth: 200}}>{bezeichnung}</span>
            <Slider defaultValue={value} step={2} marks min={min} max={max} onChange={onChange} style={{width: 200, minWidth: 100}}/>
            <span style={{marginLeft: 10}}>{value}</span>
        </div>
    }

    const saveSettings = () => {
        saveToLocalStorage("dh_default_liveview_lokal_togo_fontsize", fontSettings.toGoFontSize);
        saveToLocalStorage("dh_default_liveview_lokal_player_fontsize", fontSettings.playerFontSize);
        saveToLocalStorage("dh_default_liveview_lokal_statisik_fontsize", fontSettings.statistikFontSize);
        saveToLocalStorage("dh_default_liveview_lokal_modus_fontsize", fontSettings.modusFontSize);
        saveToLocalStorage("dh_default_liveview_lokal_scorearea_header_fontsize", fontSettings.scoreAreaHeaderFontSize);
        saveToLocalStorage("dh_default_liveview_lokal_scorearea_fontsize", fontSettings.scoreAreaFontSize);
    }

    const getFormatArea = () => {
        return <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}>
            <Stack gap={1} justifyContent="left" alignItems="left" style={{fontSize: 20}}>
                <div>
                    <Button variant="text" startIcon={<CancelPresentationIcon style={{fontSize: 50, color: "red"}}/>}
                            onClick={() => {
                                setShowFormatArea(false);
                                saveSettings();
                            }}
                    />
                </div>
                <div>Weitere Einstellungen findest du auf dem Dashboard -> Einstellungen -> Anzeige</div>
                <div style={{display: "flex", fontWeight: "bold", marginTop: 20}}>
                    Schriftgrößen
                </div>
                {formatSliderRow("ToGo:", 10, 500, fontSettings.toGoFontSize,
                    (event, newValue) => {setFontSettings({...fontSettings, toGoFontSize: newValue})})
                }
                {formatSliderRow("Spieler:", 10, 150, fontSettings.playerFontSize,
                    (event, newValue) => {setFontSettings({...fontSettings, playerFontSize: newValue})})
                }
                {formatSliderRow("Statistik:", 10, 80, fontSettings.statistikFontSize,
                    (event, newValue) => {setFontSettings({...fontSettings, statistikFontSize: newValue})})
                }
                {formatSliderRow("Modus:", 10, 80, fontSettings.modusFontSize,
                    (event, newValue) => {setFontSettings({...fontSettings, modusFontSize: newValue})})
                }
                {formatSliderRow("Scorekopfbereich:", 10, 80, fontSettings.scoreAreaHeaderFontSize,
                    (event, newValue) => {setFontSettings({...fontSettings, scoreAreaHeaderFontSize: newValue})})
                }
                {formatSliderRow("Scorebereich:", 10, 150, fontSettings.scoreAreaFontSize,
                    (event, newValue) => {setFontSettings({...fontSettings, scoreAreaFontSize: newValue})})
                }
            </Stack>
        </Backdrop>
    }

    const changeToDashboard = () => {
        window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self");
    }

    if (game === undefined) {
        return <div style={{backgroundColor: "#022836", minHeight: "100vh", height: "100%", textAlign:"center", fontSize:30, color:"white"}}>
            <div>Aktuell kein Spiel vorhanden.</div>
            <div>Bitte starte dein Spiel.</div>
            <div style={{marginTop:20}}>
                <img src="/images/darthelfer_border_klein.png" alt="darthelfer.de"
                     title="darthelfer" style={{width: 300}}/>
            </div>
        </div>
    }

    return <div style={{backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
        <div style={{position: "absolute", bottom: 5, left: 0, width: 70}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" startIcon={<ExitToAppOutlinedIcon style={{fontSize: 50}}/>}
                        onClick={() => changeToDashboard()}/>
            </ThemeProvider>
        </div>
        <div style={{position: "fixed", bottom: 55, left: 5,}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text"
                        onClick={() => changeToDashboard()}>Esc/*</Button>
            </ThemeProvider>
        </div>
        <div style={{position: "fixed", right: 5, bottom: 5}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text" startIcon={<FormatSizeIcon style={{fontSize: 50}}/>}
                        onClick={() => setShowFormatArea(true)}/>
            </ThemeProvider>
        </div>
        {showFormatArea && getFormatArea()}
        <div className="grid" style={{width: "100%", margin: 0, marginTop: 0, padding: 0,}}>
            <PlayerSingleArea player={getPlayer(game, 0)}
                              isSetModus={isSetModus(game)}
                              isLegBeginner={isLegBeginner(game, 0)}
                              togo={getToGo(game, 0)}
                              active={isPlayerActive(game, 0, getSelectedScoreIndex(game))}
                              fontSize={fontSettings.toGoFontSize}
                              canCheck={canCheck(game, getToGo(game, 0), showSettings.showFinishColors)}/>
            <PlayerSingleArea player={getPlayer(game, 1)}
                              isSetModus={isSetModus(game)}
                              isLegBeginner={isLegBeginner(game, 1)}
                              togo={getToGo(game, 1)}
                              fontSize={fontSettings.toGoFontSize}
                              canCheck={canCheck(game, getToGo(game, 1), showSettings.showFinishColors)}
                              active={isPlayerActive(game, 1, getSelectedScoreIndex(game))}/>
        </div>
        <div className="grid" style={{padding: 0, margin: 0, height: "100%", width: "100%"}}>
            {getPlayerStatistikArea(0)}
            {(game.abgeschlossen === undefined || !game.abgeschlossen) && getScoreArea()}
            {game.abgeschlossen !== undefined && game.abgeschlossen &&
                <div className="col" style={{borderLeft: "2px solid black",borderRight: "2px solid black",textAlign: "center"}}>
                    <h1 style={{color: "white", marginTop:30}}>Spiel ist beendet</h1>
                    {game.unentschieden !== undefined &&
                        <h1 style={{color: "white", marginTop:20}}>Unentschieden</h1>
                    }
                    {game.unentschieden === undefined &&
                        <h1 style={{color: "white", marginTop:20}}>Gewinner<br/>{ermittleGewinner(game).name}</h1>
                    }
                </div>
            }
            {getPlayerStatistikArea(1)}
        </div>
    </div>
}
