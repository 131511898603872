import {v4 as uuidv4} from "uuid";

export function newPlayerWithId(id, name, playerNummer, setNumber, legNumber, scoreCounter) {
    return {id: id, playernummer: playerNummer, name: name, sets: [newSet(setNumber, legNumber, scoreCounter)]};
}

export function newPlayer(name, playerNummer, setNumber, legNumber, scoreCounter) {
    return {id: uuidv4(), playernummer: playerNummer, name: name, sets: [newSet(setNumber, legNumber, scoreCounter)]};
}

export function newSet(setNumber, legNumber, scoreCounter) {
    return {id: uuidv4(), setnummer: setNumber, legs: [newLeg(legNumber, scoreCounter)]};
}

export function newSetWithoutScore(setNumber, legNumber) {
    return {id: uuidv4(), setnummer: setNumber, legs: [newLegWithoutScore(legNumber)]};
}

export function newLeg(legNumber, scoreCounter) {
    return {id: uuidv4(), legnummer: legNumber, scores: [newScore(scoreCounter)]};
}

export function newLegWithoutScore(legNumber) {
    return {id: uuidv4(), legnummer: legNumber, scores: []};
}

export function newScore(scoreCounter) {
    return {id: uuidv4(), counter:scoreCounter, value: "", togo: ""};
}

